import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class DashedLine extends Component {
	constructor(props) {
		super(props);

		this.state = {
			series: [],
			x_axis : [],
		}
	}

	componentDidUpdate(prevProps, prevState){
		if (this.props && this.props !== prevProps && this.props.data){
			var data = this.props.data;
			this.setState({
				series: data.series,
				x_axis: data.xData,
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<ReactApexChart options={
					{
						dataLabels: {
							enabled: false
						},
						stroke: {
							curve: 'smooth',
							width: 3,
						},
						
						colors: ['#556ee6', '#34c38f'],
						xaxis: {
							type: 'datetime',
							categories: this.state.x_axis,
						},
						yaxis: {
							labels: {
								formatter: function (value) {
									return value.toFixed(2);
								}
							},
						},
						grid: {
							borderColor: '#f1f1f1',
						},
						tooltip: {
							x: {
								format: 'dd/MM/yy HH:mm'
							},
						}
					}
				} series={this.state.series}
					type="area" height="350" />
			</React.Fragment>

		);
	}
}

export default DashedLine;