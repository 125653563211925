import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
// import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { Provider } from 'react-redux';

import store from './store';

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);


ReactDOM.render(
    // <FpjsProvider
    //     cacheLocation='memory'
    //     loadOptions={{
    //     apiKey: 'ycpCrA0QWHj8c5R1Rl9I', region: 'ap'
    //     }}
    // >
        app,
    // </FpjsProvider>, 
    document.getElementById('root')
);
serviceWorker.unregister();
