import React, { Component } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";

import user1 from "../../assets/images/users/user.svg";
import profileImg from "../../assets/images/profile-img.png";

class WelcomeComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			details: {}
		};
	}

	render() {
		return (
			<React.Fragment>
				<Card className="overflow-hidden">
					<div className="bg-soft-primary">
						<Row>
							<Col xs="7">
								<div className="text-primary p-3">
									<h5 className="text-primary">Welcome Back !</h5>
									<p>{this.props.userDetails && this.props.userDetails.name}</p>
								</div>
							</Col>
							<Col xs="5" className="align-self-end">
								<img src={profileImg} alt="" className="img-fluid" />
							</Col>
						</Row>
					</div>
					<CardBody className="pt-0">
						<Row>
							<Col sm="4">
								<div className="avatar-md profile-user-wid mb-4">
									<img src={user1} alt="" className="img-thumbnail rounded-circle" />
								</div>
								<h5 className="font-size-15 text-truncate">{this.props.userDetails && this.props.userDetails.role && this.props.userDetails.role.join() }</h5>
								<p className="text-muted mb-0">{this.props.userDetails && this.props.userDetails.companyName}</p>
							</Col>

							<Col sm="8">
								<div className="pt-4">
									<Row>
										<Col xs="6">
											<h5 className="font-size-15">{this.props.vms}</h5>
											<p className="text-muted mb-0"># VMs'</p>
										</Col>
										{/* <Col xs="6">
											<h5 className="font-size-15">₹1,05,874</h5>
												<p className="text-muted mb-0">Recieved</p>
										</Col> */}
									</Row>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default WelcomeComp;
