import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import React, { Suspense, lazy, Component } from 'react';
import { Row, Col, Media, Card, CardBody, CardTitle, CardSubtitle, Table, Button, CardHeader,  Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import Editable from "react-bootstrap-editable";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

class CompaniesPage extends Component {

	constructor (props){
		super(props);
		this.state = {
			allComps: [],
			searchComps: [],
			searchCompText: "",
			selectedComp: {},
			compVms: [],
			add_cust: false,
			selectedCompInd: 0,
			resources_cost_monthly: {
				cpu: 0,
				ssd: 0,
				ram: 0,
				hdd: 0
			},
			resources_cost_hourly: {
				cpu: 0,
				ssd: 0,
				ram: 0,
				hdd: 0
			},
			modify_user: false,
			addUsers: [],
			realmUsers: [],
			compUsers: [],
			resetUserPass: {},
			reset_pass_admin: false,
		};
		this.change_discount_vm = this.change_discount_vm.bind(this);
		this.change_company_costs = this.change_company_costs.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
	}

	componentDidMount(){
		this.get_all_companies();
		this.getRealmUsers();
	}

	searchCompanies() {
		const srch_elem = this.state.searchCompText.toLowerCase();
		this.setState({
			searchComps: this.state.allComps.filter(s => s.companyName.toLowerCase().includes(srch_elem) || s.companyDomain.toLowerCase().includes(srch_elem))
		})
	}


	async resetPassword(event, values){
		this.setState({
			reset_pass_admin: false
		});	
		var query = `mutation {
			realmAdminResetUserPass(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${this.state.selectedComp.companyDomain}", userEmail:"${this.state.resetUserPass.email}", password: "${values.user_password}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.realmAdminResetUserPass){
			if (data.data.realmAdminResetUserPass.result === 1) {
				showToast("Password reset Successfully", "The password for the Company admin has been reset successully", "success");
			} else if (data.data.realmAdminResetUserPass.result === -1) {
				showToast("Password reset Failed", "No User exists with the provided email", "error");
			} else {
				showToast("Password reset Failed", "There is an error processing the request. Please try again after some time.", "error");
			}
		}
		else
			showToast("Password reset Failed", "You may not have enough privileges to reset user password. Please check your permissions with administrator", "error");
	}

	async add_user_company() {
		this.setState({
			modify_user: false
		})
		const query = `mutation {
			addUserCompany(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${this.state.selectedComp.companyDomain}",
			userEmail: ${JSON.stringify(this.state.addUsers.map((elem) => (elem.value)))}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.addUserCompany)
			if (data.data.addUserCompany.result){
				showToast("Users added", "The selected users have been successfully added for the customer", "success");
				this.get_company_details(this.state.selectedComp.companyDomain);
			}
			else
				showToast("Users addition Failed", "There is an error proceesing the request. Please try again after sometime", "error");
		else
			showToast("Users addition Failed", "You may not have enough privileges to modify the custoimer users", "error");
	}

	async get_company_costs() {
		var query = `query {
			getChildCompCosts(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${this.state.selectedComp.companyDomain}") {
				cpu
				ram
				hdd
				ssd
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getChildCompCosts && data.data.getChildCompCosts.length === 2){
			this.setState({
				resources_cost_monthly: data.data.getChildCompCosts[0],
				resources_cost_hourly: data.data.getChildCompCosts[1]
			});
		}
	}

	async change_company_costs(xhr, validValue, id){

		var query = `mutation {
			updateResourceCostsComp(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${this.state.selectedComp.companyDomain}", resourceCosts: {
				monthlyCost: {
					cpu: ${this.state.resources_cost_monthly.cpu},
					memory: ${this.state.resources_cost_monthly.ram},
					ssd: ${this.state.resources_cost_monthly.ssd},
					hdd: ${this.state.resources_cost_monthly.hdd},
				},
				hourlyCost: {
					cpu: ${this.state.resources_cost_hourly.cpu},
					memory: ${this.state.resources_cost_hourly.ram},
					ssd: ${this.state.resources_cost_hourly.ssd},
					hdd: ${this.state.resources_cost_hourly.hdd},
				},
			}){
				result
			}
		}`;
		xhr.open("POST", "/api/", true);
		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				const data = JSON.parse(xhr.responseText);
				if (data && data.data && data.data.updateResourceCostsComp)
					if (data.data.updateResourceCostsComp.result){
						showToast("Costs Updated", "The costs have been successfully updated for the customer", "success");
					}
					else
						showToast("Costs Update Failed", "There is an error proceesing the request. Please try again after sometime", "error");
				else
					showToast("Costs Update Failed", "You may not have enough privileges to modify the user costs", "error");
			}
		}
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({ "query": query }));
	}

	async get_all_companies() {
		var query =`query {
			getRealmCustomers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				companyName
				companyDomain
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getRealmCustomers && data.data.getRealmCustomers.length > 0){
			this.setState({
				allComps: data.data.getRealmCustomers.sort(function(a, b){
					if(a.companyName < b.companyName) { return -1; }
					if(a.companyName > b.companyName) { return 1; }
					return 0;
				}),
			}, () => {
				if (this.state.allComps.length > 0)
					this.get_company_details(this.state.allComps[0].companyDomain);
			});
		}
	}

	get_company_details(compDomain){
		const idx = this.state.allComps.findIndex(elem => elem.companyDomain == compDomain);
		if (idx >= 0 && idx < this.state.allComps.length) {
			var comp_dom = this.state.allComps[idx].companyDomain;
			this.setState({
				selectedCompInd: idx
			});
			this.company_details(comp_dom);
		}
	}

	async getCompanyVms() {
		const query = `query {
			getCompanyVmsRealmadmin(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${this.state.selectedComp.companyDomain}") {
				vmId, name, discount
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyVmsRealmadmin && data.data.getCompanyVmsRealmadmin.length >= 0){
			this.setState({
				compVms: data.data.getCompanyVmsRealmadmin
			})
		}
	}

	async getRealmUsers() {
		const query = `query {
			getAllRealmUsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				phoneNo
				email
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getAllRealmUsers && data.data.getAllRealmUsers.length >= 0){
			this.setState({
				realmUsers: data.data.getAllRealmUsers.filter((elem) => {return elem.email !== this.props.email}),
			});
		}		
	}

	async change_discount_vm(xhr, validValue, id) {
		var query = `mutation {
			changeDiscountVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${this.state.selectedComp.companyDomain}", vmId: "${id}", 
			df: ${validValue}){
				result
			}
		}`;
		xhr.open("POST", "/api/", true);
		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				const data = JSON.parse(xhr.responseText);
				if (data && data.data && data.data.changeDiscountVm)
					if (data.data.changeDiscountVm.result){
						showToast("Discount Updated", "The discount has been added for the selected VM", "success");
					}
					else
						showToast("Discount Update Failed", "There is an error proceesing the request. Please try again after sometime", "error");
				else
					showToast("Discount Update Failed", "You may not have enough privileges to add discount to the vm", "error");
			}
		}
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({ "query": query }));
	}

	async company_details(domain) {
		var query =`query {
			getCompanyDetails(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", compDomain: "${domain}") {
				companyName
				companyDomain
				owners {
					email
					name
				} 
				users {
					name
					email
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyDetails){
			this.setState({
				selectedComp: data.data.getCompanyDetails,
				compUsers: data.data.getCompanyDetails.owners.concat(data.data.getCompanyDetails.users)
			}, () => {
				this.get_company_costs();
				this.getCompanyVms();
			});
		}
	}

	handleMulti = selectedMulti => {
		this.setState({
			addUsers: selectedMulti
		});
	}

	render() {
		const AddUser = lazy(() => import('./add-user'));
		const loadingImg = <div className="album-img">
			<img alt="loading" src="https://digitalsynopsis.com/wp-content/uploads/2016/06/loading-animations-preloader-gifs-ui-ux-effects-23.gif" />
		</div>

		return (
			<React.Fragment>
				<Card>
				<CardHeader>
					Customers / Resllers
					<Button className="ml-4 btn btn-warning btn-sm" onClick={() => {this.setState({add_cust: true})}}>
						Add Customer
					</Button>
				</CardHeader>
				<CardSubtitle className="m-4">All Customers registered</CardSubtitle>
				<CardBody>
					<Col lg="12">
						<div className="d-lg-flex">
							<Col lg="4">
								<div className="chat-leftsidebar mr-lg-4">
									<input type="text" className='form-control' onChange={(event) => this.setState({
										searchCompText: event.target.value
									}, () => this.searchCompanies())}/>
									<div className="chat-leftsidebar-nav">
										<ul className="list-unstyled chat-list">
											<PerfectScrollbar style={{ height: "410px" }}>
												{
													(this.state.searchCompText.length > 0 ? this.state.searchComps : this.state.allComps).map((comp, idx) =>
														<li key={"Comp_" + idx} className={this.state.selectedComp.companyDomain === comp.companyDomain ? "active" : ""}>
															<div className="m-3" onClick={() => {this.get_company_details(comp.companyDomain)}}>
																<Media style={{cursor: "pointer"}} className="overflow-hidden" body>
																	<h5 className="text-truncate font-size-14 mb-1">{comp.companyName}</h5>
																	<p className="text-truncate mb-0">{comp.companyDomain}</p>
																</Media>
															</div>
														</li>
													)
												}
											</PerfectScrollbar>
										</ul>
									</div>
								</div>
							</Col>
							<Col lg="8">
								<div className="w-100 user-chat">
									<Card>
										<div>
											<div className="chat-conversation p-3">
												<ul className="list-unstyled">
													<Row>
														<Col lg="12" md="12">
															<Card>
																<CardBody>
																	<CardTitle className="mb-4">Company Information</CardTitle>
																	<div className="table-responsive">
																		<Table className="table-nowrap mb-0">
																			<tbody>
																				<tr>
																					<th scope="row">Company Name :</th>
																					<td>{this.state.selectedComp.companyName}</td>
																				</tr>
																				<tr>
																					<th scope="row">Domain :</th>
																					<td>{this.state.selectedComp.companyDomain}</td>
																				</tr>
																				{/* <tr>
																					<th scope="row">Delete Company :</th>
																					<td><Button className="btn btn-danger mr-4">Delete</Button>Note: <b>All data related to the company will be deleted</b></td>
																				</tr> */}
																			</tbody>
																		</Table>
																	</div>
																</CardBody>
															</Card>
															<Card>
																<CardBody>
																	<CardTitle className="mb-4">
																		Company Admins
																	</CardTitle>
																	<Table>
																		<tbody>
																			<tr>
																				<th>Name</th>
																				<th>Email</th>
																				<th>Reset Password</th>
																			</tr>
																			{
																				this.state.selectedComp.owners && this.state.selectedComp.owners.map((elem) => (
																					<tr>
																						<td>{elem.name}</td>
																						<td>{elem.email}</td>
																						<td><Button
																							className="btn btn-sm btn-danger"
																							onClick={() => this.setState({reset_pass_admin: true, resetUserPass: elem})}
																						>Reset</Button></td>
																					</tr>
																				))
																			}
																		</tbody>
																	</Table>
																</CardBody>
															</Card>
															<Card>
																<CardBody>
																	<CardTitle className="mb-4">
																		Company Users
																		<Button
																			className="btn btn-warning float-right btn-sm"
																			onClick={() => this.setState({modify_user: true})}
																		>
																			Add
																		</Button>
																	</CardTitle>
																	<div className="table-responsive">
																		<Table className="table-nowrap mb-0">
																			<thead>
																				<th>User Name</th>
																				<th>User Email</th>
																			</thead>
																			<tbody>
																				{
																					this.state.selectedComp.users && this.state.selectedComp.users.map((usr) => {
																						return <tr>
																							<td>{usr.name}</td>
																							<td>{usr.email}</td>
																						</tr>
																					})
																				}
																			</tbody>
																		</Table>
																	</div>
																</CardBody>
															</Card>
															<Card>
																<CardBody>
																	<CardTitle className="mb-4">
																		Company VMs'
																	</CardTitle>
																	<div className="table-responsive">
																		<Table className="table-nowrap mb-0">
																			<thead>
																				<th>VM Name</th>
																				<th>VM ID</th>
																				<th>Discount</th>
																			</thead>
																			<tbody>
																				{
																					this.state.compVms.map((elem) => (
																						<tr>
																							<td>{elem.name}</td>
																							<td>{elem.vmId}</td>
																							<td>
																								<Editable
																									editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																									label={null}
																									id={elem.vmId}
																									mode="inline"
																									ajax={this.change_discount_vm}
																									validate={(value) => {
																										try{
																											const ds = Number(value);
																											if (isNaN(ds))
																												return "Discount can only be a number"
																											else 
																												if(ds < 0)
																													return "Discount Cannot be less than 0"
																												else if(ds > 99)
																													return "Discount cannot be more than 99"
																										} catch (err) {
																											console.log(err)
																											return "Discount can only be a number"
																										}
																									}}
																									placement="top"
																									showText
																									type="textfield"
																									initialValue={elem.discount}
																								/>
																							</td>
																						</tr>
	
																					))
																				}
																			</tbody>
																		</Table>
																	</div>
																</CardBody>
															</Card>
															<Card>
																<CardBody>
																	<CardTitle className="mb-4">User Resources Cost</CardTitle>
																	<div className="table-responsive">
																		<Table className="table-nowrap mb-0">
																			<thead>
																				<th>Cycle</th>
																				<th>CPU (Core)</th>
																				<th>Memory (MB)</th>
																				<th>HDD (GB)</th>
																				<th>SSD (GB)</th>
																			</thead>
																			<tbody>
																				<tr>
																					<th>Monthly</th>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.cpu = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_monthly.cpu}
																						/>
																					</td>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.ram = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_monthly.ram}
																						/>
																					</td>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.hdd = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_monthly.hdd}
																						/>
																					</td>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.ssd = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_monthly.ssd}
																						/>
																					</td>
																				</tr>
																				<tr>
																					<th>Hourly</th>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.cpu = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_hourly.cpu}
																						/>
																					</td>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.ram = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_hourly.ram}
																						/>
																					</td>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.hdd = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_hourly.hdd}
																						/>
																					</td>
																					<td>
																						<Editable
																							editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																							label={null}
																							mode="inline"
																							ajax={this.change_company_costs}
																							onValidated={(value) => {
																								this.state.resources_cost_monthly.ssd = Number(value);
																								this.forceUpdate();
																							}}
																							validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																							placement="top"
																							showText
																							type="textfield"
																							initialValue={this.state.resources_cost_hourly.ssd}
																						/>
																					</td>
																				</tr>
																			</tbody>
																		</Table>
																	</div>
																</CardBody>
															</Card>
														</Col>
													</Row>
												</ul>
											</div>
										</div>
									</Card>
								</div>
							</Col>
						</div>
					</Col>
				</CardBody>
			</Card>
			<Modal isOpen={this.state.reset_pass_admin} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({reset_pass_admin:false})}}>
				<ModalHeader toggle={() => {this.setState({reset_pass_admin:false})}}>
					Reset Password for Company Admin
				</ModalHeader >
				<ModalBody>
					Are you sure about changing password for the Company Admin <b>{this.state.resetUserPass.name}</b> with email <b>{this.state.resetUserPass.email}</b> <br />
					<AvForm className="needs-validation mt-4" onValidSubmit={this.resetPassword}>
						<AvField
							name="user_password"
							placeholder="New Password for the User"
							type="password"
							label="Password"
							errorMessage="Password cananot be empty"
							className="form-control"
							required
							validate= {{
								minLength: {
									value: 6,
									errorMessage: "Password has to be atleast 6 characters long"
								}
							}}
						/>
						<AvField
							name="cnfrm_pass"
							placeholder="Enter Password again"
							type="password"
							label="Confirm Password"
							errorMessage="Password cananot be empty"
							className="form-control"
							required
							validate= {{
								match: {
									value: "user_password",
									errorMessage: "Password's do not match"
								}
							}}
						/>
						<Button
							color="success"
							className="btn btn-warning btn-block"
							type="submit"
						>
							Reset
						</Button>
					</AvForm>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger btn-block" onClick={() => this.setState({reset_pass_admin: false})}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.modify_user} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({modify_user:false})}}>
				<ModalHeader toggle={() => {this.setState({modify_user:false})}}>
					Add User
				</ModalHeader >
				<ModalBody>
					Add Users to {this.state.selectedComp.companyName} <br />
					<Select
						value={this.state.addUsers}
						isMulti={true}
						onChange={this.handleMulti}
						options={this.state.realmUsers.filter((usr) => this.state.compUsers.filter((cu) => cu.email === usr.email).length === 0 ).map((elem) => {
							return {
								label: `${elem.name}, ${elem.email}`,
								value: elem.email
							}
						})}
						classNamePrefix="select2-selection"
					/>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-warning btn-block" onClick={() => {this.add_user_company()}}>
						Add
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.add_cust} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({add_cust:false})}}>
				<ModalHeader toggle={() => {this.setState({add_cust:false})}}>
					Add Customer
				</ModalHeader >
				<Suspense key={"add-user"} fallback={loadingImg}>
					<AddUser {...this.props}/>
				</Suspense>
			</Modal>
		</React.Fragment>);
	}
}

export default CompaniesPage;