import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import psl from "psl";
import "./assets/scss/theme.scss";
import call_api from "./components/api-caller";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { browserName, browserVersion, isBrowser, isMobile } from "react-device-detect";

const fpPromise = FingerprintJS.load({ apiKey: 'ycpCrA0QWHj8c5R1Rl9I' })

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_crct_login: false,
			email: "",
			token: "",
			realm: psl.parse(window.location.hostname).domain,
			uniqueVisitorId: "",
			domainList: [],
			domain: "",
			browserData: {},
		};
		this.changeDomain = this.changeDomain.bind(this);
		// this.sendTokenActive = this.sendTokenActive.bind(this);
	}

	// async sendTokenActive() {
	// 	var query = `query stay_logged_in{
	// 		stayLoggedIn(email: "${this.state.email}", token: "${this.state.token}", realm: "${this.state.realm}")
	// 	}`;
	// 	const data = await call_api(query);
	// 	if (!(data && data.data && data.data.stayLoggedIn)){
	// 		localStorage.removeItem("authUser");
	// 		this.setState({
	// 			is_crct_login: false,
	// 		});
	// 		window.location = "/login";
	// 	}
	// }

	async ValidateUserLogin() {
		if (this.state.email !== "" && this.state.token !== "") {
			var query = `query validate_login{
				validateLogin(email: "${this.state.email}", token: "${this.state.token}", realm: "${this.state.realm}"){
					result
				}
			}`;
			const response = await call_api(query);
			if (response && response.data && response.data.validateLogin && response.data.validateLogin.result === "1"){
				this.setState({
					is_crct_login: true,
				});
				// setInterval(this.sendTokenActive, 300000);
			}
			else{
				localStorage.removeItem("authUser");
				this.setState({
					is_crct_login: false,
				});
				window.location = "/login";
			}
		}
	}

	async registerUserDevice() {
		if (this.state.uniqueVisitorId !== "" && this.state.browserData != {} && this.state.email !== "" && this.state.token !== "") {
			var query = `mutation {
				addUserAllowedBrowser(email: "${this.state.email}", realm: "${this.state.realm}", token: "${this.state.token}", 
				browserFingerPrint: "${this.state.uniqueVisitorId}", browserName: "${this.state.browserData.browserName}", 
				browserVersion: "${this.state.browserData.browserVersion}", isMobile: ${this.state.browserData.isMobile},
				isBrowser: ${this.state.browserData.isBrowser}
			){
					result
				}
			}`;
			const response = await call_api(query);
			if (response && response.data && response.data.addUserAllowedBrowser && response.data.addUserAllowedBrowser.result){
				console.log("Device registered successfully");
			}
			else{
				console.log("Device registration failed");
			}
		}
	}

	componentDidUpdate() {
		// this.registerUserDevice();
	}

	componentWillMount() {
		const dat = localStorage.getItem("authUser");
		const dom = localStorage.getItem("company");
		const prsntDom = localStorage.getItem("crntDomain");
		if(dat && dom){
			var data = JSON.parse(dat);
			if (data.hasOwnProperty("email") && data.hasOwnProperty("token")) {
				this.setState({
					email: data.email,
					token: data.token,
					domainList: JSON.parse(dom),
					domain: prsntDom,
				}, () => {
					if (!this.state.domain)
						this.changeDomain(0);
				});
			} else{
				localStorage.removeItem("authUser");
				this.setState({
					is_crct_login: false,
				});
			}
		}
		else
			this.setState({
				is_crct_login: false,
			});
	}

	changeDomain(indx) {
		if (indx < this.state.domainList.length){
			if (this.state.domain !== this.state.domainList[indx]) {
				this.setState({
					domain: this.state.domainList[indx]
				});
				localStorage.setItem("crntDomain", this.state.domainList[indx]);
			}
		}
	}

	componentDidMount(){
		this.ValidateUserLogin();
		fpPromise
		.then(fp => fp.get())
		.then(result => {
			this.setState({
				uniqueVisitorId: result.visitorId,
			});
		})
		this.setState({
			browserData: {
				browserName: browserName,
				browserVersion: browserVersion,
				isBrowser: isBrowser,
				isMobile: isMobile,
			}
		})
	}

	render() {
		const Layout = HorizontalLayout;
		return (
			<React.Fragment>
				<Router>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								isLoggedIn={this.state.is_crct_login}
								realm={this.state.realm}
								email={this.state.email}
								token={this.state.token}
								domain={this.state.domain}
								browserData={this.state.browserData}
								domainList={this.state.domainList}
								domainChange={this.changeDomain}
								uniqueVisitorId={this.state.uniqueVisitorId}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isLoggedIn={this.state.is_crct_login}
								realm={this.state.realm}
								browserData={this.state.browserData}
								email={this.state.email}
								token={this.state.token}
								domain={this.state.domain}
								domainList={this.state.domainList}
								uniqueVisitorId={this.state.uniqueVisitorId}
								domainChange={this.changeDomain}
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
