import PropTypes from 'prop-types'
import React, { Component } from "react"
import {
	Alert,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Media,
	Row, FormGroup, Label, 
} from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user.svg"
// actions
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";

class UserProfile extends Component {
	constructor(props) {
		super(props)
		this.state = { 
			userDetails: {},
			email: "", 
			name: "", 
			countries: [],
			states: [],
			cities: [],
			country: "",
			state: "",
			city: "",
			idx: 1,
			userRoles: [],
			config: {},
			userMfAuthConfig: {},
		}

		// handleValidSubmit
		this.change_user_details = this.change_user_details.bind(this);
		this.change_user_password = this.change_user_password.bind(this);
	}

	async get_user_config(){
		const query = `query {
			getUserConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}") {
				otpAdminLogin
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserConfig){
			this.setState({
				config: data.data.getUserConfig
			});
		}
	}

	async change_user_self_config(otp_login) {
		const query = `mutation {
			changeUserSelfConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", otpAdminLogin: ${otp_login}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserSelfConfig){
			if (data.data.changeUserSelfConfig.result) {
				showToast("Config Modified", "Your personal configuration has been modified successfully", "success");
				this.get_user_config();
			}
			else
				showToast("Config Modification Failed", "There is an error processing the request. Please try again after some time", "error");
		} else {
			showToast("Config Modification Failed", "Your session might have expired. Please try reloading the page.", "error");
		}
	}

	async change_user_details(event, values) {
		const query = `mutation {
			changeUserDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", newEmail: "${values.email}", name: "${values.name}", mobileNumber: "${values.phone_no}"
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserDetails){
			if (data.data.changeUserDetails.result === 1) {
				showToast("Details Modified", "Your personal details have been modified successfully", "success");
				this.get_user_details();
			}
			else if (data.data.changeUserDetails.result === -1)
				showToast("Details Modification Failed", "The entered email seems to already exist. Please choose another email", "error");
			else if (data.data.changeUserDetails.result === 0)
				showToast("Details Modification Failed", "There is an error processing the request. Please try again after some time", "error");
		} else {
			showToast("Details Modification Failed", "Your session might have expired. Please try reloading the page.", "error");
		}
	}

	async change_user_password(event, values) {
		const query = `mutation {
			changeUserPassword(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", oldPass: "${values.crnt_password}", newPass: "${values.new_password}"
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserPassword){
			if (data.data.changeUserPassword.result === 1)
				showToast("Password Changed", "Your password has been changed successfully", "success");
			else if (data.data.changeUserPassword.result === -1)
				showToast("Password change Failed", "The entered current password might be incorrect. Please try again", "error");
			else if (data.data.changeUserPassword.result === 0)
				showToast("Password change Failed", "There is an error processing the request. Please try again after some time", "error");
		} else {
			showToast("Password change Failed", "Your session might have expired. Please try reloading the page.", "error");
		}
	}

	async change_user_mf_auth_config(sms, mail, whatsapp) {
		var query = `mutation {
			changeUserMfAuthConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}",
				sms: ${sms}, mail: ${mail}, whatsapp: ${whatsapp}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserMfAuthConfig){
			if (data.data.changeUserMfAuthConfig.result) {
				showToast("MFA Config Modfied", "Multi factor authentication configuration changed successfully for the user", "success");
				this.get_user_mf_auth_config();
			} else {
				showToast("MFA Config Modfication failed", "There is an error changing multi factor authentication configuration for the user. Please try again after some time", "error");
			}
		} else {
			showToast("MFA Config Modfication failed", "You may not have enough privileges to change multi factor authentication configuration for the user.", "error");
		}
	}
	
	async get_user_mf_auth_config() {
		var query = `query {
			getUserMultiFactorAuthConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				sms
				mail
				whatsapp
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserMultiFactorAuthConfig){
			this.setState({
				userMfAuthConfig: data.data.getUserMultiFactorAuthConfig
			});
		}
	}

	async change_user_address(){
		const query = `mutation {
			changeUserAddress(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}",
				address:{
					address:"${this.state.address.replace(/\n/g, " ")}",
					state:"${this.state.state}",
					city:"${this.state.city}",
					country:"${this.state.country}",
				}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserAddress){
			if (data.data.changeUserAddress.result === 1)
				showToast("Address Modified", "Your address details have been modified successfully", "success");
			else if (data.data.changeUserAddress.result === -1)
				showToast("Address Modification Failed", "The entered address seems to be incomplete. Please try again", "error");
			else if (data.data.changeUserAddress.result === 0)
				showToast("Address Modification Failed", "There is an error processing the request. Please try again after some time", "error");
		} else {
			showToast("Address Modification Failed", "Your session might have expired. Please try reloading the page.", "error");
		}
	}

	async get_user_details() {
		const query = `query {
			getUserDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name, phoneNo, compRoles {
					companyName,
					role
				}, 
				address {
					country, state, city, address
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserDetails){
			this.setState({
				userDetails: data.data.getUserDetails,
				userRoles: data.data.getUserDetails.compRoles
			}, () => {
				if (data.data.getUserDetails.address.city && data.data.getUserDetails.address.country && data.data.getUserDetails.address.state && data.data.getUserDetails.address.address) {
					this.setState({
						country: data.data.getUserDetails.address.country
					}, () => {
						this.get_states(data.data.getUserDetails.address.country).then(() => {
							this.setState({
								state: data.data.getUserDetails.address.state
							}, () => {
								this.get_cities(data.data.getUserDetails.address.state).then(() => {
									this.setState({
										city: data.data.getUserDetails.address.city,
										address: data.data.getUserDetails.address.address
									});
								})
							})
						})
					});
				}

			});
		}
	}
	async get_states(country){
		const requestOptions = {
			method: 'GET',
			headers: {
				'Accept': 'application/json', 
				"Authorization": 'Bearer ' + this.state.auth_token
			},
		};
		const resp = await fetch('https://www.universal-tutorial.com/api/states/'+country, requestOptions);
		if (resp.status === 200){
			const data = await resp.json();

			this.setState({
				country: country,
				states: data.map((elem) => {return elem.state_name})
			});
		}
	}

	async get_cities(state){
		const requestOptions = {
			method: 'GET',
			headers: {
				'Accept': 'application/json', 
				"Authorization": 'Bearer ' + this.state.auth_token
			},
		};
		const resp = await fetch('https://www.universal-tutorial.com/api/cities/'+state, requestOptions);
		if (resp.status === 200){
			const data = await resp.json();
			this.setState({
				state: state,
				cities: data.map((elem) => {return elem.city_name})
			});
		}
	}

	async get_countries(){
		const requestOptions = {
			method: 'GET',
			headers: {
				'Accept': 'application/json', 
				"Authorization": 'Bearer ' + this.state.auth_token
			},
		};
		const cointryResp = await fetch('https://www.universal-tutorial.com/api/countries/', requestOptions);
		if (cointryResp.status === 200){
			const countries = await cointryResp.json();
			this.setState({
				countries: countries.map((elem) => {return elem.country_name})
			});
		}
	}


	async get_states_data() {
		const requestOptions = {
			method: 'GET',
			headers: { 'Accept': 'application/json', "api-token": 'VX5rWYh0jEjDxSA4wSHAO7YHd6_k9C7zB1jlZPVCcV0rAOuQ2oa-14zldy5-4Fp-S-U', "user-email": "hopena3260@pnrep.com" },
		};
		const resp = await fetch('https://www.universal-tutorial.com/api/getaccesstoken/', requestOptions);
		if (resp.status === 200){
			const data = await resp.json();
			this.setState({
				auth_token: data.auth_token
			}, () => {
				this.get_countries();
			});
		}
	}


	componentDidMount() {
		this.get_user_details();
		this.get_states_data();
		this.get_user_config();
		this.get_user_mf_auth_config();
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumb title="Skote" breadcrumbItem="Profile" />

						<Row>
							<Col lg="12">
								{this.props.error && this.props.error ? (
									<Alert color="danger">{this.props.error}</Alert>
								) : null}
								{this.props.success && this.props.success ? (
									<Alert color="success">{this.props.success}</Alert>
								) : null}

								<Card>
									<CardBody>
										<Media>
											<div className="mr-3">
												<img
													src={avatar}
													alt=""
													className="avatar-md rounded-circle img-thumbnail"
												/>
											</div>
											<Media body className="align-self-center">
												<div className="text-muted">
													<h5>{this.state.userDetails.name}</h5>
													<p className="mb-1">{this.props.email}</p>
													<p className="mb-0">{this.state.userRoles.map((comp) => <p className="mb-0"><b className="mr-2">{comp.companyName}</b>:<span className="ml-1">{comp.role.join(", ")}</span></p>)}</p>
												</div>
											</Media>
										</Media>
									</CardBody>
								</Card>
							</Col>
						</Row>

						<h4 className="card-title mb-4">Change Personal Details</h4>

						<Card>
							<CardBody>
								<AvForm
									className="form-horizontal"
									onValidSubmit={this.change_user_details}
								>
									<div className="form-group">
										<AvField
											name="email"
											label="Email"
											value={this.props.email}
											className="form-control"
											placeholder="Enter Your Email"
											type="email"
											required
											errorMessage="Email is required"
										/>
									</div>
									<div className="form-group">
										<AvField
											name="name"
											label="Name"
											value={this.state.userDetails.name}
											className="form-control"
											placeholder="Enter Your Name"
											type="text"
											errorMessage="Name is required"
											required
										/>
									</div>
									<div className="form-group">
										<AvField
											name="phone_no"
											label="Mobile Number"
											value={this.state.userDetails.phoneNo}
											className="form-control"
											placeholder="Enter Your Mobile Number"
											type="number"
											errorMessage="Mobile Number is required"
											validate={{
												max: {value: 9999999999, errorMessage: "Invalid Mobile Number"},
												min: {value: 1000000000, errorMessage: "Invalid Mobile Number"},
											}}
											required
										/>
									</div>
									<div className="text-center mt-4">
										<Button type="submit" color="danger">
											Edit Details
										</Button>
									</div>
								</AvForm>
							</CardBody>
						</Card>

						<h4 className="card-title mb-4">Your Address Details</h4>
						<Card>
							<CardBody>
								<FormGroup className="mb-4" row>
									<Label htmlFor="billing-phone" md="2" className="col-form-label">Country</Label>
									<Col md={10}>
										<select className="form-control" required value={this.state.country} onChange={(event) => {this.get_states(event.target.value)}}>
											<option key="Country_" value="null">Select</option>
											{
												this.state.countries.map((cntry, ind) => (
													<option key={"Country_"+ind} value={cntry}>{cntry}</option>
												))
											}
										</select>
									</Col>
								</FormGroup>
								<FormGroup className="mb-4" row>
									<Label htmlFor="billing-phone" md="2" className="col-form-label">State</Label>
									<Col md={10}>
										<select className="form-control" required value={this.state.state} onChange={(event) => {this.get_cities(event.target.value)}}>
											<option key="State_" value="null">Select</option>
											{
												this.state.states.map((states, ind) => (
													<option key={"State_"+ind} value={states}>{states}</option>
												))
											}
										</select>
									</Col>
								</FormGroup>
								<FormGroup className="mb-4" row>
									<Label htmlFor="billing-phone" md="2" className="col-form-label">City</Label>
									<Col md={10}>
										<select className="form-control" required value={this.state.city} onChange={(event) => {this.setState({city: event.target.value})}}>
											<option key="City_" value="null">Select</option>
											{
												this.state.cities.map((city, ind) => (
													<option key={"City_"+ind} value={city}>{city}</option>
												))
											}
										</select>
									</Col>
								</FormGroup>
								<FormGroup className="mb-4" row>
									<Label htmlFor="billing-address" md="2" className="col-form-label">Address</Label>
									<Col md="10">
										<textarea className="form-control" required value={this.state.address} id="billing-address" rows="3" placeholder="Enter full address" onChange={(event) => {this.setState({address: event.target.value})}} ></textarea>
									</Col>
								</FormGroup>
								<div className="text-center mt-4">
									<Button type="submit" color="danger" onClick={() => this.change_user_address()}>
										Edit Address
									</Button>
								</div>
							</CardBody>
						</Card>

						<h4 className="card-title mb-4">Change Password</h4>

						<Card>
							<CardBody>
								<AvForm
									className="form-horizontal"
									onValidSubmit={this.change_user_password}
								>
									<div className="form-group">
										<AvField
											name="crnt_password"
											label="Current Password"
											className="form-control"
											placeholder="Enter Your Current Password"
											type="password"
											required
											errorMessage="Password is required"
										/>
									</div>
									<div className="form-group">
										<AvField
											name="new_password"
											label="New Password"
											className="form-control"
											placeholder="Enter your new Password"
											type="password"
											errorMessage="Password is required"
											required
										/>
									</div>
									<div className="form-group">
										<AvField
											name="cnfrm_password"
											label="Confirm Password"
											className="form-control"
											placeholder="Enter Confirm password"
											type="password"
											errorMessage="Confirm Password is required"
											validate={{
												match: { value: "new_password", errorMessage: "Passwords do not match" }
											}}
											required
										/>
									</div>
									<div className="text-center mt-4">
										<Button type="submit" color="danger">
											Change Password
										</Button>
									</div>
								</AvForm>
							</CardBody>
						</Card>

						<h4 className="card-title mb-4">Your Configuration</h4>
						<Card>
							<CardBody>
								<Row>
									<Col lg="2" md="4" sm="6">
										<Label>OTP Login (admin)</Label>
									</Col>
									<Col lg="10" md="8" sm="6">
										<div
											className="custom-control custom-switch mb-2"
											dir="ltr"
										>
											<input
												type="checkbox"
												className="custom-control-input"
												id="otpAdmLog"
												checked={this.state.config.otpAdminLogin}
											/>
											<label
												className="custom-control-label"
												htmlFor="otpAdmLog"
												onClick={() => {
													this.change_user_self_config(!this.state.config.otpAdminLogin)
												}}
											>
											</label>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>

						<h4 className="card-title mb-4">Your Multi Facotr Authentication Configuration</h4>
						<Card>
							<CardBody>
								<Row>
									<Col lg="2" md="4" sm="6">
										<Label>SMS</Label>
									</Col>
									<Col lg="10" md="8" sm="6">
										<div
											className="custom-control custom-switch mb-2"
											dir="ltr"
										>
											<input
												type="checkbox"
												className="custom-control-input"
												id="otpAdmLog"
												checked={this.state.userMfAuthConfig.sms}
											/>
											<label
												className="custom-control-label"
												htmlFor="otpAdmLog"
												onClick={() => {
													this.change_user_mf_auth_config(!this.state.userMfAuthConfig.sms, this.state.userMfAuthConfig.mail, this.state.userMfAuthConfig.whatsapp);
												}}
											>
											</label>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg="2" md="4" sm="6">
										<Label>Mail</Label>
									</Col>
									<Col lg="10" md="8" sm="6">
										<div
											className="custom-control custom-switch mb-2"
											dir="ltr"
										>
											<input
												type="checkbox"
												className="custom-control-input"
												id="otpAdmLog"
												checked={this.state.userMfAuthConfig.mail}
											/>
											<label
												className="custom-control-label"
												htmlFor="otpAdmLog"
												onClick={() => {
													this.change_user_mf_auth_config(this.state.userMfAuthConfig.sms, !this.state.userMfAuthConfig.mail, this.state.userMfAuthConfig.whatsapp);
												}}
											>
											</label>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg="2" md="4" sm="6">
										<Label>Whatsapp</Label>
									</Col>
									<Col lg="10" md="8" sm="6">
										<div
											className="custom-control custom-switch mb-2"
											dir="ltr"
										>
											<input
												type="checkbox"
												className="custom-control-input"
												id="otpAdmLog"
												checked={this.state.userMfAuthConfig.whatsapp}
											/>
											<label
												className="custom-control-label"
												htmlFor="otpAdmLog"
												onClick={() => {
													this.change_user_mf_auth_config(this.state.userMfAuthConfig.sms, this.state.userMfAuthConfig.mail, !this.state.userMfAuthConfig.whatsapp);
												}}
											>
											</label>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}
export default UserProfile;
