import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser,apiError } from '../../store/actions';

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import call_api from "../../components/api-caller";

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			mfa_auth: false,
			user_email: "",
			mfa_error: null,
		}
		this.do_login = this.do_login.bind(this);
		this.checkMfaAuth = this.checkMfaAuth.bind(this);
	}

	async checkMfaAuth(event, values) {
		var query = `query {
			mfaAuthLogin(email: "${this.state.user_email}", realm: "${this.props.realm}", mfaAuthCode: ${values.mfa_otp})
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.mfaAuthLogin) {
			localStorage.setItem("authUser", JSON.stringify({email: this.state.user_email, token: data.data.mfaAuthLogin}));
			window.location = "/";
		} else {
			this.setState({
				mfa_error: "Invalid OTP Entered",
			})
		}
	}

	async do_login(event, values) {
		var query = `query {
			login(email: "${values.email}", realm: "${this.props.realm}", password: "${values.password}"){
				email
				token
				domain
				mfaAuth
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.login) {
			if(data.data.login.mfaAuth) {
				this.setState({
					mfa_auth: true,
					user_email: data.data.login.email,
				});
				localStorage.setItem("company", JSON.stringify(data.data.login.domain));
			} else if(data.data.login.token !== "0" && data.data.login.domain){
				localStorage.setItem("authUser", JSON.stringify({email: data.data.login.email, token: data.data.login.token}));
				localStorage.setItem("company", JSON.stringify(data.data.login.domain));
				window.location = "/";
			} else {
				this.setState({
					error: "Entered Email or Password is incorrect. Please try again"
				})					
			}
		} else {
			this.setState({
				error: "Entered Email or Password is incorrect. Please try again"
			})
		}
	}

	checkLogin() {
		var cmp = localStorage.getItem("authUser");
		if (cmp && JSON.parse(cmp)){
			const dat = JSON.parse(cmp);
			if (dat && dat.email && dat.token){
				this.props.history.push("/");
			} else {
				localStorage.removeItem("authUser");
			}
		}
	}

	checkTokenPass() {
		const tkn = this.props.match.params;
		if (tkn.token) {
			try {
				const token = JSON.parse(atob(tkn.token));
				if (token && token.email && token.token && token.domains && token.domains.length > 0){
					localStorage.setItem("authUser", JSON.stringify({email: token.email, token: token.token}));
					localStorage.setItem("company", JSON.stringify(token.domains));
					window.location = "/";
				}
			} catch (error) {
				console.log(error);
			}
		}
	}

	componentWillMount() {
		this.checkLogin();
		this.checkTokenPass();
	}

	render() {

		return (
			<React.Fragment>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-soft-primary">
										<Row>
											<Col className="col-7">
												<div className="text-primary p-4">
													<h5 className="text-primary">Welcome Back !</h5>
													<p>Sign in to continue to DeepCloud.</p>
												</div>
											</Col>
											<Col className="col-5 align-self-end">
												<img src={profile} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<div>
											<Link to="/">
												<div className="avatar-md profile-user-wid mb-4">
													<span className="avatar-title rounded-circle bg-light">
														<img src={logo} alt="" height="34" />
													</span>
												</div>
											</Link>
										</div>
										<div className="p-2">

											<AvForm className="form-horizontal" onValidSubmit={this.do_login}>

												{this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}

												<div className="form-group">
													<AvField name="email" label="Email" className="form-control" placeholder="Email Address" type="text" required />
												</div>

												<div className="form-group">
													<AvField name="password" label="Password" type="password" required placeholder="Enter Password" />
												</div>

												<div className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id="customControlInline" />
													<label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
												</div>

												<div className="mt-3">
													<button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
												</div>

												<div className="mt-4 text-center">
													<Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
												</div>
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>Don't have an account ? <Link to="register" className="font-weight-medium text-primary"> Signup now </Link> </p>
									<p>Looking for <Link to="downloads_page" className="font-weight-medium text-primary"> Downloads </Link> </p>
									Crafted with <i className="mdi mdi-heart text-danger"></i> by <p>© {new Date().getFullYear()} DeepCloud</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Modal isOpen={this.state.mfa_auth} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({mfa_auth:false})}}>
					<ModalHeader toggle={() => {this.setState({mfa_auth:false})}}>
						Multi Factor Authentication
					</ModalHeader >
					<ModalBody>
						Multi Factor Authentication has been enabled for this user account. A 6 digit OTP code has been sent to your registered Mobile Number or Email accordingly. Please enter the code
						<AvForm className="form-horizontal" onValidSubmit={this.checkMfaAuth}>

							{this.state.mfa_error ? <Alert color="danger">{this.state.mfa_error}</Alert> : null}

							<Row>
								<Col md="12">
									<AvField
										name="mfa_otp"
										className="form-control"
										errorMessage="OTP cannot be empty"
										placeholder="OTP"
										type="number"
										required
									/>
								</Col>
							</Row>
							<Row>
								<Col md="12">
									<Button
										type="submit"
									>
										Submit
									</Button>
								</Col>
							</Row>
						</AvForm>
					</ModalBody>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = state => {
	const { error } = state.Login;
	return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Login));

