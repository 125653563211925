import React, { Component } from 'react';
import { Container, Card, CardBody, NavItem, NavLink, TabContent, TabPane, Row, Col, Nav, } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import Users from "./users";
import PaymentsPage from "./payments";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import classnames from 'classnames';
import VmsPage from './vms';
import Catalogs from './catalogs';
import PaymentGatewayDetails from "./paymentGateways";
import AppTemplates from './appTemplates';
import CompaniesPage from "./customers";
import CompanyPage from "./company";
import VMDeletionRequests from "./vmDeletion";
import SharedAppsPage from './sharedApps';
import ResetPassword from './reset-password';

class UsersPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabId: 0,
			vm_types: [],
			realmAdmin: false,
		}
	}

	async get_vm_types() {
		var query = `query {
			getVmTypesList(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				typeId
				name
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmTypesList && data.data.getVmTypesList.length > 0){
			this.setState({
				vm_types: data.data.getVmTypesList
			});
		}
	}

	async is_realm_admin() {
		var query = `query {
			isRealmAdmin(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				result
			}
		}`;
		const data = await call_api(query);
		if ( data && data.data && data.data.isRealmAdmin && data.data.isRealmAdmin.result === "1" ){
			this.setState({
				realmAdmin: true,
			})
		}
	}

	async get_login_info() {
		var query = `query is_higher_user{
			isHigherUser(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (!( data && data.data && data.data.isHigherUser && data.data.isHigherUser.result === "1")){
			showToast("Cannot Access User Page", "You may not have required privilages to access content", "error");
			this.props.history.push("/");
		}
	}

	componentDidMount() {
		this.get_login_info();
		this.get_vm_types();
		this.is_realm_admin();
	}

	returnPage() {
		var format = new Intl.NumberFormat('en-IN', { 
			style: 'currency', 
			currency: 'INR', 
			minimumFractionDigits: 2, 
		});
		if (this.state.tabId === 0) {
			return <Users {...this.props} currencyFormat={format} />
		} else if (this.state.tabId === 1) {
			return <PaymentsPage {...this.props} currencyFormat={format} />
		} else if (this.state.tabId === 2) {
			if (this.state.realmAdmin)
				return <CompaniesPage {...this.props} currencyFormat={format} />
		} else if (this.state.tabId === 3) {
			return <VmsPage {...this.props} currencyFormat={format} vm_types={this.state.vm_types}/>
		} else if (this.state.tabId === 4) {
			return <Catalogs {...this.props} currencyFormat={format} />
		} else if (this.state.tabId === 5) {
			return <PaymentGatewayDetails {...this.props}/>
		} else if (this.state.tabId === 6) {
			return <AppTemplates {...this.props}/>
		} else if (this.state.tabId === 7) {
			return <CompanyPage {...this.props}/>
		} else if (this.state.tabId === 8) {
			return <VMDeletionRequests {...this.props}  vm_types={this.state.vm_types}/>
		} else if (this.state.tabId === 9) {
			return <SharedAppsPage {...this.props} />
		} else if (this.state.tabId === 10) {
			return <ResetPassword {...this.props}/>
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Users" breadcrumbItem="Users" />
						<div className="checkout-tabs">
							<Row>
								<Col lg="2">
									<Nav className="flex-column" pills>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 0 })}
												onClick={() => { this.setState({tabId: 0}) } }
											>
												<i className="bx bxs-user-detail d-block check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Users</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 1 })}
												onClick={() => { this.setState({tabId: 1}) } }
											>
												<i className="bx bx-rupee d-block check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Payments</p>
											</NavLink>
										</NavItem>
											{
												this.state.realmAdmin ?
												<NavItem>
													<NavLink
														className={classnames({ active: this.state.tabId === 2 })}
														onClick={() => { this.setState({tabId: 2}) } }
													>
														<i className="bx bx-group check-nav-icon mt-4 mb-2"></i>
														<p className="font-weight-bold mb-4">Customers</p>
													</NavLink>
												</NavItem> : null
											}
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 7 })}
												onClick={() => { this.setState({tabId: 7}) } }
											>
												<i className="bx bxs-grid check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Company</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 3 })}
												onClick={() => { this.setState({tabId: 3}) } }
											>
												<i className="bx bx-card check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">VMs'</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 4 })}
												onClick={() => { this.setState({tabId: 4}) } }
											>
												<i className="bx bxs-notification check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Catalogs</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 5 })}
												onClick={() => { this.setState({tabId: 5}) } }
											>
												<i className="bx bxs-credit-card check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Payment Gateway</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 6 })}
												onClick={() => { this.setState({tabId: 6}) } }
											>
												<i className="bx bxs-grid check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">App Templates</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 8 })}
												onClick={() => { this.setState({tabId: 8}) } }
											>
												<i className="bx bx-trash check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">VM Deletion Requests</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 9 })}
												onClick={() => { this.setState({tabId: 9}) } }
											>
												<i className="bx bxs-grid check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Shared Apps</p>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({ active: this.state.tabId === 10 })}
												onClick={() => { this.setState({tabId: 10}) } }
											>
												<i className="bx bx-lock-open d-block check-nav-icon mt-4 mb-2"></i>
												<p className="font-weight-bold mb-4">Reset Password</p>
											</NavLink>
										</NavItem>
									</Nav>
								</Col>
								<Col lg={10}>
									<Card>
										<CardBody>
											{this.returnPage()}
										</CardBody>
									</Card>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default withNamespaces()(UsersPage);