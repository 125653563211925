import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';


class GetHelp extends Component {
	constructor() {
		super();
		this.state = {
			problem: "",
			description: "",
		}
		this.submitQuery = this.submitQuery.bind(this);
	}

	getUserCreds() {
		var userCreds = JSON.parse(localStorage.getItem("authUser"));
		if (userCreds && userCreds.hasOwnProperty("email") && userCreds.hasOwnProperty("token")){
			var email = userCreds["email"];
			var token = userCreds["token"];
			this.setState({
				email: email,
				token: token,
			});
		}
	}

	showToast(title, message, toastType){
		toastr.options = {
			closeButton: true,
			debug: false,
			extendedTimeOut: "1000",
			hideDuration: "1000",
			hideEasing: "linear",
			hideMethod: "fadeOut",
			newestOnTop: false,
			positionClass: "toast-top-center",
			preventDuplicates: true,
			progressBar: true,
			showDuration: "300",
			showEasing: "swing",
			showMethod: "fadeIn",
			timeOut: "5000",
		}
		
		if(toastType === "info")
			toastr.info(message,title)
		else if(toastType === "warning")
			toastr.warning(message,title)
		else if(toastType === "error")
			toastr.error(message,title)
		else
			toastr.success(message,title)
	}

	async submitQuery() {
		var query = `mutation add_query{
			addQuery(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", query: "${this.state.query}", description: "${this.state.description}") {
				result
			}
		}`;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ "query": query })
		};
		const resp = await fetch('/api/', requestOptions);
		const data = await resp.json();
		if (data && data.data && data.data.addQuery && data.data.addQuery.result === 1){
			this.showToast("Query Recieved", "Thanks for your request. Our team is seamingly working on providing more features and minimal bugs. Our support team would get back to you regarding this concern.", "success");
		} else{
			this.showToast("Unable to send Query", "There is a trouble sending the query. Please login again. If the problem still exists, please contact Admin", "error");
		}

	}

	componentDidMount(){
		this.getUserCreds();
	}

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Help" breadcrumbItem="Request" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Request Help from Support team</CardTitle>
                                        <AvForm className="needs-validation" onValidSubmit={this.submitQuery}>
											<Row>
												<Col>
													<AvField
														name="problem"
														placeholder="Give the problem"
														type="text"
														label="Problem"
														errorMessage="Problem cannot be empty"
														className="form-control"
														required
														onChange = {(event) => {this.setState({problem: event.target.value})}} 
													/>

												</Col>
											</Row>
											<Row>
												<Col>
													<AvField
														name="description"
														placeholder="Describe the problem"
														type="text"
														label="Problem Description"
														errorMessage="Problem cannot be empty"
														className="form-control"
														required
														onChange = {(event) => {this.setState({description: event.target.value})}} 
													/>

												</Col>
											</Row>
											<Row >
												<Col lg="6">
													<Button type="submit" color="primary">Submit</Button>
												</Col>
											</Row>

										</AvForm>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default GetHelp;