import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function showToast(title, message, toastType){
	toastr.options = {
		closeButton: true,
		debug: false,
		extendedTimeOut: "1000",
		hideDuration: "1000",
		hideEasing: "linear",
		hideMethod: "fadeOut",
		newestOnTop: false,
		positionClass: "toast-top-center",
		preventDuplicates: true,
		progressBar: true,
		showDuration: "300",
		showEasing: "swing",
		showMethod: "fadeIn",
		timeOut: "5000",
	}
	
	if(toastType === "info")
		toastr.info(message,title)
	else if(toastType === "warning")
		toastr.warning(message,title)
	else if(toastType === "error")
		toastr.error(message,title)
	else
		toastr.success(message,title)
}

export default showToast;