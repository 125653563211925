import React, { Component } from "react"
import {
	Button,
	Col,
	Form,
	Input,
	Label,
	Row,
} from "reactstrap";
import call_api from "../../components/api-caller";
import showToast from "../../components/showToast";

class PortForwardGroup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			rows: [],
		}
	}

	handleItemChange(id, vm_port, client_port, reverse_port_forward, user_email) {
		this.state.rows[id].vmPort = vm_port;
		this.state.rows[id].clientPort = client_port;
		this.state.rows[id].reverseForward = reverse_port_forward;
		this.state.rows[id].userEmail = user_email;
		this.forceUpdate();
	}
	
	componentDidMount() {
		this.setState({
			rows: this.props.portLists,
		})				
	}

	handleAddRow = () => {
		const item = {
			vmPort: 80,
			clientPort: 80,
			reverseForward: false,
			userEmail: null,
		};
		this.setState({
			rows: [...this.state.rows, item],
		})
	}

	async addNewPortForward(id){
		const element = this.state.rows[id];
		const add_modify = element.id === undefined;
		const query = `mutation {
			modifyPortForwards(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.props.nodeid}", vmId: "${this.props.vmid}",
				addModify: ${add_modify}, vmPort: ${element.vmPort}, clientPort: ${element.clientPort}, reverseForward: ${element.reverseForward}, userEmail: "${element.userEmail}",
				${element.id ? `id: ${element.id}` : ""}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyPortForwards) {
			if (data.data.modifyPortForwards.result === 1) {
				showToast(`Port Forward ${add_modify ? "Created" : "Modified"}`, add_modify ? "A new port forward rule has been created" : "The Port Forward rule modified successfully", "success");
				this.props.reloader();
			} else if (data.data.modifyPortForwards.result === -1) {
				showToast(`Port Forward ${add_modify ? "creation" : "modification"} failed`, "Invalid Parameters specified. Please Provide all the fields correctly", "error");
			} else if (data.data.modifyPortForwards.result === -2) {
				showToast(`Port Forward ${add_modify ? "creation" : "modification"} failed`, "A port forward rule already exists for the same ports", "error");
			} else if (data.data.modifyPortForwards.result === -3) {
				showToast(`Port Forward ${add_modify ? "creation" : "modification"} failed`, "Couldn't find the port forward rule with the specified id. Please try again", "error");
			} else if (data.data.modifyPortForwards.result === -4) {
				showToast(`Port Forward ${add_modify ? "creation" : "modification"} failed`, "No user exists with the given mail. Please enter a valid email", "error");
			} else{
				showToast(`Port Forward ${add_modify ? "creation" : "modification"} failed`, "An error occured processing the request. Please try again after some time", "error");
			} 			
		} else 
			showToast(`Port Forward ${add_modify ? "creation" : "modification"} failed`, "Your session might have expired. Please try reloding the page.", "error");
	}

	async deletePortForward(id) {
		const element = this.state.rows[id];
		if (element.id) {
			const query = `mutation {
				modifyPortForwards(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.props.nodeid}", vmId: "${this.props.vmid}", 
					delete: true,  id: ${element.id}
				) {
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.modifyPortForwards) {
				if (data.data.modifyPortForwards.result === 1) {
					showToast("Port Forward Deleted", "The selected port forward rule has been deleted for the IP", "success");
					this.setState({rows: this.state.rows.filter((elm, indx) => (indx !== id) )});
					this.props.reloader();
				} else if (data.data.modifyPortForwards.result === -2) {
					showToast("Port Forward Deletion failed", "The selected port forward rule doesn't exist for the VM", "error");
				} else{
					showToast("Port Forward Deletion failed", "An error occured processing the request. Please try again after some time", "error");
				}
			} else 
				showToast("Port Forward Deletion failed", "Your session might have expired. Please try reloding the page.", "error");	
			} else {
			this.setState({rows: this.state.rows.filter((elm, indx) => (indx !== id) )});
		}
		
	}

	render() {
		return (
			<React.Fragment>
				<table style={{ width: "100%" }}>
					<tbody>
						{this.state.rows.map((item, idx) => (
							<tr id={"addr" + idx} key={idx}>
								<td>
									<Form
										className="repeater"
										encType="multipart/form-data"
									>
										<div data-repeater-list="group-a">
											<Row data-repeater-item>
												<Col lg={this.state.rows[idx].reverseForward ? 2 : 4} className="form-group">
													<Label htmlFor={"vm_port"+idx}>VM Port</Label>
													<Input value={item.vmPort} type="number" id={"port"+idx} onChange={(event) => this.handleItemChange(idx, event.target.value, this.state.rows[idx].clientPort, this.state.rows[idx].reverseForward, this.state.rows[idx].userEmail)} />
												</Col>
												<Col lg={this.state.rows[idx].reverseForward ? 2 : 4} className="form-group">
													<Label htmlFor={"clnt_port"+idx}>Client Port</Label>
													<Input value={item.clientPort} type="number" id={"port"+idx} onChange={(event) => this.handleItemChange(idx, this.state.rows[idx].vmPort, event.target.value, this.state.rows[idx].reverseForward, this.state.rows[idx].userEmail)} />
												</Col>
												{
													this.state.rows[idx].reverseForward && 
													<Col lg={4} className="form-group">
														<Label htmlFor={"user_email"+idx}>User Email</Label>
														<Input value={item.userEmail} type="text" id={"port"+idx} onChange={(event) => this.handleItemChange(idx, this.state.rows[idx].vmPort, this.state.rows[idx].clientPort, this.state.rows[idx].reverseForward, event.target.value)} />
													</Col>
												}
												<Col lg={2} className="form-group">
													<Label htmlFor={"port"+idx}>Reverse Port Forward</Label>
													<div
														className="custom-control custom-switch mb-2"
														dir="ltr"
													>
														<input
															type="checkbox"
															className="custom-control-input"
															id={"customSwitch" + idx}
															checked={item.reverseForward}
															onChange={() => {
																this.handleItemChange(idx, this.state.rows[idx].vmPort, this.state.rows[idx].clientPort, !this.state.rows[idx].reverseForward, this.state.rows[idx].userEmail)
															}}
														/>
														<label
															className="custom-control-label"
															htmlFor={"customSwitch" + idx}
															
														>
														</label>
													</div>

												</Col>
												<Col
													lg={1}
													className="form-group align-self-center"
												>
													<i
														className="bx bx-check-circle font-size-24"
														style={{cursor: "pointer", color: "blue"}}
														onClick={() => this.addNewPortForward(idx)}
													/>
												</Col>
												<Col
													lg={1}
													className="form-group align-self-center"
												>
													<i 
														className="bx bx-trash-alt font-size-24" 
														style={{cursor: "pointer", color: "red"}} 
														onClick={(e) => this.deletePortForward(idx)}
													/>
												</Col>
											</Row>
										</div>
									</Form>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Button onClick={this.handleAddRow} color="success">
					Add{" "}
				</Button>{" "}
			</React.Fragment>
		)
	}
}

export default PortForwardGroup;