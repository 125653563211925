import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import {Row, Col} from "reactstrap";
import call_api from "../../components/api-caller";

class StackedColumnChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			months: [],
			ram: [],
			storage: [],
			cpu: [],
		}
	}

	async get_resource_usage() {
		var query = `query get_resource_usage{
			getResourceUsage(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}"){
				ram
				storage
				cpu
				month
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getResourceUsage){
			this.setState({
				months: data.data.getResourceUsage.month,
				ram: data.data.getResourceUsage.ram,
				storage: data.data.getResourceUsage.storage,
				cpu: data.data.getResourceUsage.cpu
			})
		}
	}

	componentDidMount() {
		this.get_resource_usage();
	}

	render() {
		return (
			<React.Fragment>
				<Row>
					<Col lg={4} md={6} sm={12}>
						<ReactApexChart 
							options={{
								dataLabels: {
									enabled: false
								},
								stroke: {
									curve: 'smooth',
									width: 3,
								},
								colors: ['#556ee6'],
								xaxis: {
									categories: this.state.months
								},
								grid: {
									borderColor: '#f1f1f1',
								},
							}} 
							series={[{
								name: "CPU",
								data: this.state.cpu
							}]}
							type="area" 
							height="350" 
						/>
					</Col>
					<Col lg={4} md={6} sm={12}>
						<ReactApexChart 
							options={{
								dataLabels: {
									enabled: false
								},
								stroke: {
									curve: 'smooth',
									width: 3,
								},
								colors: ['#f1b44c'],
								xaxis: {
									categories: this.state.months
								},
								grid: {
									borderColor: '#f1f1f1',
								},
							}} 
							series={[{
								name: "Memory",
								data: this.state.ram
							}]}
							type="area" 
							height="350" 
						/>
					</Col>
					<Col lg={4} md={6} sm={12}>
						<ReactApexChart 
							options={{
								dataLabels: {
									enabled: false
								},
								stroke: {
									curve: 'smooth',
									width: 3,
								},
								colors: ['#34c38f'],
								xaxis: {
									categories: this.state.months
								},
								grid: {
									borderColor: '#f1f1f1',
								},
							}} 
							series={[{
								name: "Storage",
								data: this.state.storage
							}]}
							type="area" 
							height="350" 
						/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export default StackedColumnChart;
