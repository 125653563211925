import React from 'react';
import JWT from 'expo-jwt';
import SweetAlert from 'react-bootstrap-sweetalert';
import call_api from '../../components/api-caller';

class RazorPayPaymentIndex extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			payment_success: false, 
			payment_failure: false,
			pmnt_msg: "",
		}
	}

	loadScript(src) {
		return new Promise((resolve) => {
			const script = document.createElement('script')
			script.src = src
			script.onload = () => {
				resolve(true)
			}
			script.onerror = () => {
				resolve(false)
			}
			document.body.appendChild(script)
		})
	}

	async submit_transaction_details(response, user_email, realm) {
		var query = `mutation payment{
			storePayment(email: "${user_email}", realm: "${realm}", orderId: "${response.razorpay_order_id}", paymentId: "${response.razorpay_payment_id}", signature: "${response.razorpay_signature}", domain: "${this.props.domain}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.storePayment) {
			if (data.data.storePayment.result === 1) {
				this.setState({
					payment_success: true,
					payment_failure: false,
					pmnt_msg: "Your payment is successful. Your wallet will be updated soon."
				});
			} else if (data.data.storePayment.result === -1) {
				this.setState({
					payment_success: false,
					payment_failure: true,
					pmnt_msg: "Your payment towards buying the product failed. If the money got deducted from your bank account, it gets refunded within a couple fo working days."
				});
			}
			if (data.data.storePayment.result === 0) {
				this.setState({
					payment_success: false,
					payment_failure: true,
					pmnt_msg: "Your payment towards buying the product failed. There is an error storing the payment details. If the money got deducted from the bank, it will either get refunded or the subscription gets active. Please contact support for further help"
				});
			}
		}
	}

	async displayRazorpay(razorpay_data) {
		const res = await this.loadScript('https://checkout.razorpay.com/v1/checkout.js');

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return;
		}

		const options = {
			key: razorpay_data.key, // key
			currency: "INR",
			amount: razorpay_data.amount, // amount
			order_id: razorpay_data.order_id, // order_id
			name: razorpay_data.name, // name
			description: razorpay_data.description, //description
			image: '',
			handler: (response) => {
				this.submit_transaction_details(response, razorpay_data.email, razorpay_data.realm);
			},
			prefill: {
				name: razorpay_data.name, // user-name
				email: razorpay_data.email, // user-email
				contact: razorpay_data.contact // user=-phone-no
			},
			theme: {
				"color": "#F37254"
			}
		}
		const paymentObject = new window.Razorpay(options);
		paymentObject.open();
	}

	loadJWTData() {
		const key = 'cloud-portal';
		const token = this.props.jwt_token;
		try {
			const data = JWT.decode(token, key);
			this.displayRazorpay(data);
		} catch (error) {
			console.log("Error", error);
		}
	}
  
	componentDidMount() {
		this.loadJWTData();
	}

	componentWillUnmount() {
	}
	
	render() {
		return (
			<React.Fragment>
				{(this.state.payment_success &&
					<SweetAlert success 
						confirmBtnText="Continue"
						confirmBtnBsStyle="success"
						title="Yay! Payment Success"
						onConfirm={() => this.setState({payment_success: false})}
					>
						{this.state.pmnt_msg}
					</SweetAlert>
				)}
				{(this.state.payment_failure &&
					<SweetAlert danger 
						confirmBtnText="Close"
						confirmBtnBsStyle="danger"
						title="Payment Failed"
						onConfirm={() => this.setState({payment_failure: false})}
					>
						{this.state.pmnt_msg}
					</SweetAlert>
				)}
			</React.Fragment>
		)
	}
}

export default RazorPayPaymentIndex;