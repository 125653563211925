import React, { Component } from 'react';
import { Row, Col, Button, CardTitle, Alert, Media, Card, CardHeader, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';

class AppTemplates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appTemplates: [],
			selectedTemplate: {},
			del_catl: false,
			selectedInd: 0,
		}
		this.createAppTemplates = this.createAppTemplates.bind(this);
		this.modifyAppTemplates = this.modifyAppTemplates.bind(this);
	}

	async createAppTemplates(event, values) {
		event.preventDefault();
		var query = `mutation {
			createModifyAppTemplate(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", 
			appName: "${values.app_name}", appShortCode: "${values.app_short_code}", appPath: ${JSON.stringify(values.app_path)}, appStartup: ${JSON.stringify(values.app_startup)}, appCmdline: ${JSON.stringify(values.app_cmdline)},
			maxResol: ${values.max_resol}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createModifyAppTemplate){
			if (data.data.createModifyAppTemplate.result) {
				showToast("Created App Template", "A new application template has been made successfully", "success");
				this.get_templates_avail();
			} else {
				showToast("Creating App Template Failed", "There has been an error creating the application template. Please try again after some time.", "error");
			}
		} else {
			showToast("Creating App Template Failed", "You may not have enough privileges to create an app template", "error");
		}
	}

	async modifyAppTemplates(event, values) {
		event.preventDefault();
		var query = `mutation {
			createModifyAppTemplate(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", 
			appName: "${values.mod_app_name}", appShortCode: "${values.mod_app_short_code}", appPath: ${JSON.stringify(values.mod_app_path)}, appStartup: ${JSON.stringify(values.mod_app_startup)}, appCmdline: ${JSON.stringify(values.mod_app_cmdline)}, 
			modify: true, appTempId: "${this.state.selectedTemplate.tmpId}", iconPath: "${values.mod_icon_path}", maxResol: ${values.max_resol}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createModifyAppTemplate){
			if (data.data.createModifyAppTemplate.result) {
				showToast("Modified App Template", "The selected application template has been modified successfully", "success");
				this.get_templates_avail();
			} else {
				showToast("Modifying App Template Failed", "There has been an error modifying the application template. Please try again after some time.", "error");
			}
		} else {
			showToast("Modifying App Template Failed", "You may not have enough privileges to modify an app template", "error");
		}
	}

	async removeAppTemplate() {
		this.setState({del_catl: false});
		var query = `mutation {
			removeCompanyAppTemplate(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", 
			appTempId: "${this.state.selectedTemplate.tmpId}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeCompanyAppTemplate){
			if (data.data.removeCompanyAppTemplate.result === 1) {
				showToast("Removing App Template", "All the vms with the selected app teplates will remove the appp ublishing relating to this app", "success");
				this.get_templates_avail();
			} else if (data.data.removeCompanyAppTemplate.result === -1){
				showToast("Removing App Template Failed", "There is no app template with the provided app template id.", "error");
			} else if (data.data.removeCompanyAppTemplate.result === 0){
				showToast("Removing App Template Failed", "There has been an error removing the application template. Please try again after some time.", "error");
			}
		} else {
			showToast("Removing App Template Failed", "You may not have enough privileges to modify an app template", "error");
		}
	}

	changeselectedTemplate(index) {
		if (index < this.state.appTemplates.length) 
			this.setState({
				selectedTemplate: this.state.appTemplates[index],
				selectedInd: index
			});
	}

	async get_templates_avail() {
		var query =`query {
			getCompAppTemplates(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				tmpId
				name
				appShortCode
				path
				cmdline
				startup
				iconPath
				maxResol
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompAppTemplates && data.data.getCompAppTemplates.length > 0){
			this.setState({
				appTemplates: data.data.getCompAppTemplates,
			}, () => {
				this.changeselectedTemplate(this.state.selectedInd);
			});
		}
	}

	componentDidMount() {
		this.get_templates_avail();
	}

	render() {
		return (<React.Fragment>
			<Card>
				<CardHeader>Create Application Template</CardHeader>
				<CardBody>
					<Row>
						<Col md={8} lg={8} >
						{ this.state.success_add ? 
							<Alert color="success">Application Template created Successfully.</Alert> 
						: null }
						{
							this.state.error_add ? 
								<Alert color="danger">Error Creating new Template. You may not have provileges to create one.</Alert>
							: null
						}
						</Col>
					</Row>
					<Row>
						<Col lg={6} md={8} sm={12}>
							<AvForm className="form-horizontal" onValidSubmit={this.createAppTemplates}>
								<AvField 
									className="mb-2 form-control"
									type="text"
									name="app_name"
									label="Application Name"
									helpMessage="Name of the application"
									errorMessage="Appliction name cannot be empty"
									required
								/>
								<AvField 
									className="mb-2 form-control"
									type="text"
									name="app_short_code"
									label="Application Short Code"
									helpMessage="Should be maximum of 5 characters"
									errorMessage="Appliction short code cannot be empty"
									maxLength="5"
									required
								/>
								<AvField 
									className="mb-2"
									type="text"
									name="app_path"
									label="Application Path"
									helpMessage="Execution path of the application"
									errorMessage="Execution path cannot be empty"
									required
								/>
								<AvField 
									className="mb-2"
									type="text"
									name="app_startup"
									label="Application Startup"
									helpMessage="Sartup path of the application"
								/>
								<AvField 
									className="mb-2"
									type="text"
									name="app_cmdline"
									label="Command Line"
									helpMessage="Command line parameters for the application"
								/>
								<AvField 
									className="mb-2"
									type="number"
									name="max_resol"
									label="Max Resolution"
									helpMessage="Percentage of resolution of the screen for app to be shown"
									required
									validate={{
										max: {
											value: 100
										},
										min: {
											value: 10
										},
									}}
								/>
								<Button className="btn btn-success">
									Create
								</Button>
							</AvForm>
						</Col>
					</Row>
				</CardBody>
			</Card>
			<Row>
				<Col lg="12" md="12">
					<Card>
						<CardHeader>App Templates</CardHeader>
						<CardBody>
							<Col lg="12">
								<div className="d-lg-flex">
									<Col lg="4">
										<div className="chat-leftsidebar mr-lg-4">
											<div className="chat-leftsidebar-nav">
												<ul className="list-unstyled chat-list">
													<PerfectScrollbar style={{ height: "410px" }}>
														{
															(this.state.appTemplates && this.state.appTemplates.length > 0) ? this.state.appTemplates.map((temp, idx) =>
																<li key={"tmplt_" + idx} className={this.state.selectedTemplate.tmpId === temp.tmpId ? "active" : ""}>
																	<Link to="#" onClick={() => {this.changeselectedTemplate(idx)}}>
																		<Media >
																			<Media className="overflow-hidden" body>
																				<h5 className="text-truncate font-size-14 mb-1">{temp.name}</h5>
																			</Media>
																		</Media>
																	</Link>
																</li>
															) : null
														}
													</PerfectScrollbar>
												</ul>
											</div>
										</div>
									</Col>
									<Col lg="8">
										<div className="w-100 user-chat">
											<Card>
												<div className="chat-conversation p-3">
													<ul className="list-unstyled">
														<Row>
															<Col lg="12" md="12">
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">Template Information</CardTitle>
																		<AvForm onValidSubmit={this.modifyAppTemplates}>
																			<AvField 
																				className="mb-2 form-control"
																				type="text"
																				name="mod_app_name"
																				label="Application Name"
																				helpMessage="Name of the application"
																				errorMessage="Appliction name cannot be empty"
																				required
																				value={this.state.selectedTemplate.name}
																			/>
																			<AvField
																				className="mb-2 form-control"
																				type="text"
																				name="mod_app_short_code"
																				label="Application Short Code"
																				helpMessage="Should be maximum of 5 characters"
																				errorMessage="Appliction short code cannot be empty"
																				required
																				maxLength="5"
																				value={this.state.selectedTemplate.appShortCode ? this.state.selectedTemplate.appShortCode : ""}
																			/>
																			<AvField 
																				className="mb-2"
																				type="text"
																				name="mod_app_path"
																				label="Application Path"
																				helpMessage="Execution path of the application"
																				errorMessage="Execution path cannot be empty"
																				required
																				value={this.state.selectedTemplate.path}
																			/>
																			<AvField 
																				className="mb-2"
																				type="text"
																				name="mod_app_startup"
																				label="Application Startup"
																				helpMessage="Sartup path of the application"
																				value={this.state.selectedTemplate.startup}
																			/>
																			<AvField 
																				className="mb-2"
																				type="text"
																				name="mod_app_cmdline"
																				label="Command Line"
																				helpMessage="Command line parameters for the application"
																				value={this.state.selectedTemplate.cmdline}
																			/>
																			<AvField 
																				className="mb-2"
																				type="text"
																				name="mod_icon_path"
																				label="Icon Path"
																				helpMessage="Iocn File Path"
																				value={this.state.selectedTemplate.iconPath}
																			/>
																			<AvField 
																				className="mb-2"
																				type="number"
																				name="max_resol"
																				label="Max Resolution"
																				helpMessage="Percentage of resolution of the screen for app to be shown"
																				required
																				value={this.state.selectedTemplate.maxResol}
																				validate={{
																					max: {
																						value: 100
																					},
																					min: {
																						value: 10
																					},
																				}}
																			/>
																			<Button className="btn btn-warning btn-block">
																				Modify
																			</Button>
																		</AvForm>
																		<Button className="btn btn-danger btn-block mt-4" onClick={() => this.setState({del_catl: true})}>
																			Remove
																		</Button>
																	</CardBody>
																</Card>
															</Col>
														</Row>
													</ul>
												</div>
											</Card>
										</div>
									</Col>
								</div>
							</Col>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Modal isOpen={this.state.del_catl} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({del_catl:false})}}>
				<ModalHeader toggle={() => {this.setState({del_catl:false})}}>
					Delete Service Template
				</ModalHeader >
				<ModalBody>
					Are you sure of deleting the Template <b>{this.state.selectedTemplate.name}</b>. This action is irreversible and all the VMs' made with this Template will be charged on resource based now. Please kindly follow with deleting. 
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger btn-block" onClick={() => {this.removeAppTemplate()}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>)
	}
}

export default AppTemplates;
