async function call_api(query){
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({"query": query})
	};
	const resp = await fetch('/api/', requestOptions);
	const data = await resp.json();
	return data;
}

export default call_api;