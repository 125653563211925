import React, { Component } from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, } from "reactstrap";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import { AvForm, AvField } from 'availity-reactstrap-validation';

class PaymentGatewayDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			razor_pay: {},
		}
		this.change_razorpay_detail = this.change_razorpay_detail.bind(this);
	}

	async get_payment_gateway_details() {
		var query =`query get_payment_gateway_details{
			getPaymentGatewayDetails(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}"){
				razorpayDetails {
					apiKey
					apiSecret
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getPaymentGatewayDetails){
			this.setState({
				razor_pay: data.data.getPaymentGatewayDetails.razorpayDetails,
			});
		}
	}

	async change_razorpay_detail(event, values) {
		event.preventDefault();
		var query = `mutation change_razorpay_details{
			changeRazorpayDetails(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", apiKey: "${values.api_key}", apiSecret: "${values.api_secret}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeRazorpayDetails){
			if (data.data.changeRazorpayDetails.result) {
				showToast("Changed Razorpay Details", "Razorpay payment gateway API keys have been modified successfully", "success");
				this.get_payment_gateway_details();
			} else {
				showToast("Changing Razorpay Details Failed", "There is an error changing the razorpay payment gateway details. Please try again after some time.", "error");
			}
		} else {
			showToast("Changing Razorpay Details Failed", "You may not have enough privileges to change the payment gateway details", "error");
		}				
	}

	componentDidMount() {
		this.get_payment_gateway_details();
	}

	render() {
		return (<React.Fragment>
			<Card>
				<CardHeader>Razorpay Payment Gateway Details</CardHeader>
				<CardBody>
					<Row>
						<Col lg="8">
							<AvForm onValidSubmit={this.change_razorpay_detail}>
								<AvField 
									type="text"
									name="api_key"
									label="APP Key"
									placeholder="APP key for your razorpay account"
									required
									value={this.state.razor_pay.apiKey}
								/>
								<AvField 
									type="text"
									name="api_secret"
									label="APP Secret"
									placeholder="APP Secret for your razorpay account"
									required
									value={this.state.razor_pay.apiSecret}
								/>
								<Button className="btn btn-success btn-block">
									Submit
								</Button>
							</AvForm>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</React.Fragment>)
	}
}

export default PaymentGatewayDetails;