import React, { Component } from 'react';
import { Row, Col, Button, CardTitle, Table, Alert, Media, Card, CardHeader, CardBody, Form, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";

class Catalogs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			catalogs: [],
			selectedCatalog: {},
			availIsos: [],
			disks: [
				{
					diskType: true,
					storage: 0,
				}
			],
			catalogName: "",
			catalogDesc: "",
			catalogOs: "",
			catalogCost: 0,
			catalogNetwork: 0,
			catalogCpu: 0,
			catalogMemory: 0,
			del_catl: false,
		}
		this.createCatalog = this.createCatalog.bind(this);
	}

	async deleteCatalog() {
		const catl_name = this.state.selectedCatalog.name;
		var query = `mutation delete_catalog{
			deleteCatalog(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", catalogId: ${this.state.selectedCatalog.catalogId}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.deleteCatalog){
			if (data.data.deleteCatalog){
				showToast("Deleted Catalog", "The catalog " + catl_name + " has been deleted successfully", "success");
				this.get_catalogs_avail();
			} else {
				showToast("Delete Catalog Failed", "There is an error deleting catalog " + catl_name + ". Please try again after some time.", "error");
			}
		} else {
			showToast("Delete Catalog Failed", "You may not have enough privileges deleteing a catalog.", "error");
		}
	}

	async get_isos() {
		var query =`query get_iso{
			getIsosAvail(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				isoName
				osId
				cost
				hourlyCost
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getIsosAvail && data.data.getIsosAvail.length > 0){
			this.setState({
				availIsos: data.data.getIsosAvail,
				catalogOs: data.data.getIsosAvail[0].osId
			});
		}
	}

	async createCatalog(event) {
		event.preventDefault();
		if (this.state.disks.length > 0){
			var query = `mutation add_catalog{
				createServiceCatalog(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", catalog: {
					name:"${this.state.catalogName}",
					cost:${this.state.catalogCost},
					description:"${this.state.catalogDesc}",
					config: {
						isoId: "${this.state.catalogOs}",
						cpuCores: ${this.state.catalogCpu},
						memory:  ${this.state.catalogMemory},
						networkCards: ${this.state.catalogNetwork},
						disks: ${JSON.stringify(this.state.disks).replace(/\"/g, "")}
					}
				}) {
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.createServiceCatalog){
				if (data.data.createServiceCatalog.result) {
					showToast("Created Catalog", "Service Catalog with the requested configuration has been made", "success");
					this.get_catalogs_avail();
				} else {
					showToast("Created Catalog Failed", "There has been an error creating the catalog. Please try again after some time.", "error");
				}
			} else {
				showToast("Created Catalog Failed", "You may not have enough privileges to create a service catalog", "error");
			}				
		} else {
			showToast("No Disks attached", "Please add disks in order to create a catalog", "warning");
		}
	}

	handleAddRowNested = () => {
		const item1 = {
		name1: ""
		};
		this.setState({
		disks: [...this.state.disks, item1]
		});
	};

	change_iso(value) {
		this.setState({
			catalogOs: value,
		});
	}

	handleRemoveRowNested = (e, idx) => {
		this.setState(prevState => ({
			disks: prevState.disks.filter((el, id) => id !== idx)
		}));
		document.getElementById("nested" + idx).style.display = "none";
	};

	change_disk_type(idx) {
		let itm = this.state.disks;
		itm[idx].diskType = !itm[idx].diskType;
		this.setState({
			disks: itm
		});
	}

	change_disk_storage(idx, value) {
		let itm = this.state.disks;
		itm[idx].storage = parseInt(value);
		this.setState({
			disks: itm
		});
	}

	changeselectedCatalog(index) {
		if (index < this.state.catalogs.length) 
			this.setState({
				selectedCatalog: this.state.catalogs[index]
			});
	}

	async get_catalogs_avail() {
		var query =`query get_catalogs{
			getCompanyCatalogs(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				catalogId
				name
				cost
				description
				config {
					iso
					cpu
					memory
					noOfNetworkCards
					disks {
						diskType
						diskStorage
					}
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyCatalogs && data.data.getCompanyCatalogs.length > 0){
			this.setState({
				catalogs: data.data.getCompanyCatalogs,
			}, () => {
				this.changeselectedCatalog(0);
			});
		}
	}

	componentDidMount() {
		this.get_isos();
		this.get_catalogs_avail();
	}

	render() {
		return (<React.Fragment>
			<Card>
				<CardHeader>Create Service Catalog</CardHeader>
				<CardBody>
					{
						this.state.error_add ? (
							<Row>
								<Col md={8} lg={8} >
									<Alert color="danger">Error Creating new Groups. You may not have provileges to create group.</Alert>
								</Col>
							</Row>	
						) : null
					}
					{
						this.state.success_add ? (
							<Row>
								<Col md={8} lg={8} >
									<Alert color="success">Group created Successfully.</Alert>
								</Col>
							</Row>
						) : null
					}
					<Row>
					<Col lg={12}>
						<Form className="outer-repeater" onSubmit={this.createCatalog}>
						<div data-repeater-list="outer-group" className="outer">
							<div data-repeater-item className="outer">
							<div className="form-group">
								<Label htmlFor="formname">Name : </Label>
								<Input
								type="text"
								id="formname"
								placeholder="Name for the Catalog"
								className="form-control"
								required
								value={this.state.catalogName}
								onChange = {(event) => {
									this.setState({
										catalogName: event.target.value
									})
								}}
								/>
							</div>
							<div className="form-group">
								<Label htmlFor="formname">Description : </Label>
								<Input
								type="text"
								id="formndesc"
								placeholder="Description for the Catalog"
								className="form-control"
								required
								value={this.state.catalogDesc}
								onChange = {(event) => {
									this.setState({
										catalogDesc: event.target.value
									})
								}}
								/>
							</div>
							<div className="form-group">
								<Label htmlFor="os">Operating System :</Label>
								<select required className="form-control" onChange={(event) => this.change_iso(event.target.value) }>
									{
										this.state.availIsos.map((iso, indx) => 
											(
											<option key={"iso_"+indx} value={iso.osId}>{iso.isoName}</option>
											)
										)
									}
								</select>
							</div>

							<div className="form-group">
								<Label htmlFor="formemail">CPU Cores :</Label>
								<Input
									type="number"
									id="no_of_network_cards"
									placeholder="Number of CPU cores"
									className="form-control"
									required
									value={this.state.catalogCpu}
									onChange = {(event) => {
										this.setState({
											catalogCpu: parseInt(event.target.value)
										})
									}}
								/>
							</div>

							<div className="form-group">
								<Label htmlFor="formemail">Memory (MB):</Label>
								<Input
									type="number"
									id="no_of_network_cards"
									placeholder="Memory in GB"
									className="form-control"
									required
									value={this.state.catalogMemory}
									onChange = {(event) => {
										this.setState({
											catalogMemory: parseInt(event.target.value)
										})
									}}
								/>
							</div>

							<div className="form-group">
								<Label htmlFor="formemail">Network Cards :</Label>
								<Input
									type="number"
									id="no_of_network_cards"
									placeholder="Number of network Cards"
									className="form-control"
									required
									value={this.state.catalogNetwork}
									onChange = {(event) => {
										this.setState({
											catalogNetwork: parseInt(event.target.value)
										})
									}}
								/>
							</div>

							<div className="inner-repeater mb-4">
								<Label>Disks:</Label>
								<table style={{ width: "100%" }}>
								<tbody>
									{this.state.disks.map((item1, idx) => (
									<tr id={"nested" + idx} key={idx}>
										<td>
										<Row className="mb-2">
											<Col md="4">
												<Input
												type="number"
												className="inner form-control"
												placeholder={
												"Enter Storage " +
												(idx + 1)
												}
												required
												onChange={(event) => {this.change_disk_storage(idx, event.target.value)} }
											/>
											</Col>
											<Col md="1">
												<div className="square-switch">
													<input
														type="checkbox"
														id={"square-switch"+idx}
														switch="info"
														checked={this.state.disks[idx].diskType}
														onChange={() => {this.change_disk_type(idx)}}
														value={this.state.disks[idx].storage}
													/>
													<label
														htmlFor={"square-switch"+idx}
														data-on-label="SSD"
														data-off-label="HDD"
													></label>
												</div>
											</Col>
											<Col md="2">
											<Button
												onClick={e =>
												this.handleRemoveRowNested(e, idx)
												}
												color="primary"
												className="btn-block inner"
												style={{ width: "100%" }}
											> Delete
											</Button>
											</Col>
										</Row>
										</td>
									</tr>
									))}
								</tbody>
								</table>
								<Button
									onClick={this.handleAddRowNested}
									color="success"
									className="mt-1"
								>
								Add Disk
							</Button>
							</div>
							<div className="form-group">
								<Label htmlFor="formemail">Cost :</Label>
								<Input
								type="number"
								id="cost"
								placeholder="Cost for the catalog"
								className="form-control"
								required
								value={this.state.catalogCost}
								onChange = {(event) => {
									this.setState({
										catalogCost: parseFloat(event.target.value)
									})
								}}
								/>
							</div>

							<Button type="submit" color="primary">
								Submit
							</Button>
							</div>
						</div>
						</Form>
					</Col>
					</Row>
				</CardBody>
			</Card>
			<Row>
				<Col lg="12" md="12">
					<Card>
						<CardHeader>Catalogs</CardHeader>
						<CardBody>
							<Col lg="12">
								<div className="d-lg-flex">
									<Col lg="4">
										<div className="chat-leftsidebar mr-lg-4">
											<div className="chat-leftsidebar-nav">
												<ul className="list-unstyled chat-list">
													<PerfectScrollbar style={{ height: "410px" }}>
														{
															(this.state.catalogs && this.state.catalogs.length > 0) ? this.state.catalogs.map((cat, idx) =>
																<li key={"Group_" + idx} className={this.state.selectedCatalog.catId === cat.catId ? "active" : ""}>
																	<Link to="#" onClick={() => {this.changeselectedCatalog(idx)}}>
																		<Media >
																			<Media className="overflow-hidden" body>
																				<h5 className="text-truncate font-size-14 mb-1">{cat.name}</h5>
																			</Media>
																		</Media>
																	</Link>
																</li>
															) : null
														}
													</PerfectScrollbar>
												</ul>
											</div>
										</div>
									</Col>
									<Col lg="8">
										<div className="w-100 user-chat">
											<Card>
												<div className="chat-conversation p-3">
													<ul className="list-unstyled">
														<Row>
															<Col lg="12" md="12">
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">Catalog Information</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">Catalog Name :</th>
																						<td>{this.state.selectedCatalog.name}</td>
																					</tr>
																					<tr>
																						<th scope="row">Description :</th>
																						<td>{this.state.selectedCatalog.description}</td>
																					</tr>
																					<tr>
																						<th scope="row">Catalog OS :</th>
																						<td>{this.state.selectedCatalog.config && this.state.selectedCatalog.config.iso}</td>
																					</tr>
																					<tr>
																						<th scope="row">CPU Cores :</th>
																						<td>{this.state.selectedCatalog.config && this.state.selectedCatalog.config.cpu}</td>
																					</tr>
																					<tr>
																						<th scope="row">Memory RAM (GB) :</th>
																						<td>{this.state.selectedCatalog.config && this.state.selectedCatalog.config.memory}</td>
																					</tr>
																					<tr>
																						<th scope="row">Number of Network Cards :</th>
																						<td>{this.state.selectedCatalog.config && this.state.selectedCatalog.config.noOfNetworkCards}</td>
																					</tr>
																					<tr>
																						<th scope="row">Disks :</th>
																						<td>{this.state.selectedCatalog.config && this.state.selectedCatalog.config.disks && 
																							<Table>
																								<tbody>
																									<tr>
																										<th>Disk Type</th>
																										<th>Disk Storage (GB)</th>
																									</tr>
																								{this.state.selectedCatalog.config.disks.map((elem) => {
																									return (
																										<tr>
																											<td>{elem.diskType ? "SSD" : "HDD"}</td>
																											<td>{elem.diskStorage}</td>
																										</tr>
																									)
																								})}

																								</tbody>
																							</Table>
																						}</td>
																					</tr>
																					<tr>
																						<th scope="row">Cost :</th>
																						<td>{this.props.currencyFormat.format(this.state.selectedCatalog.cost)}</td>
																					</tr>
																					<tr>
																						<th scope="row">Delete :</th>
																						<td><Button className="btn btn-danger" onClick={() => {this.setState({del_catl: true})}}>Delete Catalog</Button></td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
															</Col>
														</Row>
													</ul>
												</div>
											</Card>
										</div>
									</Col>
								</div>
							</Col>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Modal isOpen={this.state.del_catl} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({del_catl:false})}}>
				<ModalHeader toggle={() => {this.setState({del_catl:false})}}>
					Delete Service Catalog
				</ModalHeader >
				<ModalBody>
					Are you sure of deleting the catalog <b>{this.state.selectedCatalog.name}</b>. This action is irreversible and all the VMs' made with this catalog will be charged on resource based now. Please kindly follow with deleting. 
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger btn-block" onClick={() => {this.deleteCatalog()}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>)
	}
}

export default Catalogs;
