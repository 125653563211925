import React, { Component } from 'react';
import { Row, Col, Button, CardTitle, Alert, Table, Media, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

class SharedAppsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sharedApps: [],
			selectedApp: {},
			selectedInd: 0,
			selectedAppUsrs: [],
			chooseAppUsers: [],
			mod_app_usr: false,
			compUsers: [],
			no_sessions: 1,
		}
	}

	async get_all_users() {
		var query =`query get_all_users{
			getAllUsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				email
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getAllUsers && data.data.getAllUsers.length >= 0){
			this.setState({
				compUsers: data.data.getAllUsers,
			});
		}
	}

	async getAppUsers() {
		var query = `query {
			getSharedAppUsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", 
			appId: "${this.state.selectedApp.appId}" ) {
				email
				name
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getSharedAppUsers && data.data.getSharedAppUsers.length >= 0){
			this.setState({
				selectedAppUsrs: data.data.getSharedAppUsers,
				chooseAppUsers: data.data.getSharedAppUsers.map((elem) => elem.email),
			});
		}
	}

	async modifyUsersSharedApp() {
		this.setState({
			mod_app_usr: false,
		});
		if (this.state.no_sessions > 0 && this.state.no_sessions <= 5) {
			var query = `mutation {
				changeUserSharedApps(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", appId: "${this.state.selectedApp.appId}", users: [${this.state.chooseAppUsers.map((elem)=> `{email: "${elem}", count: ${this.state.no_sessions}}`)}]) {
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.changeUserSharedApps){
				if (data.data.changeUserSharedApps.result) {
					showToast("Modified Users for Application", "The selected application has been modified successfully", "success");
					this.getAppUsers();
				} else {
					showToast("Modifying Users for application failed", "There has been an error modifying the application. Please try again after some time.", "error");
				}
			} else {
				showToast("Modifying Users for application failed", "You may not have enough privileges to modify an app template", "error");
			}
		} else {
			showToast("Modifying Users for application failed", "Invalid number of sessions provided", "error");
		}
	}


	changeselectedApp(index) {
		if (index < this.state.sharedApps.length) 
			this.setState({
				selectedApp: this.state.sharedApps[index],
				selectedInd: index
			}, () => {
				this.getAppUsers();
			});
	}

	async get_templates_avail() {
		var query =`query {
			getCompSharedApps(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				appId
				machineId
				applicationId
				applicationName
				desktopName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompSharedApps && data.data.getCompSharedApps.length > 0){
			this.setState({
				sharedApps: data.data.getCompSharedApps,
			}, () => {
				this.changeselectedApp(this.state.selectedInd);
			});
		}
	}

	componentDidMount() {
		this.get_templates_avail();
		this.get_all_users();
	}

	render() {
		return (<React.Fragment>
			<Card>
				<CardHeader>Shared Applications</CardHeader>
				<CardBody>
					<div className="d-lg-flex">
						<Col lg="4">
							<div className="chat-leftsidebar mr-lg-4">
								<div className="chat-leftsidebar-nav">
									<ul className="list-unstyled chat-list">
										<PerfectScrollbar style={{ height: "410px" }}>
											{
												(this.state.sharedApps && this.state.sharedApps.length > 0) ? this.state.sharedApps.map((temp, idx) =>
													<li key={"tmplt_" + idx} className={this.state.selectedApp.appId === temp.appId ? "active" : ""}>
														<Link to="#" onClick={() => {this.changeselectedApp(idx)}}>
															<Media >
																<Media className="overflow-hidden" body>
																	<h5 className="text-truncate font-size-14 mb-1">{temp.applicationName}</h5>
																	<p>{temp.machineId}</p>
																</Media>
															</Media>
														</Link>
													</li>
												) : null
											}
										</PerfectScrollbar>
									</ul>
								</div>
							</div>
						</Col>
						<Col lg="8">
							<Card>
								<CardHeader>Application Details</CardHeader>
								<CardBody>
									<Table>
										<tbody>
											<tr>
												<th>Application Name</th>
												<td>:</td>
												<td>{this.state.selectedApp.applicationName}</td>
											</tr>
											<tr>
												<th>Application ID</th>
												<td>:</td>
												<td>{this.state.selectedApp.applicationId}</td>
											</tr>
											<tr>
												<th>Machine Name</th>
												<td>:</td>
												<td>{this.state.selectedApp.desktopName}</td>
											</tr>
											<tr>
												<th>Machine ID</th>
												<td>:</td>
												<td>{this.state.selectedApp.machineId}</td>
											</tr>
										</tbody>
									</Table>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									Assigned Users
									<Button className="btn btn-warning btn-sm float-right" onClick={() => {this.setState({mod_app_usr: true})}}>
										Modify
									</Button>
								</CardHeader>
								<CardBody>
									<Table>
										<tr>
											<th>Name</th>
											<th>Email</th>
										</tr>
										{
											this.state.selectedAppUsrs.map((usr) => 
												<tr>
													<td>{usr.name}</td>
													<td>{usr.email}</td>
												</tr>
											)
										}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</div>
				</CardBody>
			</Card>
			<Modal isOpen={this.state.mod_app_usr} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({mod_app_usr:false})}}>
				<ModalHeader toggle={() => {this.setState({mod_app_usr:false})}}>
					Modify App Users
				</ModalHeader >
				<ModalBody>
					You are about to modify users for shared app <b>{this.state.selectedApp.applicationName}</b>. <br />
					<CheckboxTree
						nodes={this.state.compUsers.map((usr) => {
							return {
								value: usr.email,
								label: usr.name
							}
						})}
						iconsClass="fa5"
						checked={this.state.chooseAppUsers}
						onCheck={checked => this.setState({chooseAppUsers: checked})}
					/>
					<form className="mt-4 form">
						<div class="form-group mb-0">
							<label for="noSessions">Number of Max Sessions</label>
							<input id="noSessions" type="number" className="form-control" 
								value={this.state.no_sessions} 
								onChange={(event) => this.setState({no_sessions: Number(event.target.value)})} 
							/>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger btn-block" onClick={() => this.modifyUsersSharedApp()}>
						Modify
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>)
	}
}

export default SharedAppsPage;
