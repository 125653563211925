import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert,Container } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser,apiError } from '../../store/actions';

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import call_api from "../../components/api-caller";

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			error_type: null,
		}
		this.do_register = this.do_register.bind(this);
	}

	async do_register(event, values) {
		var query = `mutation register{
			registerUser(user: {email: "${values.email}", realm: "${this.props.realm}", domain: "${this.props.realm}", password: "${values.password}", name: "${values.name}", phoneNo: "${values.phone_no}"}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.registerUser && data.data.registerUser.result){
			if (data.data.registerUser.result === 1){
				this.setState({
					error: `Registration successful. You can now login from ${this.props.realm}.`,
					error_type: "success"
				})
			} else if (data.data.registerUser.result === -1) {
				this.setState({
					error: "User already exists with the same email. Please try with another email.",
					error_type: "danger"
				})
			}
		} else {
			this.setState({
				error: `There is a problem at our end. Issue has been reported. Sorry for the inconvinience caused`,
				error_type: "danger"
			})
		}
	}

	render() {

		return (
			<React.Fragment>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-soft-primary">
										<Row>
											<Col className="col-7">
												<div className="text-primary p-4">
													<h5 className="text-primary">Welcome Back !</h5>
													<p>Register in DeepCloud to use unlimited cloud access</p>
												</div>
											</Col>
											<Col className="col-5 align-self-end">
												<img src={profile} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<div>
											<Link to="/">
												<div className="avatar-md profile-user-wid mb-4">
													<span className="avatar-title rounded-circle bg-light">
														<img src={logo} alt="" height="34" />
													</span>
												</div>
											</Link>
										</div>
										<div className="p-2">

											<AvForm className="form-horizontal" onValidSubmit={this.do_register}>

												{this.state.error ? <Alert color={this.state.error_type}>{this.state.error}</Alert> : null}

												<div className="form-group">
													<AvField name="email" label="Email" className="form-control" placeholder="Your Email Address" type="text" required />
												</div>

												<div className="form-group">
													<AvField name="name" label="Name" type="text" required placeholder="Enter your Name" />
												</div>

												<div className="form-group">
													<AvField name="phone_no" label="Phone Number" type="number" required placeholder="Enter your Contact Number" />
												</div>

												<div className="form-group">
													<AvField name="password" label="Password" type="password" required placeholder="Enter new Password" />
												</div>

												<div className="mt-3">
													<button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Register</button>
												</div>
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Already have an account ?{" "}
										<Link
											to="/login"
											className="font-weight-medium text-primary"
										>
											{" "}
											Login
										</Link>
									</p>
									<p>Crafted with <i className="mdi mdi-heart text-danger"></i> by <p>© {new Date().getFullYear()} DeepCloud</p></p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = state => {
	const { error } = state.Login;
	return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Register));

