import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import avatar2 from "../../assets/images/users/user.svg";
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { Row, Col, Media, Card, CardBody, CardTitle, CardSubtitle, Table, Button, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from "reactstrap";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Moment from 'react-moment';
import Editable from "react-bootstrap-editable";
import { AvForm, AvField, } from "availity-reactstrap-validation";


class Users extends Component {

	constructor (props){
		super(props);
		this.state = {
			allUsers: [],
			searchUsers: [],
			searchUserText: "",
			selectedUser: {},
			usr_roles: [],
			roles: [],
			usersvms: [],
			is_modify: false,
			add_user_balance: false,
			userMfAuthConfig: {},
			add_new_user: false,
			wallet_info: {},
			resources_cost_monthly: {
				cpu: 0,
				ssd: 0,
				ram: 0,
				hdd: 0
			},
			resources_cost_hourly: {
				cpu: 0,
				ssd: 0,
				ram: 0,
				hdd: 0
			},
			selectedUserEmail: 0,
			addUserBtnDisabled: false,
			deleteUser: false,
			userConfig: {},
			userAppDevices: [],
			userAppBrowsers: [],
			login_user: false
		};
		this.change_user_costs = this.change_user_costs.bind(this);
		this.change_user_name = this.change_user_name.bind(this);
		this.add_wallet_balance = this.add_wallet_balance.bind(this);
		this.add_company_user = this.add_company_user.bind(this);
		this.change_conf_user = this.change_conf_user.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps != this.props){
			console.log(this.props.browserData, this.props.uniqueVisitorId);
		}
	}

	componentDidMount(){
		this.get_all_users();
		this.get_roles();
	}

	async change_user_name(xhr, validValue, id) {
		var query = `mutation {
			modifyUserName(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", 
			userEmail: "${this.state.selectedUser.email}", userName: "${validValue}"){
				result
			}
		}`;
		xhr.open("POST", "/api/", true);
		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				const data = JSON.parse(xhr.responseText);
				if (data && data.data && data.data.modifyUserName)
					if (data.data.modifyUserName.result){
						showToast("Username Updated", "The username has been successfully updated for the user", "success");
					}
					else
						showToast("Username Update Failed", "There is an error proceesing the request. Please try again after sometime", "error");
				else
					showToast("Username Update Failed", "You may not have enough privileges to modify the user name", "error");
			}
		}
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({ "query": query }));
	}

	async change_user_costs(xhr, validValue, id){
		var query = `mutation change_resource_cost{
			updateResourceCostsUser(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", resourceCosts: {
				monthlyCost: {
					cpu: ${this.state.resources_cost_monthly.cpu},
					memory: ${this.state.resources_cost_monthly.ram},
					ssd: ${this.state.resources_cost_monthly.ssd},
					hdd: ${this.state.resources_cost_monthly.hdd},
				},
				hourlyCost: {
					cpu: ${this.state.resources_cost_hourly.cpu},
					memory: ${this.state.resources_cost_hourly.ram},
					ssd: ${this.state.resources_cost_hourly.ssd},
					hdd: ${this.state.resources_cost_hourly.hdd},
				},
			}){
				result
			}
		}`;
		xhr.open("POST", "/api/", true);
		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				const data = JSON.parse(xhr.responseText);
				if (data && data.data && data.data.updateResourceCostsUser)
					if (data.data.updateResourceCostsUser.result){
						showToast("Costs Updated", "The costs have been successfully updated for the user", "success");
					}
					else
						showToast("Costs Update Failed", "There is an error proceesing the request. Please try again after sometime", "error");
				else
					showToast("Costs Update Failed", "You may not have enough privileges to modify the user costs", "error");
			}
		}
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({ "query": query }));
	}

	async get_user_costs() {
		var query = `query {
			getUserCosts(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}") {
				cpu
				ram
				hdd
				ssd
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserCosts && data.data.getUserCosts.length === 2){
			this.setState({
				resources_cost_monthly: data.data.getUserCosts[0],
				resources_cost_hourly: data.data.getUserCosts[1]
			});
		}
	}

	async change_user_mf_auth_config(admin_enforce, sms, mail, whatsapp) {
		var query = `mutation {
			changeUserMfAuthConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}",
				adminEnforce: ${admin_enforce}, sms: ${sms}, mail: ${mail}, whatsapp: ${whatsapp}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserMfAuthConfig){
			if (data.data.changeUserMfAuthConfig.result) {
				showToast("MFA Config Modfied", "Multi factor authentication configuration changed successfully for the user", "success");
				this.get_user_mf_auth_config();
			} else {
				showToast("MFA Config Modfication failed", "There is an error changing multi factor authentication configuration for the user. Please try again after some time", "error");
			}
		} else {
			showToast("MFA Config Modfication failed", "You may not have enough privileges to change multi factor authentication configuration for the user.", "error");
		}
	}
	
	async get_user_mf_auth_config() {
		var query = `query {
			getUserMultiFactorAuthConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}") {
				adminEnforce
				sms
				mail
				whatsapp
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserMultiFactorAuthConfig){
			this.setState({
				userMfAuthConfig: data.data.getUserMultiFactorAuthConfig
			});
		}
	}

	async getUserWalletDetails() {
		var query = `query get_wallet_details{
			getUserWalletDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}") {
				prepaid
				amount
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserWalletDetails){
			this.setState({
				wallet_info: data.data.getUserWalletDetails
			});
		}
	}

	async getUserConfig() {
		var query = `query{
			getUserConfigCompAdmin(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}") {
				vdiHidden
				exportFolder
				exportEnabled
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserConfigCompAdmin){
			this.setState({
				userConfig: data.data.getUserConfigCompAdmin
			});
		}
	}

	async remove_user(email) {
		var query = `mutation remove_user{
			removeUser(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${email}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeUser && data.data.removeUser.result){
			showToast("Removed User", "User have been removed Successfully from the company", "success");
			this.get_all_users();
		} else {
			showToast("Failed to remove User", "User haven't been removed from the company", "error");
		}
	}

	async add_wallet_balance(event, values) {
		var query = `mutation add_wallet_balance_user{
			addWalletBalanceUser(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", amount: ${values.amount}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.addWalletBalanceUser){
			if (data.data.addWalletBalanceUser.result){
				this.setState({
					add_user_balance: false
				});
				showToast("Wallet Balance Updated", `The amount ${this.props.currencyFormat.format(values.amount)} has been added to the user's wallet successfully`, "success");
				this.getUserWalletDetails();
			} else {
				showToast("Failed Updating Wallet Balance", `There is an error updating user's account balance. Please try again after some time`, "error");
			}
		} else {
			showToast("Failed Updating Wallet Balance", `You may not have enough privileges to modify user account balance`, "error");
		}
	}

	async get_user_app_devices() {
		const query = `query {
			getUserAppDevices(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}") {
				diskId, osId, procId, allowed, remember, wanIp, arch, machineName, 
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserAppDevices && data.data.getUserAppDevices.length >= 0){
			this.setState({
				userAppDevices: data.data.getUserAppDevices
			});
		}
	}

	async get_user_app_browsers() {
		const query = `query {
			getUserAppBrowsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}") {
				browserFingerPrint, allowed, browserName, browserVersion, isMobile, isBrowser
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserAppBrowsers && data.data.getUserAppBrowsers.length >= 0){
			this.setState({
				userAppBrowsers: data.data.getUserAppBrowsers
			});
		}
	}

	async get_user_vms_costs() {
		var query = `query company_user_vms{
			companyUserVms(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}"){
				name
				createDate
				lastBillDate
				billEndDate
				costpm
				cost
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.companyUserVms && data.data.companyUserVms.length >= 0){
			this.setState({
				usersvms: data.data.companyUserVms
			});
		}
	}

	async get_roles(){
		var query = `query get_roles{
			getRoles(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				roleName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getRoles && data.data.getRoles.length >= 0){
			this.setState({
				roles: data.data.getRoles.map((elem) => {return elem.roleName})
			});
		}
	}

	async modifyUserRoles() {
		var query =`mutation {
			modifyUserRoleCompany(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", roles: ${JSON.stringify(this.state.usr_roles)}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyUserRoleCompany)
			if (data.data.modifyUserRoleCompany.result){
				showToast("Modified Roles Successfully", "Roles for the selected User have been modified successfully", "success");
				this.setState({
					is_modify: false
				});
				this.getUserDetails(this.state.selectedUserEmail);
			} else 
				showToast("Modify Roles Failed", "Error modifying user roles. Please try again after some time", "error");
		else
			showToast("Modify Roles Failed", "You may not have enough privileges to modify roles", "error");
	}

	async remove_user_company() {
		this.setState({
			deleteUser: false,
		});
		var query = `mutation {
			removeUserCompany(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeUserCompany){
			if (data.data.removeUserCompany.result === 1){
				showToast("Removed User Successfully", `The user account has been successfully removed from the company`, "success");
				this.get_all_users();
			} else if (data.data.removeUserCompany.result === -1)
				showToast("Failed removing user account", "No Company admins found to remove the user and assign the preferences.", "error");
			else if (data.data.removeUserCompany.result === -2)
				showToast("Failed removing user account", "You cannot remove a company admin. Please change the permissions of the user first before removing the user.", "error");
			else
				showToast("Failed removing user account", "Error removing the user account. Please try again after some time", "error");
		} else {
			showToast("Failed removing user account", "You may not have enough privileges to modify user account", "error");
		}
	}

	async change_conf_user(xhr, validValue, id) {
		const query = `mutation {
			changeUserConfig(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", vdiHidden: ${this.state.userConfig.vdiHidden}, exportFolder: ${JSON.stringify(validValue)}, exportEnabled: ${this.state.userConfig.exportEnabled}){
				result
			}
		}`;
		xhr.open("POST", "/api/", true);
		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				const data = JSON.parse(xhr.responseText);
				if (data && data.data && data.data.changeUserConfig)
					if (data.data.changeUserConfig.result){
						showToast("Changed User Configuration", `The user configuration has been successfully changed`, "success");
						this.getUserConfig();
					}
					else
						showToast("Failed changing configuration", "Error changing the user configuration. Please try again after some time", "error");
				else 
					showToast("Failed changing user configuration", "You may not have enough privileges to modify user account", "error");
			}
		}
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({ "query": query }));
	}


	async change_user_config(vdi_hidden, export_folder, export_enabled) {
		var query = `mutation {
			changeUserConfig(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", vdiHidden: ${vdi_hidden}, exportFolder: ${export_folder ? JSON.stringify(export_folder) : "\"\""}, exportEnabled: ${export_enabled}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserConfig){
			if (data.data.changeUserConfig.result){
				showToast("Changed User Configuration", `The user configuration has been successfully changed`, "success");
				this.getUserConfig();
			}
			else
				showToast("Failed changing configuration", "Error changing the user configuration. Please try again after some time", "error");
		} else {
			showToast("Failed changing user configuration", "You may not have enough privileges to modify user account", "error");
		}
	}

	async modify_user_app_browser(browser_finger_print, allowed, remove = false) {
		var query = `mutation {
			modifyUserAppBrowser(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", 
			browserFingerPrint: "${browser_finger_print}", allowed: ${allowed}, delete: ${remove}
			){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyUserAppBrowser){
			if (data.data.modifyUserAppBrowser.result === 1){
				showToast("Changed Device access", `The selected device for the application has been successfully configured`, "success");
				this.get_user_app_browsers();
			} else if (data.data.modifyUserAppBrowser.result === -1)
				showToast("Failed changing device access", "Error changing the user application access. There doesn't seem a machine with the provided configuration", "error");
			else
				showToast("Failed changing device access", "Error changing the user application access. Please try again after some time", "error");
		} else {
			showToast("Failed changing device access", "You may not have enough privileges to modify user account", "error");
		}
	}

	async modify_user_app_device(proc_id, disk_id, os_id, allowed, remember, remove = false) {
		var query = `mutation {
			modifyUserAppDevice(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}", procId: "${proc_id}", diskId: "${disk_id}", osId: "${os_id}", allowed: ${allowed}, remember: ${remember}, delete: ${remove}
			){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyUserAppDevice){
			if (data.data.modifyUserAppDevice.result === 1){
				showToast("Changed Device access", `The selected device for the application has been successfully configured`, "success");
				this.get_user_app_devices();
			} else if (data.data.modifyUserAppDevice.result === -1)
				showToast("Failed changing device access", "Error changing the user application access. There doesn't seem a machine with the provided configuration", "error");
			else
				showToast("Failed changing device access", "Error changing the user application access. Please try again after some time", "error");
		} else {
			showToast("Failed changing device access", "You may not have enough privileges to modify user account", "error");
		}
	}

	async change_user_account() {
		var query = `mutation change_user_account_type{
			changeUserAccountType(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser.email}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeUserAccountType){
			if (data.data.changeUserAccountType.result){
				showToast("Changed User Account Type", `The user account has been successfully changed to ${!this.state.wallet_info.prepaid ? "Prepaid" : "Postpaid"}`, "success");
				this.getUserDetails(this.state.selectedUserEmail);
			}
			else
				showToast("Failed changing account type", "Error changing the user account type. Please try again after some time", "error");
		} else {
			showToast("Failed changing account type", "You may not have enough privileges to modify user account", "error");
		}
	}

	getUserDetails(email) {
		const index = this.state.allUsers.findIndex(elem => elem.email == email);
		if (index >= 0 && index < this.state.allUsers.length) 
			this.setState({
				selectedUser: {...this.state.allUsers[index]},
				usr_roles: this.state.allUsers[index].role,
				selectedUserEmail: email,
			}, () => {
				this.get_user_vms_costs();
				this.getUserWalletDetails();
				this.get_user_costs();
				this.getUserConfig();
				this.get_user_app_devices();
				this.get_user_mf_auth_config();
				this.get_user_app_browsers();
			});
	}

	async add_company_user(event, values){
		this.setState({addUserBtnDisabled: true});
		const query = `mutation {
			addCompanyUser(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", 
				user: {
					email: "${values.email}", 
					realm: "${this.props.realm}", 
					domain: "${this.props.realm}", 
					password: "${values.password}", 
					name: "${values.name}", 
					phoneNo: "${values.phone_no}"
				}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.addCompanyUser && data.data.addCompanyUser !== 0) {		
			if (data.data.addCompanyUser.result === 1){
				this.setState({
					error: `Registration successful. You can now login from ${this.props.realm}.`,
					error_type: "success"
				})
				this.get_all_users();
			} else if (data.data.addCompanyUser.result === -1) {
				this.setState({
					error: "User already exists with the same email. Please try with another email.",
					error_type: "danger"
				})
			}
		} else {
			this.setState({
				error: `There is a problem at our end. Issue has been reported. Sorry for the inconvinience caused`,
				error_type: "danger"
			})
		}
		this.setState({addUserBtnDisabled: false});
	}

	async init_login_user(user_email) {
		const query = `mutation {
			secureAdminLoginUser(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${user_email}") {
				result
				token
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.secureAdminLoginUser){
			if (data.data.secureAdminLoginUser.result) {
				if(data.data.secureAdminLoginUser.token){
					const url = `https://manage.deepcloud.com/login/${data.data.secureAdminLoginUser.token}`
					window.open(url, "_blank", );
				} else {
					this.setState({
						login_user: true,
						user_email: user_email,
					})
				}
			} else {
				showToast("Failed logging into users account", "There is an error processing the request. Please try again later.", "error");
			}
		} else {
			showToast("Failed logging into users account", "You may not have enough privileges to login to users account", "error");
		}
	}

	async finish_login_user() {
		const query = `mutation {
			verifyAdminLoginUser(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.user_email}", otp: "${this.state.login_otp}") {
				result
				token
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.verifyAdminLoginUser){
			if (data.data.verifyAdminLoginUser.result === true && data.data.verifyAdminLoginUser.token) {
				const login_data = {
					email: this.state.user_email,
					token: data.data.verifyAdminLoginUser.token,
					auth_login: true,
				}
				const url = `https://manage.deepcloud.com/login/${btoa(JSON.stringify(login_data))}`
				this.setState({
					login_user: false,
				});
				window.open(url, "_blank", );
			} else if (data.data.verifyAdminLoginUser.result === false) {
				showToast("Failed logging into users account", "You may have entered incorrect OTP. Please try again.", "error");
			} else {
				showToast("Failed logging into users account", "There is an error processing the request. Please try again later.", "error");
				this.setState({
					login_user: false,
				});
			}
		} else {
			showToast("Failed logging into users account", "You may not have enough privileges to login to users account", "error");
			this.setState({
				login_user: false,
			});
		}
	}


	async get_all_users() {
		var query = `query {
			getAllUsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				phoneNo
				companyName
				role
				email
				userName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getAllUsers && data.data.getAllUsers.length >= 0){
			this.setState({
				allUsers: data.data.getAllUsers.sort(function(a, b){
					if(a.name < b.name) { return -1; }
					if(a.name > b.name) { return 1; }
					return 0;
				}),
			}, () => {
				if (this.state.allUsers.length > 0)
					this.getUserDetails(this.state.allUsers[0].email);
			});
		}
	}
	
	searchUsers() {
		const srch_elem = this.state.searchUserText.toLowerCase();
		this.setState({
			searchUsers: this.state.allUsers.filter(s => s.name.toLowerCase().includes(srch_elem) || s.email.toLowerCase().includes(srch_elem))
		})
	}

	render() {
		return (
			<React.Fragment>
				<Card>
					<CardHeader>
						Users
						<Button className="btn btn-sm btn-info float-right" onClick={() => this.setState({add_new_user: true})}>
							Create User
						</Button>
					</CardHeader>
					<CardSubtitle className="m-4">All Users registered</CardSubtitle>
					<CardBody>
						<Col lg="12">
							<div className="d-lg-flex">
								<Col lg="4">
									<div className="chat-leftsidebar mr-lg-4">
										<input type="text" className='form-control' onChange={(event) => this.setState({
											searchUserText: event.target.value
										}, () => this.searchUsers())}/>
										<div className="chat-leftsidebar-nav">
											<ul className="list-unstyled chat-list">
												<PerfectScrollbar style={{ height: "410px" }}>
													{
														(this.state.searchUserText.length > 0 ? this.state.searchUsers : this.state.allUsers).map((user, idx) =>
															<li key={"User_" + idx} className={this.state.selectedUser.email === user.email ? "active" : ""}>
																<Link to="#"  onClick={() => {this.getUserDetails(user.email)}}>
																	<Media>
																		<div className="align-self-center mr-3">
																			<img src={avatar2} className="rounded-circle avatar-xs" alt="" />
																		</div>
																		<Media className="overflow-hidden" body>
																			<h5 className="text-truncate font-size-14 mb-1">{user.name}</h5>
																			<p className="text-truncate mb-0">{user.email}</p>
																		</Media>
																	</Media>
																</Link>
															</li>
														)
													}
												</PerfectScrollbar>
											</ul>
										</div>
									</div>
								</Col>
								<Col lg="8">
									<div className="w-100 user-chat">
										<Card>
											<div>
												<div className="chat-conversation p-3">
													<ul className="list-unstyled">
														<Row>
															<Col lg="12" md="12">
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">
																			User Information
																			<Button className="btn btn-danger btn-sm float-right" onClick={() => this.setState({deleteUser: true})}>
																				Delete
																			</Button>
																		</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">User Name</th>
																						<td>:</td>
																						<td>{this.state.selectedUser.name}</td>
																					</tr>
																					<tr>
																						<th scope="row">Email</th>
																						<td>:</td>
																						<td>{this.state.selectedUser.email}</td>
																					</tr>
																					<tr>
																						<th scope="row">Phone Number</th>
																						<td>:</td>
																						<td>{this.state.selectedUser.phoneNo}</td>
																					</tr>
																					<tr>
																						<th scope="row">Company</th>
																						<td>:</td>
																						<td>{this.state.selectedUser.companyName}</td>
																					</tr>
																					<tr>
																						<th scope="row">User Alias</th>
																						<td>:</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_name}
																								validate={(value) => {
																									if(value.length === 0)
																										return "Enter a valid user name"
																									else if(value.length > 5)
																										return "User Name too long"
																								}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.selectedUser.userName}
																							/>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">Role</th>
																						<td>:</td>
																						<td>
																							{this.state.selectedUser.role && this.state.selectedUser.role.join()}
																							<Button className="btn btn-sm btn-warning ml-4" onClick={() => {this.setState({is_modify: true})}}>Modify</Button>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">Login as User</th>
																						<td>:</td>
																						<td>
																							<Button className="btn btn-info btn-sm"
																								onClick={() => this.init_login_user(this.state.selectedUser.email)}
																							>
																								Login
																							</Button>
																						</td>
																					</tr>

																					{/* <tr>
																						<th scope="row">Delete Company :</th>
																						<td><Button className="btn btn-danger mr-4">Delete</Button>Note: <b>All data related to the company will be deleted</b></td>
																					</tr> */}
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">Wallet Information</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">Account Type</th>
																						<td>:</td>
																						<td>{this.state.wallet_info.prepaid ? "Prepaid" : "Postpaid"}</td>
																						<td><Button className="btn btn-warning btn-sm" onClick={() => {this.change_user_account()}}>Change</Button></td>
																					</tr>
																					<tr>
																						<th scope="row">Wallet Balance</th>
																						<td>:</td>
																						<td>{this.props.currencyFormat.format(this.state.wallet_info.amount)}</td>
																						<td><Button className="btn btn-warning btn-sm" onClick={() => {this.setState({add_user_balance: true})}}>Modify</Button></td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">User Configuration</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">Hidden User</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch1"
																									checked={this.state.userConfig.vdiHidden}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch1"
																									onClick={() => {
																										this.change_user_config(!this.state.userConfig.vdiHidden, this.state.userConfig.exportFolder, this.state.userConfig.exportEnabled)
																									}}
																								>
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">Export Enabled</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch1"
																									checked={this.state.userConfig.exportEnabled}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch1"
																									onClick={() => {
																										this.change_user_config(this.state.userConfig.vdiHidden, this.state.userConfig.exportFolder, !this.state.userConfig.exportEnabled)
																									}}
																								>
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th>Export Folder</th>
																						<td>:</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_conf_user}
																								validate={(value) => {
																									if(value.length === 0)
																										return "Enter a proper folder"
																								}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.userConfig.exportFolder}
																							/>

																						</td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">User Resources Cost</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<thead>
																					<th>Cycle</th>
																					<th>CPU (Core)</th>
																					<th>Memory (MB)</th>
																					<th>HDD (GB)</th>
																					<th>SSD (GB)</th>
																				</thead>
																				<tbody>
																					<tr>
																						<th>Monthly</th>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.cpu = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_monthly.cpu}
																							/>
																						</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.ram = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_monthly.ram}
																							/>
																						</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.hdd = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_monthly.hdd}
																							/>
																						</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.ssd = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_monthly.ssd}
																							/>
																						</td>
																					</tr>
																					<tr>
																						<th>Hourly</th>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.cpu = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_hourly.cpu}
																							/>
																						</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.ram = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_hourly.ram}
																							/>
																						</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.hdd = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_hourly.hdd}
																							/>
																						</td>
																						<td>
																							<Editable
																								editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																								label={null}
																								mode="inline"
																								ajax={this.change_user_costs}
																								onValidated={(value) => {
																									this.state.resources_cost_monthly.ssd = Number(value);
																									this.forceUpdate();
																								}}
																								validate={(value) => {if(Number(value) != value){return "Only Numbers allowed"}}}
																								placement="top"
																								showText
																								type="textfield"
																								initialValue={this.state.resources_cost_hourly.ssd}
																							/>
																						</td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">User VMs'</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<thead>
																					<th>VM Name</th>
																					<th>Created Date</th>
																					<th>Last Billing Date</th>
																					<th>End Billing Date</th>
																					<th>Cost</th>
																					<th>Due Cost</th>
																				</thead>
																				<tbody>
																					{
																						this.state.usersvms && this.state.usersvms.map((vm) => {
																							return <tr>
																								<td>{vm.name}</td>
																								<td><Moment format="DD-MM-YYYY HH:mm">{vm.createDate}</Moment></td>
																								<td>{vm.lastBillDate && <Moment format="YYYY/MM/DD">{vm.lastBillDate}</Moment>}</td>
																								<td>{vm.billEndDate && <Moment format="YYYY/MM/DD">{vm.billEndDate}</Moment>}</td>
																								<td>{this.props.currencyFormat.format(vm.costpm)}</td>
																								<td>{this.props.currencyFormat.format(vm.cost)}</td>
																							</tr>
																						})
																					}
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">User Application Browsers</CardTitle>
																		<div className="table-responsive">
																			{
																				this.state.userAppBrowsers.map((dev) => {
																					return <Card>
																						<CardBody>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Allow  &nbsp;: &nbsp;</h5>
																									<p>
																										<Button 
																											className={`btn btn-sm ${dev.allowed ? "btn-warning" : "btn-info"}`}
																											onClick={() => this.modify_user_app_browser(dev.browserFingerPrint, !dev.allowed)}
																										>
																											{dev.allowed ? "Disallow" : "Allow"}
																										</Button>
																									</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Browser Finger Print  &nbsp;: &nbsp;</h5>
																									<p>{dev.browserFingerPrint}</p>
																								</Col>
																							</Row>																							
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Mobile Browser &nbsp;: &nbsp;</h5>
																									<p>{dev.isMobile ? "Yes" : "No"}</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Normal Browser &nbsp;: &nbsp;</h5>
																									<p>{dev.isBrowser ? "Yes" : "No"}</p>
																								</Col>
																							</Row>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Browser Name  &nbsp;: &nbsp;</h5>
																									<p>{dev.browserName}</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Browser Version &nbsp;: &nbsp;</h5>
																									<p>{dev.browserVersion}</p>
																								</Col>
																							</Row>
																						</CardBody>
																					</Card>
																				})
																			}
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">User Application Devices</CardTitle>
																		<div className="table-responsive">
																			{
																				this.state.userAppDevices.map((dev) => {
																					return <Card className="mb-2">
																						<CardBody>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Allow  &nbsp;: &nbsp;</h5>
																									<p>
																										<Button 
																											className={`btn btn-sm ${dev.allowed ? "btn-warning" : "btn-info"}`}
																											onClick={() => this.modify_user_app_device(dev.procId, dev.diskId, dev.osId, !dev.allowed, dev.remember)}
																										>
																											{dev.allowed ? "Disallow" : "Allow"}
																										</Button>
																									</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Remember  &nbsp;: &nbsp;</h5>
																									<p>
																										<Button 
																											className={`btn btn-sm ${dev.remember ? "btn-success" : "btn-warning"}`}
																											onClick={() => this.modify_user_app_device(dev.procId, dev.diskId, dev.osId, dev.allowed, !dev.remember)}
																										>
																											{dev.remember ? "Yes" : "NO"}
																										</Button>
																									</p>
																								</Col>
																							</Row>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Processor ID  &nbsp;: &nbsp;</h5>
																									<p>{dev.procId}</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Disk Serial  &nbsp;: &nbsp;</h5>
																									<p>{dev.diskId}</p>
																								</Col>
																							</Row>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">OS ID  &nbsp;: &nbsp;</h5>
																									<p>{dev.osId}</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">WAN IP  &nbsp;: &nbsp;</h5>
																									<p>{dev.wanIp}</p>
																								</Col>
																							</Row>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Machine Name  &nbsp;: &nbsp;</h5>
																									<p>{dev.machineName}</p>
																								</Col>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Architecture  &nbsp;: &nbsp;</h5>
																									<p>{dev.arch}</p>
																								</Col>
																							</Row>
																							<Row>
																								<Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Remove Machine &nbsp;: &nbsp;</h5>
																									<p>
																										<Button 
																											className={`btn btn-sm btn-danger`}
																											onClick={() => this.modify_user_app_device(dev.procId, dev.diskId, dev.osId, dev.allowed, dev.remember, true)}
																										>
																											Remove
																										</Button>
																									</p>
																								</Col>
																								{/* <Col lg={6} md={6} sm={12}>
																									<h5 class="float-left">Architecture  &nbsp;: &nbsp;</h5>
																									<p>{dev.arch}</p>
																								</Col> */}
																							</Row>
																						</CardBody>
																					</Card>
																				})
																			}
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">User Multi Factor Authentication Configuration</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">Admin Enfore MFA Policy</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch1"
																									checked={this.state.userMfAuthConfig.adminEnforce}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch1"
																									onClick={() => {
																										this.change_user_mf_auth_config(!this.state.userMfAuthConfig.adminEnforce, this.state.userMfAuthConfig.sms, this.state.userMfAuthConfig.mail, this.state.userMfAuthConfig.whatsapp);
																									}}
																								>
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">SMS</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch2"
																									checked={this.state.userMfAuthConfig.sms}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch2"
																									onClick={() => {
																										this.change_user_mf_auth_config(this.state.userMfAuthConfig.adminEnforce, !this.state.userMfAuthConfig.sms, this.state.userMfAuthConfig.mail, this.state.userMfAuthConfig.whatsapp);
																									}}
																								>
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">Mail</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch3"
																									checked={this.state.userMfAuthConfig.mail}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch3"
																									onClick={() => {
																										this.change_user_mf_auth_config(this.state.userMfAuthConfig.adminEnforce, this.state.userMfAuthConfig.sms, !this.state.userMfAuthConfig.mail, this.state.userMfAuthConfig.whatsapp);
																									}}
																								>
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">Whatsapp</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch4"
																									checked={this.state.userMfAuthConfig.whatsapp}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch4"
																									onClick={() => {
																										this.change_user_mf_auth_config(this.state.userMfAuthConfig.adminEnforce, this.state.userMfAuthConfig.sms, this.state.userMfAuthConfig.mail, !this.state.userMfAuthConfig.whatsapp);
																									}}
																								>
																								</label>
																							</div>
																						</td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
															</Col>
														</Row>
													</ul>
												</div>
											</div>
										</Card>
									</div>
							</Col>
						</div>
					</Col>
				</CardBody>
			</Card>
			<Modal isOpen={this.state.add_new_user} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({add_new_user:false})}}>
				<ModalHeader toggle={() => {this.setState({add_new_user:false})}}>
					Create New User
				</ModalHeader >
				<ModalBody>
					<AvForm className="form-horizontal" onValidSubmit={this.add_company_user}>

						{this.state.error ? <Alert color={this.state.error_type}>{this.state.error}</Alert> : null}

						<div className="form-group">
							<AvField name="email" label="Email" className="form-control" placeholder="Your Email Address" type="text" required />
						</div>

						<div className="form-group">
							<AvField name="name" label="Name" type="text" required placeholder="Enter your Name" />
						</div>

						<div className="form-group">
							<AvField name="phone_no" label="Phone Number" type="number" required placeholder="Enter your Contact Number" />
						</div>

						<div className="form-group">
							<AvField name="password" label="Password" type="password" required placeholder="Enter new Password" />
						</div>

						<div className="mt-3">
							<button className="btn btn-primary btn-block waves-effect waves-light" disabled={this.state.addUserBtnDisabled} type="submit">Register</button>
						</div>
					</AvForm>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.deleteUser} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({deleteUser:false})}}>
				<ModalHeader toggle={() => {this.setState({deleteUser:false})}}>
					Remove User
				</ModalHeader >
				<ModalBody>
					Are you sure of removing the user {this.state.selectedUser.name}. 
					This will remove all the data related to the company for the selected user. 
					All the VMs will be assigned to one of the company admin. <br />
					<b>Note:</b> This action cannot be undone.
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger btn-block" onClick={() => this.remove_user_company()}>
						Delete
					</Button>
					<Button className="btn btn-info btn-block" onClick={() => this.setState({deleteUser: false})}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.login_user} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({login_user:false})}}>
				<ModalHeader toggle={() => {this.setState({login_user:false})}}>
					Login as User
				</ModalHeader >
				<ModalBody>
					Please enter the OTP received by the user through email.
					<input 
						className="form-control"
						type="text"
						placeholder="Enter the OTP received in the users email"
						onChange={(event) => this.setState({login_otp: event.target.value})}
					/>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-success"
					onClick={() => this.finish_login_user()}
					>
						Login
					</Button>
					<Button className="btn btn-secondary" onClick={() => this.setState({login_user: false})}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.add_user_balance} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({add_user_balance:false})}}>
				<ModalHeader toggle={() => {this.setState({add_user_balance:false})}}>
					Add User Wallet Balance
				</ModalHeader >
				<ModalBody>
					You are willing to add amount to {this.state.selectedUser.name}
					<AvForm className="needs-validation mt-4" onValidSubmit={this.add_wallet_balance}>
						<Row>
							<Col lg="12" md="12" >
								<AvField
									name="amount"
									placeholder="Amount to be added to user's wallet"
									label="Amount"
									type="number"
									errorMessage="Amount cannor be empty"
									className="form-control"
									validate={{ 
										required: { value: true },
										max: {value: 10000},
										min: {value: 1}
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Button className="btn btn-block btn-warning" type="submit">
								Add
							</Button>
						</Row>
					</AvForm>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.is_modify} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({is_modify:false})}}>
				<ModalHeader toggle={() => {this.setState({is_modify:false})}}>
					Modify User Role
				</ModalHeader >
				<ModalBody>
					<CheckboxTree
						nodes={this.state.roles && this.state.roles.map((prm) => {
							return {
								value: prm,
								label: prm
							}
						})}
						iconsClass="fa5"
						checked={this.state.usr_roles}
						onCheck={checked => this.setState({usr_roles: checked})}
					/>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-warning btn-block" onClick={() => {this.modifyUserRoles()}}>
						Modify
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>);
	}
}

export default Users;