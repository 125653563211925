import React, { Component } from 'react';
import { Container, Row, Col, Button, CardTitle, Table, Alert, Media, Card, CardHeader, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Select from "react-select";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Groups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groups: [],
			selectedGroup: {},
			group_name: "",
			group_nin_users: [],
			user_new: [],
			roles: [],
			usr_roles: [],
			is_modify: false,
			selectedUser: "",
		}
		this.addNewGroup = this.addNewGroup.bind(this);
	}

	handleMulti = selectedMulti => {
		this.setState({
			user_new: selectedMulti
		});
	}

	changeUserRoles(idx) {
		this.setState({
			usr_roles: this.state.selectedGroup.users[idx].role,
			selectedUser: this.state.selectedGroup.users[idx].email,
			is_modify: true,
		});
	}

	async exitUserGroup() {
		var query = `mutation exit_user_group{
			exitUserGroup(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", groupId: "${this.state.selectedGroup.groupId}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.exitUserGroup){
			if (data.data.exitUserGroup.result){
				showToast("Exited from group", "You have been successfully exited from the group", "success");
				this.get_groups();
			} else {
				showToast("Failed exiting group", "There is an error removing yourself from group. Please try again after some time", "error");
			}
		} else {
			showToast("Failed exiting group", "You may not have enough privileges to remove from this group", "error");
		}
	}

	async get_group_roles() {
		var query = `query get_group_roles{
			getGroupRoles(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				roleName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getGroupRoles && data.data.getGroupRoles.length > 0){
			this.setState({
				roles: data.data.getGroupRoles.map((elem) => {return elem.roleName})
			});
		}
	}

	async getGroupNonUsers(group_id) {
		var query = `query get_group_non_users{
			getGroupsNonUsers(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", groupId: "${group_id}") {
				name
				email
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getGroupsNonUsers && data.data.getGroupsNonUsers.length > 0){
			this.setState({
				group_nin_users: data.data.getGroupsNonUsers.map((elem) => {
					return {
						label: `${elem.name}, ${elem.email}`,
						value: elem.email
					}
				}),
				user_new: [],
			});
		}
	}

	async modifyUserRoles() {
		var query =`mutation modify_user_role{
			modifyUserRolesGroup(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${this.state.selectedUser}", groupId: "${this.state.selectedGroup.groupId}", roles: ${JSON.stringify(this.state.usr_roles)}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyUserRolesGroup && data.data.modifyUserRolesGroup.result){
			showToast("Modified Roles Successfully", "Roles for the selected User for the selected have been modified successfully", "success");
			this.setState({
				is_modify: false
			});
			this.get_groups();
		} else {
			showToast("Modify Roles Failed", "You may not have enough privileges to modify roles", "error");
		}

	}

	async DeleteGroup(id){
		
	}

	async RemoveUserGroup(user_email) {
		var query = `mutation add_user_group{
			removeUserGroup(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", groupId: "${this.state.selectedGroup.groupId}", users: "${user_email}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeUserGroup && data.data.removeUserGroup.result){
			showToast("Users Removed", "The selected user has been removed from the group", "success");
			this.get_groups();
		} else {
			showToast("Failed removing User", "You may not have privileges to remove user", "error");
		}
	}

	async get_groups(){
		var query = `query get_groups{
			getGroups(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				domain
				groupId
				users {
					name
					email
					role
				}
			}
		}`;

		const data = await call_api(query);
		if (data && data.data && data.data.getGroups && data.data.getGroups.length > 0){
			this.setState({
				groups: data.data.getGroups
			}, () => {
				this.changeSelectedGroup(0)
			});
		}
	}

	changeSelectedGroup(ind) {
		this.setState({
			selectedGroup: this.state.groups[ind],
		});
		this.getGroupNonUsers(this.state.groups[ind].groupId);
	}

	async addUserGroups() {
		var usrs = this.state.user_new.map((elem) => {return elem.value});
		if (usrs.length > 0){
			var query = `mutation add_user_group{
				addUserGroup(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", groupId: "${this.state.selectedGroup.groupId}", users: ${JSON.stringify(usrs)}) {
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.addUserGroup && data.data.addUserGroup.result){
				showToast("Users Added", "All the selected users have been added to the group", "success");
				this.get_groups();
			} else {
				showToast("Users Not Added", "You may not have privileges to add users to the group", "error");
			}
		} else {
			showToast("Couldn't Add Users", "Please select Users to add to the group", "warning");
		}
	}

	async addNewGroup(){
		var query = `mutation add_new_group{
			createGroup(email:"${this.props.email}", token: "${this.props.token}", name:"${this.state.group_name}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createGroup && data.data.createGroup.result)
			this.setState({
				error_add: false,
				success_add: true,
			}, () => {
				this.get_groups();
			});
		else
		this.setState({
			error_add: true,
			success_add: false,
		});
	}

	componentDidMount() {
		this.get_groups();
		this.get_group_roles();	
	}

	render() {
		return (<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Users" breadcrumbItem="Groups" />
					<Card>
						<CardHeader>Create Group</CardHeader>
						<CardBody>
							{
								this.state.error_add ? (
									<Row>
										<Col md={8} lg={8} >
											<Alert color="danger">Error Creating new Groups. You may not have provileges to create group.</Alert>
										</Col>
									</Row>	
								) : null
							}
							{
								this.state.success_add ? (
									<Row>
										<Col md={8} lg={8} >
											<Alert color="success">Group created Successfully.</Alert>
										</Col>
									</Row>
								) : null
							}
							<AvForm className="needs-validation" onValidSubmit={this.addNewGroup}>
								<Row>
									<Col md={8} lg={8}>
										<AvField
											name="group_name"
											placeholder="New Group Name"
											type="text"
											errorMessage="Enter a valid name"
											label="Group Name"
											className="form-control"
											required
											onChange = {(event) => {this.setState({group_name: event.target.value})}} 
										/>
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<Button
											color="success"
											className="btn btn-success waves-effect waves-light"
											type="submit"
										>
											Add
										</Button>
									</Col>
								</Row>
							</AvForm>
						</CardBody>
					</Card>
					<Row>
						<Col lg="12" md="12">
							<Card>
								<CardHeader>Groups</CardHeader>
								<CardBody>
									<Col lg="12">
										<div className="d-lg-flex">
											<Col lg="4">
												<div className="chat-leftsidebar mr-lg-4">
													<div className="chat-leftsidebar-nav">
														<ul className="list-unstyled chat-list">
															<PerfectScrollbar style={{ height: "410px" }}>
																{
																	(this.state.groups.length > 0) ? this.state.groups.map((group, idx) =>
																		<li key={"Group_" + idx} className={this.state.selectedGroup.groupId === group.groupId ? "active" : ""}>
																			<Link to="#" onClick={() => {this.changeSelectedGroup(idx)}}>
																				<Media >
																					<Media className="overflow-hidden" body>
																						<h5 className="text-truncate font-size-14 mb-1">{group.name}</h5>
																					</Media>
																				</Media>
																			</Link>
																		</li>
																	) : null
																}
															</PerfectScrollbar>
														</ul>
													</div>
												</div>
											</Col>
											<Col lg="8">
												<div className="w-100 user-chat">
													<Card>
														<div className="chat-conversation p-3">
															<ul className="list-unstyled">
																<Row>
																	<Col lg="12" md="12">
																		<Card>
																			<CardBody>
																				<CardTitle className="mb-4">Group Information</CardTitle>
																				<div className="table-responsive">
																					<Table className="table-nowrap mb-0">
																						<tbody>
																							<tr>
																								<th scope="row">Group Name :</th>
																								<td>{this.state.selectedGroup.name}</td>
																							</tr>
																							<tr>
																								<th scope="row">Domain :</th>
																								<td>{this.state.selectedGroup.domain}</td>
																							</tr>
																						</tbody>
																					</Table>
																					<FormGroup className="mb-4" row>
																						<label htmlFor="projectbudget" className="col-form-label col-lg-2">Add Users</label><br />
																						<Col lg="12">
																							<Select
																								value={this.state.user_new}
																								isMulti={true}
																								onChange={this.handleMulti}
																								options={this.state.group_nin_users}
																								classNamePrefix="select2-selection"
																							/>
																						</Col>
																					</FormGroup>

																					<Button className="btn btn-info btn-block" onClick={() => {this.addUserGroups()}}>
																						Add Users
																					</Button>
																					<Button className="btn btn-warning btn-block" onClick={() => {this.exitUserGroup()}}>
																						Exit Group
																					</Button>																					{/* <Button className="btn btn-danger btn-block" onClick={() => {this.DeleteGroup(this.state.selectedGroup.groupId)}}>
																						Delete
																					</Button> */}
																				</div>
																			</CardBody>
																		</Card>
																	</Col>
																</Row>
																<Row>
																	<Col lg="12" md="12">
																		<Card>
																			<CardTitle className="mb-4">Users</CardTitle>
																			<CardBody>
																				<Table className="table-nowrap mb-0">
																					<thead>
																						<th>User</th>
																						<th>Email</th>
																						<th>Delete</th>
																						<th>Modify</th>
																					</thead>
																					<tbody>
																						{this.state.selectedGroup.users && this.state.selectedGroup.users.map((elem, idx) => {
																							return (elem.email !== this.props.email) ? <tr key={"User_"+idx}>
																								<td>{elem.name}</td>
																								<td>{elem.email}</td>
																								<td><Button className="btn btn-warning" onClick={() => {this.changeUserRoles(idx)}}>Modify</Button></td>
																								<td><Button className="btn btn-danger" onClick={() => {this.RemoveUserGroup(elem.email)}}>Remove</Button></td>
																							</tr> : null
																						})
																					}
																					</tbody>
																				</Table>
																			</CardBody>
																		</Card>
																	</Col>
																</Row>
															</ul>
														</div>
													</Card>
												</div>
											</Col>
										</div>
									</Col>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Modal isOpen={this.state.is_modify} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({is_modify:false})}}>
						<ModalHeader toggle={() => {this.setState({is_modify:false})}}>
							Modify User Role for the Group
						</ModalHeader >
						<ModalBody>
							<CheckboxTree
								nodes={this.state.roles.map((prm) => {
									return {
										value: prm,
										label: prm
									}
								})}
								iconsClass="fa5"
								checked={this.state.usr_roles}
								onCheck={checked => this.setState({usr_roles: checked})}
							/>
						</ModalBody>
						<ModalFooter>
							<Button className="btn btn-warning btn-block" onClick={() => {this.modifyUserRoles()}}>
								Modify
							</Button>
						</ModalFooter>
					</Modal>
				</Container>
			</div>
		</React.Fragment>)
	}
}

export default Groups;
