import React from "react";
import { Redirect } from "react-router-dom";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Users from "../pages/Customers/index";
import InstanceCreate from "../pages/Instances/instance-create";
import InstanceInfo from "../pages/Instances/list-instances";
import BillingPage from "../pages/Billing/index";
import GetHelp  from "../pages/Help/index";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import DownloadsPage from "../pages/Authentication/Downloads";
import Groups from "../pages/Groups/groups";
import UserProfile from "../pages/Authentication/profile";
import VDIReport from "../pages/Reports/vdi-report";

const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },
	{ path: "/admin", component: Users },
	{ path: "/create_instance", component: InstanceCreate },
	{ path: "/billing", component: BillingPage },
	{ path: "/help", component: GetHelp },
	{ path: "/vdi-report/:token", component: VDIReport },
	{ path: "/list_instance/:id/:node", component: InstanceInfo },
	{ path: "/groups", component: Groups },
	{ path: "/profile", component: UserProfile },
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login/:token?", component: Login },
	// { path: "/login", component: Login },
	{ path: "/downloads_page/", component: DownloadsPage},
	{ path: "/forgot-password", component: ForgetPasswordPage},
	{ path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
