import React, { Component } from 'react';
import call_api from "../../components/api-caller";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Collapse, CardText, Table, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import showToast from '../../components/showToast';

class ChooseCatalog extends Component {
	constructor() {
		super();
		this.state = {
			createInsTab: 1,
			catalogs: [],
			isCatlOpen: -1,
			selectedCatalog: {},
			showCatlCreate: false,
			new_vm_name: "",
		}
	}

	async get_catalogs_avail() {
		var query =`query get_catalogs{
			getCompanyCatalogs(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				catalogId
				name
				cost
				description
				config {
					iso
					cpu
					memory
					noOfNetworkCards
					disks {
						diskType
						diskStorage
					}
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyCatalogs && data.data.getCompanyCatalogs.length > 0){
			this.setState({
				catalogs: data.data.getCompanyCatalogs,
			});
		}
	}

	async CreateVmInstance() {
		if (this.state.new_vm_name !== ""){
			var query = `mutation create_vm_from_catalog{
				createVmFromCatalog(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", catalogId: ${this.state.selectedCatalog.catalogId}, vmName: "${this.state.new_vm_name}"){
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.createVmFromCatalog){
				if (data.data.createVmFromCatalog.result === 0){
					showToast(
						"Insufficient Credits",
						"You may not have enough wallet balance to create VM from the selected catalog. Please add sufficient amount to your wallet",
						"warning"
					)
				} else if (data.data.createVmFromCatalog.result === -1){
					showToast(
						"Create VM failed",
						"There is an error creating VM from the catalog. Please verify if all the information provided is valid.",
						"error"
					)
				} else if (data.data.createVmFromCatalog.result === 1){
					showToast(
						"Failed creating VM",
						"There is an error creating VM at our end. Please wait till we resolve it..",
						"error"
					)
				} else if (data.data.createVmFromCatalog.result === 2){
					showToast(
						"VM Created Successfully",
						"VM has been created successfully. You have been assigned to the VM to access it.",
						"success"
					)
				}
			} else {
				showToast(
					"Failed Creating VM",
					"You may not have enough privileges to create a VM instance. Please try again after some time",
					"error"
				)
			}
			this.setState({
				showCatlCreate: false,
			});
		} else {
			showToast("Invalid VM Name", "Please enter a valid vm name in order to create a VM", "warning");
		}
	}

	showCreateVM(index) {
		this.setState({
			selectedCatalog: this.state.catalogs[index],
			showCatlCreate: true,
		})
	}

	componentDidMount() {
		this.get_catalogs_avail();
	}

	cardOfCatalog = (index) => {
		const catl = this.state.catalogs[index];
		return (
			<Card>
				<CardBody>
					<CardTitle className="mt-0">{catl.name}</CardTitle>
					<CardSubtitle className="font-14 text-muted">{this.props.currencyFormat.format(catl.cost)}</CardSubtitle>
				</CardBody>
				<CardBody>
					<p>
						<button
							onClick={() => {
								this.setState({
									isCatlOpen: (this.state.isCatlOpen === index) ? -1 : index
								});
							}}
							className="btn btn-primary mo-mb-2"
							type="button"
							style={{ cursor: "pointer" }}
						>
							{(this.state.isCatlOpen !== index) ? "Show" : "Close"} Config
						</button>
					</p>
					<Collapse isOpen={this.state.isCatlOpen === index}>
						<Card>
							<CardBody>
								<CardText>{catl.description !== "" ? catl.description : "Sample Description"}</CardText>
								<Table>
									<tr>
										<th>OS</th>
										<td>{catl.config.iso}</td>
									</tr>
									<tr>
										<th>CPU</th>
										<td>{catl.config.cpu} Cores</td>
									</tr>
									<tr>
										<th>Memory</th>
										<td>{catl.config.memory} GB</td>
									</tr>
									<tr>
										<th>Network Cards</th>
										<td>{catl.config.noOfNetworkCards}</td>
									</tr>
									{catl.config.disks && catl.config.disks.map((elem, index) => {
										return (
											<tr>
												<th>Disk {index + 1}</th>
												<td>{elem.diskStorage} GB {elem.diskType ? "SSD" : "HDD"}</td>
											</tr>
										)
									})}
								</Table>
								{/* <CardFooter> */}
									<Button className="btn btn-warning btn-block" onClick={() => {this.showCreateVM(index)}}>
										Create VM
									</Button>
								{/* </CardFooter> */}
							</CardBody>
						</Card>
					</Collapse>
				</CardBody>
			</Card>
		)
	}

	render() {
		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<CardTitle>Choose a catalog from the below</CardTitle>
						<CardSubtitle className="mb-3">
							Each catalog has its own specificity and desried requirement. Please choose accordingly. 
						</CardSubtitle>
					</CardBody>
				</Card>
				<Row>
					{this.state.catalogs.map((elem, index) => {
						return <Col lg="3" md="6" sm="12">
							{this.cardOfCatalog(index)}
						</Col>
					})}
				</Row>
				<Modal isOpen={this.state.showCatlCreate} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({showCatlCreate:false})}}>
					<ModalHeader toggle={() => {this.setState({showCatlCreate:false})}}>
						Create Instance
					</ModalHeader >
					<ModalBody>
						Are you sure of creating an VM Instance from the catalog <b>{this.state.selectedCatalog.name}</b>. It will be billed monthly and its cost is <b>{this.props.currencyFormat.format(this.state.selectedCatalog.cost)}</b>.
						<Input
							className="mt-2"
							placeholder="Enter VM Name"
							required
							value = {this.state.new_vm_name}
							onChange={(event) => {
								this.setState({
									new_vm_name: event.target.value
								})
							}}
						/>
					</ModalBody>
					<ModalFooter>
						<Button className="btn btn-warning btn-block" onClick={() => {this.CreateVmInstance()}}>
							Create
						</Button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		)
	}
}

export default ChooseCatalog;