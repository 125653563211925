import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { Row, Col, Media, Card, CardBody, CardTitle, CardSubtitle, Table, Button, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import call_api from "../../components/api-caller";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import DashedLine from "../Instances/SplineArea";
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import showToast from "../../components/showToast";
import SweetAlert from "react-bootstrap-sweetalert"


class VmsPage extends Component {

	constructor (props){
		super(props);
		this.state = {
			allVms: [],
			searchVms: [],
			searchVmText: "",
			selectedVm: {},
			usg_cat: 0,
			cpu_usage: [],
			mem_usage: [],
			disk_read: [],
			disk_write: [],
			netin: [],
			netout: [],
			categories: [
				{
					name: "Hour",
					value: 0
				},
				{
					name: "Day",
					value: 1
				},
				{
					name: "Week",
					value: 2
				},
				{
					name: "Month",
					value: 3
				},
				{
					name: "Year",
					value: 4
				},
			],
			show_code: false,
			appCode: null,
			vmAppServerDetails: {},
			vmAppPublishings: [],
			appTemplates: [],
			add_app: false,
			add_app_temp: null,
			modify_app_users: false,
			compUsers: [],
			selectedAppPubl: {},
			selectedApppUsers: [],
			no_sessions: 1,
			vmConfig: {},
			vmPerms: [],
			vm_perms_user: false,
			vm_perms_user_details: {},
			selectedVmPermsUser: [],
		};
	}

	componentDidMount(){
		this.get_all_vms();
		this.get_templates_avail();
		this.get_all_users();
		this.get_vm_perms();
	}

	async get_vm_perms() {
		var query =`query {
			getVmPermsUsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") 
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmPermsUsers && data.data.getVmPermsUsers.length >= 0){
			this.setState({
				vmPerms: data.data.getVmPermsUsers,
			});
		}
	}

	async get_all_users() {
		var query =`query get_all_users{
			getAllUsers(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				email
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getAllUsers && data.data.getAllUsers.length >= 0){
			this.setState({
				compUsers: data.data.getAllUsers,
			});
		}
	}

	async getVmConfig() {
		const query = `query {
			getVmConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", nodeName: "${this.state.selectedVm.nodeName}") {
				vmReport
				vmTimeline
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmConfig){
			this.setState({
				vmConfig: data.data.getVmConfig
			});
		}
	}

	async get_vm_app_publishings() {
		const query = `query {
			getVmAppPublishings(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}") {
				tempId
				appName
				appSpec
				users {
					name
					email
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmAppPublishings && data.data.getVmAppPublishings.length > 0){
			this.setState({
				vmAppPublishings: data.data.getVmAppPublishings
			});
		}
	}

	async get_templates_avail() {
		var query =`query {
			getCompAppTemplates(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				tmpId
				name
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompAppTemplates && data.data.getCompAppTemplates.length > 0){
			this.setState({
				appTemplates: data.data.getCompAppTemplates,
			});
		}
	}

	async getVmUsage() {
		var query = `query get_vm_usage_admin{
			getVmUsageAdmin(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", nodeName: "${this.state.selectedVm.nodeName}", category: ${this.state.usg_cat}){
				cpu {
					usage
					time
				}
				diskRead {
					usage
					time
				}
				diskWrite {
					usage
					time
				}
				mem {
					usage
					time
				}
				netin {
					usage
					time
				}
				netout {
					usage
					time
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmUsageAdmin){
			var usg_stat = data.data.getVmUsageAdmin;
			if (usg_stat.cpu && usg_stat.cpu.length > 0){
				this.setState({
					cpu_usage: usg_stat.cpu
				});
			}
			if (usg_stat.mem && usg_stat.mem.length > 0){
				this.setState({
					mem_usage: usg_stat.mem
				});
			}
			if (usg_stat.diskRead && usg_stat.diskRead.length > 0){
				this.setState({
					disk_read: usg_stat.diskRead
				});
			}
			if (usg_stat.diskWrite && usg_stat.diskWrite.length > 0){
				this.setState({
					disk_write: usg_stat.diskWrite
				});
			}
			if (usg_stat.netin && usg_stat.netin.length > 0){
				this.setState({
					netin: usg_stat.netin
				});
			}
			if (usg_stat.netout && usg_stat.netout.length > 0){
				this.setState({
					netout: usg_stat.netout
				});
			}
		}
	}

	async change_vm_config(){
		const query = `mutation {
			changeVmConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", nodeName: "${this.state.selectedVm.nodeName}", vmReport: ${this.state.vmConfig.vmReport}, vmTimeline: ${this.state.vmConfig.vmTimeline}, hidden: ${this.state.selectedVm.hidden}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeVmConfig) {
			if (data.data.changeVmConfig.result === 1) {
				showToast("Changed VDI Config", "VDI Config has been successfully changed for the selected VM", "success");
				this.get_all_vms();
			}
			else if (data.data.changeVmConfig.result === -1)
				showToast("Cannot change VDI Config", "The selected VM may not be of a VDI type. Please try again", "error");
			else
				showToast("Cannot change VDI Config", "There is an error processing the  request. Please try again after some time.", "error");
		}
		else
			showToast("Cannot change VDI Config", "You may not have required privilages to modify VDI configuration", "error");
		this.getVmConfig();
	}
	
	async changeVmType(new_id){
		const query = `mutation {
			changeVmType(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", newType: ${new_id}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeVmType) {
			if (data.data.changeVmType.result)
				showToast("Changed VM Type", "VM-Type has been successfully changed", "success");
			else
				showToast("Cannot change VM type", "There is an error processing the  request. Please try again after some time.", "error");
		}
		else
			showToast("Cannot change VM type", "You may not have required privilages to modify vm type", "error");
	}

	async getVmAppServerDetails() {
		var query = `query {
			getVmAppServerDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}"){
				desktopName
				ipAddress
				username
				adminUsers
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmAppServerDetails) {
			this.setState({
				vmAppServerDetails: data.data.getVmAppServerDetails
			});
		}
	}

	async getVmDetails(vm_id, node_name){
		var query = `query get_vm_info_admin{
			getVmInfoAdmin(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${vm_id}", nodeName: "${node_name}"){
				os
				name
				vmId
				nodeName
				location
				cores
				ram
				netUsage
				status
				pool {
					name
					email
					role
					rdpEnabled
					rdpDomainLogin
					vmPerms
				}
				disks {
					diskName
					diskType
					storage
				}
				typeId
				hidden
				guestAgentStatus
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmInfoAdmin) {
			this.setState({
				selectedVm: data.data.getVmInfoAdmin
			}, () => {
				this.getVmUsage();
				this.getVmConfig();
				if (data.data.getVmInfoAdmin.typeId === 3) {
					this.getVmAppServerDetails();
					this.get_vm_app_publishings();
				}
			});
		}
	}

	async initAppServer(){
		const query = `mutation {
			initAppServer(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}") {
				result
				appCode
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.initAppServer) {
			if (data.data.initAppServer.result === 1) {
				showToast("Initialised VM for app server", "Please use the code to initilise app server in the vm", "success");
				this.setState({
					appCode: data.data.initAppServer.appCode,
					show_code: true
				});
			}
			else if (data.data.initAppServer.result === -1)
				showToast("Couldn't Initialize VM", "The selected VM is not an app server to initialize", "error");
			else if (data.data.initAppServer.result === 0)
				showToast("Couldn't Initialize VM", "There is an error processing the request. Please try again after some time", "error");
		}
		else
			showToast("Couldn't Initialize VM", "You may not have required privilages to initialise app-server for the vm", "error");
	}

	async modifyAppPublishing(){
		if (!this.state.selectedVm.guestAgentStatus) {
			showToast("App Server Details Modification Failed", "The Guest agent on the VM not running. Please start the guest agent on this VM and try again", "error");
			return;
		}
		this.setState({
			modify_app_users: false,
		});
		if (this.state.no_sessions > 0 && this.state.no_sessions <= 5) {
			const query = `mutation { 
				modifyAppPublishing(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", 
				appSpec: "${this.state.selectedAppPubl.appSpec}", users: [${this.state.selectedApppUsers.map((elem)=> `{email: "${elem}", count: ${this.state.no_sessions}}`)}]) {
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.modifyAppPublishing){
				if (data.data.modifyAppPublishing.result === 1) {
					showToast("Application modified successfully", "The user modification process is pushed to background tasks. Please check within next few minutes for the changed", "success");
					this.get_vm_app_publishings();
				}
				else if (data.data.modifyAppPublishing.result === -1)
					showToast("Failed modifying Application", "The selected VM is not an app server to publish", "error");
				else if (data.data.modifyAppPublishing.result === -2)
					showToast("Failed modifying Application", "The selected VM has not been initialized to publish an app", "error");
				else if (data.data.modifyAppPublishing.result === 0)
					showToast("Failed modifying Application", "There is an error processing the request. Please try again after some time", "error");
			} else
				showToast("Failed modifying Application", "You may not have required privilages to publish an application to app-server", "error");
		} else {
			showToast("Failed modifying Application", "Invalid number of sessions provided", "error");
		}
	}

	async change_vm_perms_user(){
		this.setState({
			vm_perms_user: false,
		});
		const query = `mutation { 
			changeVmPermsUser(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", userEmail: "${this.state.vm_perms_user_details.email}", newPerms: ${JSON.stringify(this.state.selectedVmPermsUser)}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeVmPermsUser){
			if (data.data.changeVmPermsUser.result === 1) {
				showToast("Permissions changed successfully", "The permssions for the selected user have been changed successfully", "success");
				this.getVmDetails(this.state.selectedVm.vmId, this.state.selectedVm.nodeName);
				
			}
			else if (data.data.changeVmPermsUser.result === -1)
				showToast("Failed changing Permissions", "The selected user is not user for the selected VM. Please check the details and try again", "error");
			else if (data.data.changeVmPermsUser.result === 0)
				showToast("Failed changing Permissions ", "There is an error processing the request. Please try again after some time", "error");
		} else
			showToast("Failed changing Permissions ", "You may not have required privilages to change the permissions for the user", "error");
		
	}

	async createAppPublishing(){
		const query = `mutation { 
			createRemoveAppPublishing(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", appTempId: "${this.state.add_app_temp}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createRemoveAppPublishing){
			if (data.data.createRemoveAppPublishing.result === 1) {
				showToast("Application published successfully", "The selected application will soon be published into the app-server", "success");
				this.get_vm_app_publishings();
			}
			else if (data.data.createRemoveAppPublishing.result === -1)
				showToast("Failed publishing Application", "The selected VM is not an app server to publish", "error");
			else if (data.data.createRemoveAppPublishing.result === -2)
				showToast("Failed publishing Application", "The selected VM has not been initialized to publish an app", "error");
			else if (data.data.createRemoveAppPublishing.result === -3)
				showToast("Failed publishing Application", "Incorrect parameters have been provided. Please try again.", "error");
			else if (data.data.createRemoveAppPublishing.result === 0)
				showToast("Failed publishing Application", "There is an error processing the request. Please try again after some time", "error");
		} else
			showToast("Failed publishing Application", "You may not have required privilages to publish an application to app-server", "error");
	}

	async removeAppPublishing(){
		this.setState({
			modify_app_users: false,
		})
		const query = `mutation { 
			createRemoveAppPublishing(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", remove: true, appSpec: "${this.state.selectedAppPubl.appSpec}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createRemoveAppPublishing){
			if (data.data.createRemoveAppPublishing.result === 1) {
				showToast("Application removed successfully", "The selected application will soon be removed from the app-server", "success");
				this.get_vm_app_publishings();
			}
			else if (data.data.createRemoveAppPublishing.result === -1)
				showToast("Failed removing Application", "The selected VM is not an app server to publish", "error");
			else if (data.data.createRemoveAppPublishing.result === -2)
				showToast("Failed removing Application", "The selected VM has not been initialized to publish an app", "error");
			else if (data.data.createRemoveAppPublishing.result === -3)
				showToast("Failed removing Application", "Incorrect parameters have been provided. Please try again.", "error");
			else if (data.data.createRemoveAppPublishing.result === 0)
				showToast("Failed removing Application", "There is an error processing the request. Please try again after some time", "error");
		} else
			showToast("Failed removing Application", "You may not have required privilages to remove an application to app-server", "error");
	}

	async modifyAppServerDetails(admin_user) {
		const query = `mutation { 
			modifyAppServerDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", adminUser: ${admin_user}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyAppServerDetails){
			if (data.data.modifyAppServerDetails.result) {
				showToast("App Server Details Modified", "App Server Details for the user has been modified successfully", "success");
				this.getVmAppServerDetails();
			} else {
				showToast("App Server Details Modification failed", "An error occured while trying to modify app server details. Please try again after sometime", "error");
			}
		} else
			showToast("App Server Details Modification failed", "You may not have required privilages to modify App Server Details.", "error");
	}

	async modifyRDPUser(user_email, rdp_enabled, rdp_dom_login) {
		const query = `mutation { 
			modifyRdpVmUser(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", userEmail: "${user_email}", rdpEnabled: ${rdp_enabled ? "true" : "false"}, domLogin: ${rdp_dom_login ? "true" : "false"}) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyRdpVmUser){
			if (data.data.modifyRdpVmUser.result) {
				showToast("RDP Access Modified", "RDP access for the user has been modified successfully", "success");
				this.getVmDetails();
			} else {
				showToast("RDP Access Modification failed", "An error occured while trying to modify access for the user. Please try again after sometime", "error");
			}
		} else
			showToast("RDP Access Modification failed", "You may not have required privilages to modify RDP access for the user.", "error");
	}

	async get_all_vms() {
		var query = `query get_company_vms{
			getCompanyVms(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}"){
				name
				vmId
				nodeName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyVms && data.data.getCompanyVms.length > 0){
			this.setState({
				allVms: data.data.getCompanyVms
			}, () => {
				this.getVmDetails(this.state.allVms[0].vmId, this.state.allVms[0].nodeName);
			});
		}
	}
	
	changeAppSpec(index) {
		if (index < this.state.vmAppPublishings.length) {
			const found_app = this.state.vmAppPublishings[index];
			this.setState({
				selectedAppPubl: found_app,
				selectedApppUsers: found_app.users.map((elem) => elem.email)
			});
		}
	}

	searchVms() {
		const srch_elem = this.state.searchVmText.toLowerCase();
		this.setState({
			searchVms: this.state.allVms.filter(s => s.name.toLowerCase().includes(srch_elem) || s.vmId.toLowerCase().includes(srch_elem))
		})
	}
	
	render() {
		function mb_gb_conv(mb) {
			const gb = (mb / 1024).toFixed(2)
			if (gb > 0){
				return `${gb} GB`
			} else {
				return `${mb.toFixed(2)} MB`
			}
		}

		return (
			<React.Fragment>
				<Card>
					<CardHeader>
						VM
					</CardHeader>
					<CardSubtitle className="m-4">VMs' List</CardSubtitle>
					<CardBody>
						<Col lg="12">
							<div className="d-lg-flex">
								<Col lg="4">
									<div className="chat-leftsidebar mr-lg-4">
										<input type="text" className='form-control' onChange={(event) => this.setState({
											searchVmText: event.target.value
										}, () => this.searchVms())}/>
										<div className="chat-leftsidebar-nav">
											<ul className="list-unstyled chat-list">
												<PerfectScrollbar style={{ height: "700px" }}>
													{
														(this.state.searchVmText.length > 0 ? this.state.searchVms : this.state.allVms).map((vm, idx) =>
															<li key={"User_" + idx} className={this.state.selectedVm.vmId === vm.vmId ? "active" : ""}>
																<Link to="#"  onClick={() => {this.getVmDetails(vm.vmId, vm.nodeName);}}>
																	<Media>
																		<Media className="overflow-hidden" body>
																			<h5 className="text-truncate font-size-14 mb-1">{vm.name}</h5>
																			<p className="text-truncate mb-0">Node: {vm.nodeName}</p>
																		</Media>
																		{/* <div className="font-size-14">ID: {vm.vmId}</div> */}
																	</Media>
																</Link>
															</li>
														)
													}
												</PerfectScrollbar>
											</ul>
										</div>
									</div>
								</Col>
								<Col lg="8">
									<div className="w-100 user-chat">
										<Card>
											<div>
												<div className="chat-conversation p-3">
													<ul className="list-unstyled">
														<Row>
															<Col lg="12" md="12">
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">VM Information</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">VM Name</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.name}</td>
																					</tr>
																					<tr>
																						<th scope="row">VM ID</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.vmId}</td>
																					</tr>
																					<tr>
																						<th scope="row">VM Node</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.nodeName}</td>
																					</tr>
																					<tr>
																						<th scope="row">Status</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.status}</td>
																					</tr>
																					<tr>
																						<th scope="row">Guest Agent Status</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.guestAgentStatus ? "Running": "Not Running"}</td>
																					</tr>
																					
																					<tr>
																						<th scope="row">Vm-Type</th>
																						<td>:</td>
																						<td>
																							<AvForm>
																								<AvField
																									type = "select"
																									name="vm_type_data"
																									value={this.state.selectedVm.typeId}
																									onChange={(event) => this.changeVmType(event.target.value)}
																								>
																									<option key="null" value="null">Select</option>
																									{this.props.vm_types && this.props.vm_types.map((elem) => (
																										<option key = {"vm_type_"+elem.typeId} value={elem.typeId}>
																											{elem.name}
																										</option>
																									))}
																								</AvField>
																							</AvForm>
																						</td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">
																			VM Configuration
																		</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th>VM Hidden</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch1"
																									checked={this.state.selectedVm.hidden}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch1"
																									onClick={() => {
																										this.state.selectedVm.hidden = !this.state.selectedVm.hidden;
																										this.forceUpdate();
																										this.change_vm_config();
																									}}
																								>
																									{this.state.selectedVm.hidden ? "Hidden" : "Not Hidden"}
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">VM Report</th>
																						<td>:</td>
																						<td>
																							<div
																								className="custom-control custom-switch mb-2"
																								dir="ltr"
																							>
																								<input
																									type="checkbox"
																									className="custom-control-input"
																									id="customSwitch1"
																									checked={this.state.vmConfig.vmReport}
																								/>
																								<label
																									className="custom-control-label"
																									htmlFor="customSwitch1"
																									onClick={() => {
																										this.state.vmConfig.vmReport = !this.state.vmConfig.vmReport;
																										this.forceUpdate();
																										this.change_vm_config();
																									}}
																								>
																									{this.state.vmConfig.vmReport ? "Enabled" : "Disabled"}
																								</label>
																							</div>
																						</td>
																					</tr>
																					<tr>
																						<th scope="row">VM Report Schedule</th>
																						<td>:</td>
																						<td>
																							<select value={this.state.vmConfig.vmTimeline} className="form-control"
																								onChange={(event) => {
																									const no_days = Number(event.target.value);
																									if (!isNaN(no_days)) {
																										this.state.vmConfig.vmTimeline = no_days;
																										this.forceUpdate();
																										this.change_vm_config();
																									}
																								}}
																							>
																								<option key="h" value="1">1 Hour</option>
																								<option key="d" value="2">1 Day</option>
																								<option key="w" value="3">1 Week</option>
																								<option key="m" value="4">1 Month</option>
																								<option key="y" value="5">1 Month</option>
																							</select>
																						</td>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																{ this.state.selectedVm.typeId && this.state.selectedVm.typeId === 3 ? (
																	<Card>
																		<CardBody>
																			<CardTitle className="mb-4">
																				App-Server Details
																				<Button className="btn btn-info btn-sm float-right" onClick={() => this.initAppServer()}>
																					Initialize
																				</Button>
																			</CardTitle>
																			<div className="table-responsive">
																				<Table className="table-nowrap mb-0">
																					<tbody>
																						{/* <tr>
																							<th scope="row">Computer-Name</th>
																							<td>:</td>
																							<td>{this.state.vmAppServerDetails.desktopName}</td>
																						</tr> */}
																						<tr>
																							<th scope="row">IP-Address</th>
																							<td>:</td>
																							<td>{this.state.vmAppServerDetails.ipAddress}</td>
																						</tr>
																						
																						{/* <tr>
																							<th scope="row">Username</th>
																							<td>:</td>
																							<td>{this.state.vmAppServerDetails.username}</td>
																						</tr> */}
																						<tr>
																							<th scope="row">Admin Users</th>
																							<td>:</td>
																							<td>
																								<div className="custom-control custom-checkbox mb-3">
																									<input
																										type="checkbox"
																										className="custom-control-input"
																										id="admin_user"
																										onChange={() => false}
																										checked={this.state.vmAppServerDetails.adminUsers}
																									/>
																									<label
																										className="custom-control-label"
																										onClick={() => {
																											this.modifyAppServerDetails(!this.state.vmAppServerDetails.adminUsers)
																										}}
																									>
																									</label>
																								</div>
																							</td>
																						</tr>
																						<tr>
																							<th>
																								App-Publishings
																								<Button className="btn btn-info btn-sm float-right" onClick={() => this.setState({add_app: true})}>
																									Add
																								</Button>
																							</th>
																							<td>:</td>
																							<td>
																								<select className="form-control" onChange={(event) => this.changeAppSpec(event.target.value)}>
																									<option key="null_app_tmp" value="null">Select</option>
																								{
																									this.state.vmAppPublishings.map((elem, indx) => (
																										<option key={"app_tmp"+elem.appSpec} value={indx}>{elem.appName}</option>
																									))
																								}
																								</select>
																							</td>
																						</tr>
																						<tr>
																							<th>
																								App-Users
																								<Button className="btn btn-info btn-sm float-right" onClick={() => this.setState({modify_app_users: true})}>
																									Modify
																								</Button>
																							</th>
																							<td>:</td>
																							<td>
																								{
																									this.state.selectedAppPubl.users && this.state.selectedAppPubl.users.map((elem)=>elem.name).join(", ")
																								}
																							</td>
																						</tr>
																						{
																							this.state.selectedAppPubl.appSpec && this.state.selectedAppPubl.appSpec !== "" ?
																							<tr>
																								<th>Remove</th>
																								<td>:</td>
																								<td>
																									<Button className="btn btn-danger" onClick={() => this.removeAppPublishing()}>
																										Remove
																									</Button>
																								</td>
																							</tr> : null
																						}
																					</tbody>
																				</Table>
																			</div>
																		</CardBody>
																	</Card>
																	) : null 
																}
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">VM Configuration</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th scope="row">Memory (GB)</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.ram}</td>
																					</tr>
																					<tr>
																						<th scope="row">CPU (Cores)</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.cores}</td>
																					</tr>
																					<tr>
																						<th scope="row">OS</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.os}</td>
																					</tr>
																					<tr>
																						<th scope="row">Network Usage</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.netUsage && mb_gb_conv(this.state.selectedVm.netUsage)}</td>
																					</tr>
																					<tr>
																						<th scope="row">Location</th>
																						<td>:</td>
																						<td>{this.state.selectedVm.location}</td>
																					</tr>
																					<tr>
																						<th scope="row">Disks</th>
																						<Table>
																							<tbody>
																								{this.state.selectedVm.disks && this.state.selectedVm.disks.map((disk) => {
																									return (
																										<tr>
																											<th>{disk.diskName}</th>
																											<td>{disk.diskType ? "SSD" : "HDD"}</td>
																											<td>{disk.storage}</td>
																										</tr>
																									)
																								})}
																							</tbody>
																						</Table>
																					</tr>
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">VM Users</CardTitle>
																		<div className="table-responsive">
																			<Table className="table-nowrap mb-0">
																				<tbody>
																					<tr>
																						<th>Name</th>
																						{/* <th>Email</th> */}
																						<th>Role</th>
																						<th>RDP Enabled</th>
																						<th>RDP Domain Login</th>
																						<th>Permissions</th>
																					</tr>
																					{this.state.selectedVm.pool && this.state.selectedVm.pool.map((elem) => {
																						return (
																							<tr>
																								<td>{elem.name}</td>
																								{/* <td>{elem.email}</td> */}
																								<td>{elem.role && elem.role.length > 0 && elem.role.join(", ")}</td>
																								<td><Button className={"btn btn-sm " + (elem.rdpEnabled ? "btn-success" : "btn-warning")} onClick={() => this.modifyRDPUser(elem.email, !elem.rdpEnabled, elem.rdpDomainLogin)}>{elem.rdpEnabled ? "Enabled":"Disabled"}</Button></td>
																								<td>
																									<div className="custom-control custom-checkbox mb-3">
																										<input
																											type="checkbox"
																											className="custom-control-input"
																											id="CustomCheck1"
																											onChange={() => false}
																											checked={elem.rdpDomainLogin}
																										/>
																										<label
																											className="custom-control-label"
																											onClick={() => {
																												this.modifyRDPUser(elem.email, elem.rdpEnabled, !elem.rdpDomainLogin)
																											}}
																										>
																										</label>
																									</div>
																								</td>
																								<td>
																									{elem.vmPerms && elem.vmPerms.length > 0 && elem.vmPerms.map(perm => perm.replace("VMPerms.", "")).join(", ")}
																									<i className='float-left bx bx-edit-alt mr-4' 
																										style={{fontSize: "18px"}} 
																										onClick={() => this.setState({
																											vm_perms_user_details: {
																												email: elem.email,
																												name: elem.name,
																											},
																											vm_perms_user: true,
																											selectedVmPermsUser: elem.vmPerms,
																										})}
																									/>
																								</td>
																							</tr>
																						)
																					})}
																				</tbody>
																			</Table>
																		</div>
																	</CardBody>
																</Card>
																<Card>
																	<CardBody>
																		<CardTitle className="mb-4">VM Usage</CardTitle>
																		<select onChange={(event) => {this.setState({usg_cat: event.target.value}, () => {this.getVmUsage()})}}>
																			{
																				this.state.categories.map((elem) => {
																					return (
																						<option value={elem.value}>
																							{elem.name}
																						</option>
																					)
																				})
																			}
																		</select>
																		<Col lg={12}>
																			<Card>
																				<CardBody>
																					<CardTitle className="mb-4">CPU Usage Stats </CardTitle>
																					<DashedLine data={{
																						xData: this.state.cpu_usage.map((obj => {return obj.time})),
																						series: [
																							{
																								name: "CPU",
																								data: this.state.cpu_usage.map((obj => {return obj.usage}))
																							}
																						]
																					}}/>
																				</CardBody>
																			</Card>
																		</Col>
																		<Col lg={12}>
																			<Card>
																				<CardBody>
																					<CardTitle className="mb-4">Memory Usage Stats </CardTitle>
																					<DashedLine data={{
																						xData: this.state.mem_usage.map((obj => {return obj.time})),
																						series: [
																							{
																								name: "RAM",
																								data: this.state.mem_usage.map((obj => {return obj.usage}))
																							}
																						]
																					}}/>
																				</CardBody>
																			</Card>
																		</Col>
																		<Col lg={12}>
																			<Card>
																				<CardBody>
																					<CardTitle className="mb-4"> Disk IO Usage Stats </CardTitle>
																					<DashedLine data={{
																						xData: this.state.disk_read.map((obj => {return obj.time})),
																						series: [
																							{
																								name: "Network IN",
																								data: this.state.disk_read.map((obj => {return obj.usage}))
																							},
																							{
																								name: "Network OUT",
																								data: this.state.disk_write.map((obj => {return obj.usage}))
																							}
																						]
																					}}/>
																				</CardBody>
																			</Card>
																		</Col>
																		<Col lg={12}>
																			<Card>
																				<CardBody>
																					<CardTitle className="mb-4"> Netwrok IO Usage Stats </CardTitle>
																					<DashedLine data={{
																						xData: this.state.netin.map((obj => {return obj.time})),
																						series: [
																							{
																								name: "Network IN",
																								data: this.state.netin.map((obj => {return obj.usage}))
																							},
																							{
																								name: "Network OUT",
																								data: this.state.netout.map((obj => {return obj.usage}))
																							}
																						]
																					}}/>
																				</CardBody>
																			</Card>
																		</Col>
																	</CardBody>
																</Card>
															</Col>
														</Row>
													</ul>
												</div>
											</div>
										</Card>
									</div>
							</Col>
						</div>
					</Col>
				</CardBody>
			</Card>
			<Modal isOpen={this.state.vm_perms_user} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({vm_perms_user:false})}}>
				<ModalHeader toggle={() => this.setState({vm_perms_user:false})}>
					VM Permissions for the User
				</ModalHeader >
				<ModalBody>
					Are you sure you want to modify the permissions for the user <b>{this.state.vm_perms_user_details.name}</b> with email <b>{this.state.vm_perms_user_details.email}</b> <br />
					<div className='mt-4'>
						<CheckboxTree
							nodes={this.state.vmPerms.map((perm) => {
								return {
									value: perm,
									label: perm.replace("VMPerms.", ""),
								}
							})}
							iconsClass="fa5"
							checked={this.state.selectedVmPermsUser}
							onCheck={checked => this.setState({selectedVmPermsUser: checked})}
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-warning btn-block" onClick={() => this.change_vm_perms_user()}>
						Modify
					</Button>
					<Button className="btn btn-info btn-block" onClick={() => this.setState({vm_perms_user:false})}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.add_app} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({add_app:false})}}>
				<ModalHeader toggle={() => {this.setState({add_app:false})}}>
					Application Publishing
				</ModalHeader >
				<ModalBody>
					<Row>
						<Col lg="12" md="12" sm="12">
							<select className="form form-control" onChange={(event) => this.setState({add_app_temp: event.target.value}) }>
								<option key={"empty_temp"} value={null}>Select</option>
							{
								this.state.appTemplates.map((elem) => 
									<option key={"templt_"+elem.tmpId} value={elem.tmpId}>{elem.name}</option>
								)
							}
							</select>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-warning btn-block" onClick={() => this.createAppPublishing()}>
						Add
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.modify_app_users} role="dialog" autoFocus={true} className="modal-sm" centered={true} tabIndex="-1" toggle={() => {this.setState({modify_app_users:false})}}>
				<ModalHeader toggle={() => {this.setState({modify_app_users:false})}}>
					Application Users
				</ModalHeader >
				<ModalBody>
					<CheckboxTree
						nodes={this.state.compUsers.map((usr) => {
							return {
								value: usr.email,
								label: usr.name
							}
						})}
						iconsClass="fa5"
						checked={this.state.selectedApppUsers}
						onCheck={checked => this.setState({selectedApppUsers: checked})}
					/>
					<form className="mt-4 form">
						<div class="form-group mb-0">
							<label for="noSessions">Number of Max Sessions</label>
							<input id="noSessions" type="number" className="form-control" 
								value={this.state.no_sessions} 
								onChange={(event) => this.setState({no_sessions: Number(event.target.value)})} 
							/>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-warning btn-block" onClick={() => this.modifyAppPublishing()}>
						Modify
					</Button>
				</ModalFooter>
			</Modal>
			{this.state.show_code ? 
				<SweetAlert
					success
					title="Initialisation Code"
					onConfirm={() => this.setState({ show_code: false })}
				>
					Please use the code <b>{this.state.appCode}</b> in the server machine to configure the application server.
				</SweetAlert>
			: null }

		</React.Fragment>);
	}
}

export default VmsPage;