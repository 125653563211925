import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";


class TopCities extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<CardTitle className="mb-4">
							VM's Assigned
						</CardTitle>
						<div className="table-responsive mt-4">
							<table className="table table-centered table-nowrap mb-2">
								<thead className="thead-light">
									<tr>
										<th>Name / FQDN</th>
										<th>Location</th>
										<th># Cores</th>
										<th>Ram</th>
										<th>Storage</th>
										<th>Template</th>
									</tr>
								</thead>
								<tbody>
									{this.props && this.props.vmInfo && this.props.vmInfo.map((inf, key) => (
										<tr key={"inst_"+key}>
											<td style={{ width: "20%" }} >
												<h5 className="mb-0"><Link to={"/list_instance/"+inf.vmId+"/"+inf.nodeName+"/"}>{inf.name}</Link></h5>
											</td>
											<td style={{ width: "10%" }} >
												<p className="mb-0">{inf.location}</p>
											</td>
											<td style={{ width: "10%" }} >
												<p className="mb-0">{inf.cores}</p>
											</td>
											<td style={{ width: "10%" }} >
												<p className="mb-0">{inf.ram}</p>
											</td>
											<td style={{ width: "10%" }} >
												<p className="mb-0">{inf.stor}</p>
											</td>
											<td style={{ width: "10%" }} >
												<p className="mb-0">{inf.template ? "Yes" : "No"}</p>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default TopCities;
