import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { Row, Col, Media, Card, CardBody, CardTitle, CardSubtitle, Table, Button, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import call_api from "../../components/api-caller";
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import showToast from "../../components/showToast";

class VMDeletionRequests extends Component {

	constructor (props){
		super(props);
		this.state = {
			allVms: [],
			selectedIndex: 0,
			selectedVm: {},
			delete_vm: false,
		};
	}

	componentDidMount(){
		this.get_all_vms();
	}

	async getVmDetails(indx){
		if (this.state.allVms.length <= indx) {
			return
		}
		var query = `query {
			getVmInfoAdmin(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.allVms[indx].vmId}", nodeName: "${this.state.allVms[indx].nodeName}"){
				os
				name
				vmId
				nodeName
				location
				cores
				ram
				netUsage
				status
				pool {
					name
					email
					role
				}
				disks {
					diskName
					diskType
					storage
				}
				typeId
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmInfoAdmin) {
			this.setState({
				selectedVm: data.data.getVmInfoAdmin,
				selectedIndex: indx,
			});
		}
	}

	async get_all_vms() {
		var query = `query {
			getVmDeletionPending(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}"){
				name
				vmId
				nodeName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmDeletionPending && data.data.getVmDeletionPending.length > 0){
			this.setState({
				allVms: data.data.getVmDeletionPending
			}, () => {
				this.getVmDetails(this.state.selectedIndex)
			});
		}
	}

	async confirm_deletion_vm() {
		const query = `mutation {
			confirmDeletionVmAdmin(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.selectedVm.vmId}", nodeName: "${this.state.selectedVm.nodeName}") {
				result
				error
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.confirmDeletionVmAdmin){
			if (data.data.confirmDeletionVmAdmin.result === 1) {
				showToast("VM Deleted Successfully", "The selected VM has been deleted successfully. All the data of the VM still persists", "success");
			} else if (data.data.confirmDeletionVmAdmin.result === -1) {
				showToast("VM Deletion Failed", "The selected VM has not been requested for deletion. Please recheck.", "error")
			} else {
				showToast("VM Deletion Failed", `There is an error deleting the selected VM. ${data.data.confirmDeletionVmAdmin.error}`, "error")
			}
		} else {
			showToast("VM Deletion Failed", "You may not have enough privileges to delete a VM. Please contact your company administrator", "error")
		}
	}
	
	render() {
		function mb_gb_conv(mb) {
			const gb = (mb / 1024).toFixed(2)
			if (gb > 0){
				return `${gb} GB`
			} else {
				return `${mb.toFixed(2)} MB`
			}
		}

		return (
			<React.Fragment>
				<Card>
					<CardHeader>VM Deletion Requests</CardHeader>
					<CardSubtitle className="m-4">VMs' pending for confirmation of deletion</CardSubtitle>
					<CardBody>
						<Col lg="12">
							<div className="d-lg-flex">
								<Col lg="4" md="2" sm="12">
									<div className="chat-leftsidebar mr-lg-4">
										<div className="chat-leftsidebar-nav">
											<ul className="list-unstyled chat-list">
												<PerfectScrollbar style={{ height: "410px" }}>
													{
														(this.state.allVms.length >= 0) ? this.state.allVms.map((vm, idx) =>
															<li key={"User_" + idx} className={this.state.selectedVm.vmId === vm.vmId ? "active" : ""}>
																<Link to="#"  onClick={() => this.getVmDetails(idx)}>
																	<Media>
																		<Media className="overflow-hidden" body>
																			<h5 className="text-truncate font-size-14 mb-1">{vm.name}</h5>
																			<p className="text-truncate mb-0">Node: {vm.nodeName}</p>
																		</Media>
																		<div className="font-size-14">ID: {vm.vmId}</div>
																	</Media>
																</Link>
															</li>
														) : null
													}
												</PerfectScrollbar>
											</ul>
										</div>
									</div>
								</Col>
								<Col lg="8" md="10" sm="12">
									<Card>
										<CardBody>
											<Row>
												<h5 className="col-8">Confirm Deletion of VM.</h5>
												<Button 
													className="btn btn-danger float-right col-4"
													onClick={() => this.setState({delete_vm:true})}
												>
													Delete
												</Button>

											</Row>
										</CardBody>
									</Card>
									<Card>
										<CardBody>
											<CardTitle className="mb-4">VM Information</CardTitle>
											<div className="table-responsive">
												<Table className="table-nowrap mb-0">
													<tbody>
														<tr>
															<th scope="row">VM Name</th>
															<td>:</td>
															<td>{this.state.selectedVm.name}</td>
														</tr>
														<tr>
															<th scope="row">VM ID</th>
															<td>:</td>
															<td>{this.state.selectedVm.vmId}</td>
														</tr>
														<tr>
															<th scope="row">VM Node</th>
															<td>:</td>
															<td>{this.state.selectedVm.nodeName}</td>
														</tr>
														<tr>
															<th scope="row">Status</th>
															<td>:</td>
															<td>{this.state.selectedVm.status}</td>
														</tr>
														<tr>
															<th scope="row">Vm-Type</th>
															<td>:</td>
															<td>
																<AvForm>
																	<AvField
																		type = "select"
																		name="vm_type_data"
																		value={this.state.selectedVm.typeId}
																		disabled
																	>
																		<option key="null" value="null">Select</option>
																		{this.props.vm_types && this.props.vm_types.map((elem) => (
																			<option key = {"vm_type_"+elem.typeId} value={elem.typeId}>
																				{elem.name}
																			</option>
																		))}
																	</AvField>
																</AvForm>
															</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</CardBody>
									</Card>
									<Card>
										<CardBody>
											<CardTitle className="mb-4">VM Configuration</CardTitle>
											<div className="table-responsive">
												<Table className="table-nowrap mb-0">
													<tbody>
														<tr>
															<th scope="row">Memory (GB)</th>
															<td>:</td>
															<td>{this.state.selectedVm.ram}</td>
														</tr>
														<tr>
															<th scope="row">CPU (Cores)</th>
															<td>:</td>
															<td>{this.state.selectedVm.cores}</td>
														</tr>
														<tr>
															<th scope="row">OS</th>
															<td>:</td>
															<td>{this.state.selectedVm.os}</td>
														</tr>
														<tr>
															<th scope="row">Network Usage</th>
															<td>:</td>
															<td>{this.state.selectedVm.netUsage && mb_gb_conv(this.state.selectedVm.netUsage)}</td>
														</tr>
														<tr>
															<th scope="row">Location</th>
															<td>:</td>
															<td>{this.state.selectedVm.location}</td>
														</tr>
														<tr>
															<th scope="row">Disks</th>
															<Table>
																<tbody>
																	{this.state.selectedVm.disks && this.state.selectedVm.disks.map((disk) => {
																		return (
																			<tr>
																				<th>{disk.diskName}</th>
																				<td>{disk.diskType ? "SSD" : "HDD"}</td>
																				<td>{disk.storage}</td>
																			</tr>
																		)
																	})}
																</tbody>
															</Table>
														</tr>
													</tbody>
												</Table>
											</div>
										</CardBody>
									</Card>
									<Card>
										<CardBody>
											<CardTitle className="mb-4">VM Users</CardTitle>
											<div className="table-responsive">
												<Table className="table-nowrap mb-0">
													<tbody>
														<tr>
															<th>Name</th>
															<th>Email</th>
															<th>Role</th>
														</tr>
														{this.state.selectedVm.pool && this.state.selectedVm.pool.map((elem) => {
															return (
																<tr>
																	<td>{elem.name}</td>
																	<td>{elem.email}</td>
																	<td>{elem.role && elem.role.length > 0 && elem.role.join(", ")}</td>
																</tr>
															)
														})}
													</tbody>
												</Table>
											</div>
										</CardBody>
									</Card>
								</Col>
							</div>
						</Col>
					</CardBody>
				</Card>
			<Modal isOpen={this.state.delete_vm} role="dialog" autoFocus={true} className="modal-md" centered={true} tabIndex="-1" toggle={() => {this.setState({delete_vm:false})}}>
				<ModalHeader toggle={() => this.setState({delete_vm:false})}>
					Confirm Deletion of VM
				</ModalHeader >
				<ModalBody>
					Are you sure of deleting the current VM <b>{this.state.selectedVm.name}</b>?
					Deleting the VM will result in completly removing the VM but the details of the VM do persists for billing. <br /> 
					<b>Note:</b>&nbsp;The VM will be charged for the VM till the current Usage.
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-danger btn-block" onClick={() => this.confirm_deletion_vm()}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>);
	}
}

export default VMDeletionRequests;