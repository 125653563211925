import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	isLoggedIn,
	realm,
	email,
	token,
	domain,
	domainChange,
	domainList,
	uniqueVisitorId,
	browserData,
	...rest
}) => 
	(
		<Route
			{...rest}
			render={props => {
				if (isAuthProtected && !localStorage.getItem("authUser") && !isLoggedIn) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}

				return (
					<Layout realm={realm} email={email} token={token} domain={domain} domainChange={domainChange} domainList={domainList} uniqueVisitorId={uniqueVisitorId} browserData={browserData}>
						<Component {...props} realm={realm} email={email} token={token} domain={domain} uniqueVisitorId={uniqueVisitorId} browserData={browserData}/>
					</Layout>
				);
			}}
		/>
	)
// );
export default AppRoute;

