import React, { Component } from "react"
import {
	Button,
	Col,
	Form,
	Input,
	Label,
	Table,
	Row,
} from "reactstrap";
import call_api from "../../components/api-caller";
import showToast from "../../components/showToast";

class VmAppPublishings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			rows: [],
			appServerDetails: {},
		}
	}

	async getVmAppServerDetails() {
		const query = `query {
			getVmAppServerDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}",
			vmId: "${this.props.vm_id}") {
				desktopName, ipAddress, adminUsers
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmAppServerDetails) {
			this.setState({
				appServerDetails: data.data.getVmAppServerDetails,
			});
		}
	}

	async getVmAppPublishings() {
		const query = `query {
			getVmAppTemplatePublishings(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}",
			vmId: "${this.props.vm_id}", nodeName: "${this.props.node_id}", browserFingerPrint: "${this.props.uniqueVisitorId}") {
				name, vmId, nodeName,
				appTemplates {
					name, appSpec, path, startup, cmdline, iconPath, tmpId,
					appSrvCredentials {
						appSrvUsername, appSrvPassword
					},
					maxSessions, mountFolderPath, maxResol
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmAppTemplatePublishings && data.data.getVmAppTemplatePublishings.appTemplates && data.data.getVmAppTemplatePublishings.appTemplates.length > 0) {
			this.setState({
				rows: data.data.getVmAppTemplatePublishings.appTemplates,
			});
		}
	}

	async openApp(ind) {
		const app_publ = this.state.rows[ind];
		const app_param = `${app_publ.appSrvCredentials.appSrvUsername}-`
		const args = ` "${app_param}" "${app_publ.path}" "" "${app_publ.name}" "${app_publ.startup}" "" "${this.state.appServerDetails.adminUsers ? 'true' : 'false'}" `;
		const config = {
			'protocol': 'rdp', 
			'hostname': this.state.appServerDetails.ipAddress, 
			'port': "3389", 
			'username': app_publ.appSrvCredentials.appSrvUsername, 
			'password': app_publ.appSrvCredentials.appSrvPassword, 
			'ignore-cert': 'true', 
			'resize-method': "display-update", 
			"remote-app": "C:\\AppRegs\\app-runner.exe", 
			"remote-app-args": args
		}
		const token = btoa(JSON.stringify(config));
		window.open(`https://webconsole.deepcloud.com/ca-webconsole/?payload=${token}`, '_blank');
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.uniqueVisitorId !== this.props.uniqueVisitorId || prevProps.vm_id !== this.props.vm_id || prevProps.node_id !== this.props.node_id) {
			this.getVmAppPublishings();
		}
	}

	componentDidMount() {
		this.getVmAppServerDetails();
		this.getVmAppPublishings();
	}
	

	render() {
		return (
			<React.Fragment>
				<Table>
					<tbody>
						<tr>
							<th>App-Name</th>
							<th>Open</th>
						</tr>
						{this.state.rows.map((item, idx) => (
							<tr key={idx}>
								<td>
									{item.name}
								</td>
								<td>
									<i className="bx bx-window-open" onClick={() => this.openApp(idx)}></i>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</React.Fragment>
		)
	}
}

export default VmAppPublishings;