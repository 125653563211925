import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import classnames from "classnames";
import CreateInstance from "./instanceCreate";
import ChooseCatalog from "./chooseCatalog";

class InstanceCreate extends Component {
	constructor() {
		super();
		this.state = {
			createInsTab: 2,
		}
	}

	render() {
		var format = new Intl.NumberFormat('en-IN', { 
			style: 'currency', 
			currency: 'INR', 
			minimumFractionDigits: 2, 
		});
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>

						{/* Render Breadcrumbs */}
						<Breadcrumbs title="Instances" breadcrumbItem="Instance Create" />

						<Row>
							<Col lg="12">
								<Nav tabs className="nav-tabs-custom">
									<NavItem>
										<NavLink
											style={{ cursor: "pointer" }}
											className={classnames({
												active: this.state.createInsTab === 1
											})}
											onClick={() => {
												this.setState({
													createInsTab: 1
												});
											}}
										>
											<span className="d-none d-sm-block">Create Your own VM</span>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											style={{ cursor: "pointer" }}
											className={classnames({
												active: this.state.createInsTab === 2
											})}
											onClick={() => {
												this.setState({
													createInsTab: 2
												});
											}}
										>
											<span className="d-none d-sm-block">Choose from Catalog</span>
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={this.state.createInsTab}>
									<TabPane tabId={1} className="p-3">
										<CreateInstance {...this.props} currencyFormat={format}/>
									</TabPane>
									<TabPane tabId={2} className="p-3">
										<ChooseCatalog {...this.props} currencyFormat={format}/>
									</TabPane>
								</TabContent>
							</Col>
						</Row>

					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default InstanceCreate;