import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, Table, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Media, Button, ModalFooter, Alert } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DashedLine from "./SplineArea";
import Moment from 'react-moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation";
import showToast from "../../components/showToast";
import Editable from "react-bootstrap-editable";
import call_api from "../../components/api-caller";
import PortForwardGroup from './port-forward';
import VmAppPublishings from './vmAppPubs';

class InstanceInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			sysInfo: {},
			nodeid: "",
			vmid: "",
			cpu_usage: [],
			mem_usage: [],
			disk_read: [],
			disk_write: [],
			netin: [],
			netout: [],
			snapShots: [],
			show_snap_des: false,
			snap_name: "",
			snap_description: "",
			show_add_group: false,
			add_user_vm: false,
			my_grps: [],
			vm_grps: [],
			show_template_warning: false,
			show_new_clone: false,
			modify_ram: false,
			modify_cpu: false,
			isEditable: false,
			modify_disk: false,
			selectedDisk: null,
			new_disk: false,
			bill_pend: false,
			alert_revert_snap: false,
			snap_name: "",
			delet_inst: false,

			show_port_forward: false,
			port_forwrds: [],

		}
		this.snapshotInstance = this.snapshotInstance.bind(this);
		this.addVmtoGroup = this.addVmtoGroup.bind(this);
		this.addVmtoUser = this.addVmtoUser.bind(this);
		this.convert_vm_to_template = this.convert_vm_to_template.bind(this);
		this.clone_vm_from_template = this.clone_vm_from_template.bind(this);
		this.modifyRamAmount = this.modifyRamAmount.bind(this);
		this.modifyCpuCores = this.modifyCpuCores.bind(this);
		this.ExtendDiskSize = this.ExtendDiskSize.bind(this);
		this.addNewDisk = this.addNewDisk.bind(this);
		this.change_vm_name = this.change_vm_name.bind(this);
		this.getVmPortForwards = this.getVmPortForwards.bind(this);
	}

	async change_vm_name(xhr, validValue, id) {
		var query = `mutation {
			changeVmName(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}", newName: "${validValue}"){
				result
			}
		}`;
		xhr.open("POST", "/api/", true);
		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				const data = JSON.parse(xhr.responseText);
				if (data && data.data && data.data.changeVmName)
					if (data.data.changeVmName.result){
						showToast("VM Name Updated", "The name of the VM has been successfully updated", "success");
					}
					else
						showToast("VM Name Update Failed", "There is an error proceesing the request. Please try again after sometime", "error");
				else
					showToast("VM Name Update Failed", "You may not have enough privileges to modify the VM Name", "error");
			}
		}
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({ "query": query }));
	}

	async removeVmGroup(group_id) {
		var query = `mutation remove_vm_group{
			removeVmGroup(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", groupId: "${group_id}", vmId: "${this.state.vmid}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeVmGroup && data.data.removeVmGroup.result){
			if (data.data.removeVmGroup.result === 1){
				showToast("VM removed from Group", `The VM ${this.state.vmid} has been successfuly removed from the group`, "success");
				this.get_vm_groups();
			}
			else if (data.data.removeVmGroup.result === -1)
				showToast("Cannot remove Group", `The VM ${this.state.vmid} is is not a part of group.`, "warning");
		} else {
			showToast("Failed to remove from Group", `You may not have the privileges to remove the VM from the group`, "error");
		}
	}

	async modifyRamAmount(event, values) {
		var query = `mutation modify_ram{
			modifyRam(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}", newRamSize: ${values.ram_size}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyRam && data.data.modifyRam.result){
			if (data.data.modifyRam.result === 1){
				showToast("RAM amount modified", `Memory size has been successfuly modified for the current VM`, "success");
				this.getVmInfo();
			}
			else if (data.data.modifyRam.result === -1)
				showToast("Cannot modify RAM", `The VM has been already been deleted.`, "warning");
			else if (data.data.modifyRam.result === -2)
				showToast("Cannot modify RAM", `You do not have enough credits to upgrade the VM.`, "warning");
		} else {
			showToast("Failed modifying RAM", `You may not have the privileges to modify VM configuration`, "error");
		}
		this.setState({
			modify_ram: false,
		})
	}

	async modifyCpuCores(event, values) {
		var query = `mutation modify_ram{
			modifyCpu(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}", newCpuCores: ${values.cpu_cores}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.modifyCpu && data.data.modifyCpu.result){
			if (data.data.modifyCpu.result === 1){
				showToast("CPU amount modified", `Number of CPU cores has been changed successfully`, "success");
				this.getVmInfo();
			}
			else if (data.data.modifyCpu.result === -1)
				showToast("Cannot modify CPU cores", `The VM has been already been deleted.`, "warning");
			else if (data.data.modifyCpu.result === -2)
				showToast("Cannot modify CPU cores", `You do not have enough credits to upgrade the VM.`, "warning");
		} else {
			showToast("Failed modifying CPU cores", `You may not have the privileges to modify VM configuration`, "error");
		}
		this.setState({
			modify_cpu: false,
		})
	}

	async removeDisk() {
		var query = `mutation remove_disk{
			removeDisk(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}", diskId: "${this.state.selectedDisk.toLowerCase()}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeDisk && data.data.removeDisk.result){
			if (data.data.removeDisk.result === 1){
				showToast("Disk Removed", `The selected Disk has been removed from the VM.`, "success");
				this.getVmInfo();
			}
			else if (data.data.removeDisk.result === -1)
				showToast("Cannot Remove Disk", `The VM has been already been deleted.`, "warning");
			else if (data.data.removeDisk.result === -2)
				showToast("Cannot Remove Disk", `The selected disk hasn't been attached to the VM.`, "warning");
			else if (data.data.removeDisk.result === -3)
				showToast("Cannot Remove Disk", `You do not have enough credits to upgrade the VM.`, "warning");
		} else {
			showToast("Failed removing disk", `You may not have the privileges to modify VM configuration`, "error");
		}
		this.setState({
			modify_disk: false,
		})
	}

	async addNewDisk(event, values) {
		var query = `mutation create_new_disk{
			createNewDisk(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}", isSsd: ${(values.ssd_check.length > 0)}, size: ${values.disk_size}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createNewDisk && data.data.createNewDisk.result){
			if (data.data.createNewDisk.result === 1){
				showToast("Disk Created", `A new disk has been created and has been attached.`, "success");
				this.getVmInfo();
			}
			else if (data.data.createNewDisk.result === -1)
				showToast("Cannot Create Disk", `The VM has been already been deleted.`, "warning");
			else if (data.data.createNewDisk.result === -2)
				showToast("Cannot Create Disk", `You do not have enough credits to upgrade the VM.`, "warning");
		} else {
			showToast("Failed creating disk", `You may not have the privileges to modify VM configuration`, "error");
		}
		this.setState({
			new_disk: false,
		})
	}

	async ExtendDiskSize(event, values) {
		var query = `mutation extend_disk_size{
			extendDiskSize(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}", diskId: "${this.state.selectedDisk.toLowerCase()}", extendSize: ${values.extend_size}){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.extendDiskSize && data.data.extendDiskSize.result){
			if (data.data.extendDiskSize.result === 1){
				showToast("Disk Size extended", `The selected disk size has been extended for the VM.`, "success");
				this.getVmInfo();
			}
			else if (data.data.extendDiskSize.result === -1)
				showToast("Cannot Extend Disk Size", `The VM has been already been deleted.`, "warning");
			else if (data.data.extendDiskSize.result === -2)
				showToast("Cannot Extend Disk Size", `The selected disk hasn't been attached to the VM.`, "warning");
			else if (data.data.extendDiskSize.result === -3)
				showToast("Cannot Extend Disk Size", `You do not have enough credits to upgrade the VM.`, "warning");
		} else {
			showToast("Failed extending disk size", `You may not have the privileges to modify VM configuration`, "error");
		}
		this.setState({
			modify_disk: false,
		})
	}

	async addVmtoGroup(event, values){
		var query = `mutation add_vm_group{
			addVmToGroup(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", groupId: "${values["choose_group"]}", vmId: "${this.state.vmid}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.addVmToGroup && data.data.addVmToGroup.result){
			if (data.data.addVmToGroup.result === 1){
				showToast("VM added to Group", `The VM ${this.state.vmid} has been successfuly added to the group`, "success");
				this.get_vm_groups();
			}
			else if (data.data.addVmToGroup.result === -1)
				showToast("Cannot add to Group", `The VM ${this.state.vmid} is already assigned to the group`, "warning");
		} else {
			showToast("Failed to add to Group", `You may not have the privileges to add the VM to the group`, "error");
		}
		this.setState({
			show_add_group: false,
		});
	}

	async addVmtoUser(event, values){
		var query = `mutation add_vm_user{
			addVmUser(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail: "${values["user_email"]}", vmId: "${this.state.vmid}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.addVmUser){
			if (data.data.addVmUser.result === 1){
				showToast("VM added to User", `The VM ${this.state.vmid} has been successfuly added to the User`, "success");
				this.getVmInfo();
			}
			else if (data.data.addVmUser.result === -1)
				showToast("Cannot add User", `The VM ${this.state.vmid} is already assigned to the user`, "warning");
			else if (data.data.addVmUser.result === -2)
				showToast("Cannot add User", `No User exists with the entered email`, "warning");
			else 
				showToast("Faile adding user", "There is an error adding user to the VM. Please try again after some time.", "error")
		} else {
			showToast("Failed to add to User", `You may not have the privileges to add the VM to the user`, "error");
		}
		this.setState({
			add_user_vm: false,
		});
	}

	async get_vm_groups() {
		var query = `query get_vm_groups{
			getVmGroups(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}"){
				name
				groupId
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmGroups){
			this.setState({
				vm_grps: data.data.getVmGroups
			});
		}
	}

	get_user_info() {
		if (this.props && this.props.match && this.props.match.params && this.props.match.params.id && this.props.match.params.node){
			var vmid = this.props.match.params.id;
			var nodeid = this.props.match.params.node;
			this.setState({
				nodeid: nodeid,
				vmid: vmid,
			}, () => {
				this.getVmInfo();
				this.getVmUsage();
				this.getSnapShots();
				this.get_vm_groups();
				this.getVmPortForwards();
			});
		}
		else
			this.props.history.push("/");
	}




	async get_user_groups(){
		var query = `query get_group{
			getGroups(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				groupId
				name
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getGroups && data.data.getGroups.length > 0){
			this.setState({
				my_grps: data.data.getGroups,
				show_add_group: true,
			})
		}
	}

	async getVmInfo(){
		var query = `query {
			getVmInfo(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}") {
				name
				location
				ipAddress
				cores
				ram
				stor
				status
				disks {
					diskName
					storage
					diskType
				}
				pool {
					name
					email
					role
				}
				billingStartDate
				billingLastDate
				billingEndDate
				os
				template
				netUsage
				typeId
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmInfo){
			this.setState({
				sysInfo: data.data.getVmInfo,
			}, () => {
				this.setState({
					isEditable: ((this.state.sysInfo.status && this.state.sysInfo.status.toLowerCase() === "running") || (this.state.sysInfo.status && this.state.sysInfo.status.toLowerCase() === "stopped"))
				});
			});
		} else{
			this.setState({
				bill_pend: true
			});
		}
	}


	async getVmPortForwards() {
		var query = `query {
			getVmPortForwards(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}") {
				id
				vmPort
				clientPort
				reverseForward
				userEmail
			}
		}`;
		const data = await call_api(query);

		if (data && data.data && data.data.getVmPortForwards && data.data.getVmPortForwards.length > 0){
			this.setState({
				port_forwrds: data.data.getVmPortForwards,
			});
		}
	}

	async getSnapShots(){
		var query = `query get_snaps{
			getSnapshots(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", nodeName: "${this.state.nodeid}", vmId: "${this.state.vmid}") {
				snapName
				createdTime
				description
				current
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getSnapshots && data.data.getSnapshots.length > 0){
			this.setState({
				snapShots: data.data.getSnapshots,
			});
		}
	}

	async StopInstance() {
		var query = `mutation {
			stopVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
			  result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.stopVm){
			if (data.data.stopVm && data.data.stopVm.result === 1){
				showToast("VM stopped successfully", "VM '"+this.state.vmid+"' has been stopped successfully", "success");
				this.getVmInfo();
			} else {
				showToast("VM stopping failed", "VM '"+this.state.vmid+"' stop service has been failed", "error");
			}
		}
	}

	async StartStopInstance(){
		var query = this.state.sysInfo.status.toLowerCase()  === "stopped" ? `mutation start_instance{
			startVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
				result
			}
		}` : `mutation stop_instance{
			shutdownVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
			  result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && (data.data.startVm || data.data.shutdownVm)){
			if ((data.data.startVm && data.data.startVm.result === 1) || (data.data.shutdownVm && data.data.shutdownVm.result === 1)){
				showToast("VM "+(this.state.sysInfo.status.toLowerCase()  === "stopped" ? "started" : "shut down")+" successfully", "VM '"+this.state.vmid+"' has been "+(this.state.sysInfo.status.toLowerCase()  === "stopped" ? "started" : "shut down")+" successfully", "success");
				this.getVmInfo();
			} else if (data.data.shutdownVm && data.data.shutdownVm.result === -1){
				showToast("Failed shutting down VM", "Guest Agent is not running in the VM. Please try stopping the VM instead.", "error");
			} else {
				showToast("VM "+(this.state.sysInfo.status.toLowerCase()  === "stopped" ? "started" : "shut down")+" failed", "VM '"+this.state.vmid+"' "+(this.state.sysInfo.status.toLowerCase()  === "stopped" ? "start" : "shut down")+" service has been failed", "error");
			}
		} else {
			showToast("VM "+(this.state.sysInfo.status.toLowerCase()  === "stopped" ? "started" : "shut down")+" failed", "You may not have enough permissions to '"+this.state.vmid+"' "+(this.state.sysInfo.status.toLowerCase()  === "stopped" ? "start" : "shut down")+" the VM ", "error");
		}
	}

	async clone_vm_from_template(event, values) {
		var query = `mutation clone_template{
			cloneVmTemplate(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}", vmName: "${values["clone_name"]}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.cloneVmTemplate && data.data.cloneVmTemplate.result){
			if (data.data.cloneVmTemplate.result === 2) {
				showToast("Clone Successful", "New VM has been created from the template VM", "success");
			} else if (data.data.cloneVmTemplate.result === 2) {
				showToast("Clone Successful", "New VM has been created from the template VM. User has not been given the privilege to acces the VM", "warning");
			}
		} else {
			showToast("Clone Failed", "NeYou may not have privilege for creating the VM from the template", "error");
		}
		this.setState({
			show_new_clone: false,			
		})
	}

	async getVmUsage() {
		var query = `query getvm_usage{
			getVmUsage(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
				cpu {
					usage
					time
				}
				diskRead {
					usage
					time
				}
				diskWrite {
					usage
					time
				}
				mem {
					usage
					time
				}
				netin {
					usage
					time
				}
				netout {
					usage
					time
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmUsage){
			var usg_stat = data.data.getVmUsage;
			if (usg_stat.cpu && usg_stat.cpu.length > 0){
				this.setState({
					cpu_usage: usg_stat.cpu
				});
			}
			if (usg_stat.mem && usg_stat.mem.length > 0){
				this.setState({
					mem_usage: usg_stat.mem
				});
			}
			if (usg_stat.diskRead && usg_stat.diskRead.length > 0){
				this.setState({
					disk_read: usg_stat.diskRead
				});
			}
			if (usg_stat.diskWrite && usg_stat.diskWrite.length > 0){
				this.setState({
					disk_write: usg_stat.diskWrite
				});
			}
			if (usg_stat.netin && usg_stat.netin.length > 0){
				this.setState({
					netin: usg_stat.netin
				});
			}
			if (usg_stat.netout && usg_stat.netout.length > 0){
				this.setState({
					netout: usg_stat.netout
				});
			}
		}
	}

	async rebootInstance(){
		var query =`mutation reboot_instance{
			rebootVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.rebootVm){
			if (data.data.rebootVm.result === 1){
				showToast("VM rebooted successfully", "VM '"+this.state.vmid+"' has been restarted successfully", "success");
				this.getVmInfo();
			} else {
				showToast("VM rebooted failed", "VM '"+this.state.vmid+"' restart service has been failed", "error");
			}
		}
	}

	async reloadNetworkUsage() {
		const query = `mutation {
			reloadNetUsage(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.reloadNetUsage){
			if (data.data.reloadNetUsage.result){
				showToast("Network usage reloaded", "VM '"+this.state.vmid+"' network usage has been successfully reloaded", "success");
				this.getVmInfo();
			} else {
				showToast("Network usage reload failed", "There has been an error reloading the network usage. Please try again after some time.", "error");
			}
		} else {
			showToast("Network usage reload failed", "Make sure you have enough privileges and VM is active", "error");
		}
	}

	async snapshotInstance(){
		var query = `mutation create_snap{
			createSnapshot(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}", snapName: "${this.state.snap_name}", description: "${this.state.snap_description}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.createSnapshot){
			if (data.data.createSnapshot.result === 1){
				showToast("Snap-Shot created successfully", "Snap-shot for VM '"+this.state.vmid+"' has been created successfully", "success");
				this.getVmInfo();
				this.getSnapShots();
			} else {
				showToast("Snap-Shot create failed", "Snap-shot for VM '"+this.state.vmid+"' couldn't be created", "error");
			}
		}
		this.setState({
			show_snap_des: false
		});
	}

	async RollbackInstance(snap_name){
		var query = `mutation rollback_snap{
			rollbackSnapshot(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}", snapName: "${snap_name}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.rollbackSnapshot){
			if (data.data.rollbackSnapshot.result === 1){
				showToast("Snap-Shot rollback successful", "Snap-shot for VM '"+this.state.vmid+"' has been rolled-back successfully", "success");
			} else {
				showToast("Snap-Shot rollback failed", "Snap-shot for VM '"+this.state.vmid+"' couldn't be rolled-back", "error");
			}
		} else {
			showToast("VM rollback failed", "You may not have enough permissions to rollback the VM to a specific snapshot ", "error");
		}
	}

	askDeleteInstance() {
		this.setState({
			delet_inst: true,
		})
	}

	async deleteInstance(){
		this.setState({
			delet_inst: false,
		})
		if (this.state.sysInfo.status !== "running"){
			var query =`mutation {
				deleteVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
					result
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.deleteVm){
				if (data.data.deleteVm.result === 1){
					showToast("VM deleted successfully", "VM '"+this.state.vmid+"' has been deletd successfully. The information still persists though VM isn't available", "success");
					this.getVmInfo();
				} else {
					showToast("VM rebooted failed", "VM '"+this.state.vmid+"' delete has been failed. You may not have enough privileges to delete the VM", "error");
				}
			} else {
				showToast("VM deletion failed", "You may not have enough permissions to delete the VM", "error");
			}
		} else {
			showToast("VM running", "VM Instance is running. Cannot delete VM while running. Please stop the VM", "warning");
		}
	}

	componentDidMount(){
		this.get_user_info();
		document.addEventListener('contextmenu', event => event.preventDefault());
	}

	async convert_vm_to_template() {
		var query = `mutation convert_to_template{
			convertVmToTemplate(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
				result
				error
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.convertVmToTemplate){
			if (data.data.convertVmToTemplate.result){
				showToast("Template Created Successfully", "The selected VM has been converted to template. You now can clone Machines from the template", "success");
				this.setState({
					show_template_warning: false,
				});
				this.getVmInfo();	
			} else {
				showToast("Template Created Failed", data.data.convertVmToTemplate.error, "error");
			}
		} else {
			showToast("Template Created Failed", "You may not have enough privileges to convert VM to template", "error");
		}
	}

	async removeUserVM(user_email){
		var query = `mutation remove_user_vm{
			removeUserVm(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}", userEmail: "${user_email}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.removeUserVm){
			if (data.data.removeUserVm.result){
				showToast(
					"User Removed",
					"The selected user has been removed from the VM users list",
					"success",
				);
				this.getVmInfo();	
			} else {
				showToast(
					"Failed removing User",
					"There has been an error removing the user. Please try again after some time",
					"error",
				);
			}
		} else {
			showToast(
				"Failed removing User",
				"You may not have enough privileges to remove user from the VM users.",
				"error",
			);
		}
	}

	async getVncViewer(){
		if (this.state.sysInfo.status && this.state.sysInfo.status.toLowerCase() === "running"){
			var query =`query get_vnc{
				vncViewer(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", vmId: "${this.state.vmid}", nodeName: "${this.state.nodeid}"){
					payload
				}
			}`;
			const data = await call_api(query);
			if (data && data.data && data.data.vncViewer){
				if (data.data.vncViewer.payload) {
					const url = `https://console.deepcloud.in/vnc.html?payload=${data.data.vncViewer.payload}`;
					window.open(url, '_blank', "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,fullscreen=yes");
					// if (data.data.vncViewer.port) {
					// 	var url = `${data.data.vncViewer.consoleEndpoint}?console=kvm&vmid=${this.state.vmid}&node=${this.state.nodeid}&ticket=${encodeURIComponent(data.data.vncViewer.ticket)}&resize=scale`;
					// } else {
					// 	try{
					// 		const out = JSON.parse(atob(data.data.vncViewer.ticket));
					// 		var url = `https://console2.cloudadda.com/console/?__EVENTTARGET=&__EVENTARGUMENT=&server=192.168.77.39%3A2179&&user=console&passwordHash=01000000D08C9DDF0115D1118C7A00C04FC297EB01000000AC9D971E95102C4DA16F57F7778F3B8004000000020000000000106600000001000020000000CAC021BC912620C27A10D02463ACA355B6379546C06CDA87B069FD19CF00941A000000000E8000000002000020000000AFD84D01E53A487842E38F7C6E2CACD82EE7595DEB894B7FC477C0CE6A56F6A020000000AB20B4EECA76E1ABE956F7F19A0AD9DD0273A557C8F7D2AC63F7974BC8FDAB1B40000000DC8CF8B8B8CB28A4AAEAA2E4DD9629EDB458A60E0C393ECA22B870E400BB1AEF5B3EA352AA9479F8B4EDE5C1CD2287B640586E23EB2880EBF650F2B7030FF630&connect=Connect%21&vmGuid=${out.pcb}&vmEnhancedMode=checked`;
					// 		window.open(url);
					// 	} catch (err) {
					// 		console.log(err);
					// 	}
					// }	
				}
			} else {
				showToast("Couldn't Open Console", "You may not have enough permissions to open the console for the VM", "warning");
			}
		} else {
			showToast("VM powered Off", "Couldn't open console when the VM is powered off", "warning");
		}
	}

	render() {
		function mb_gb_conv(mb) {
			const gb = (mb / 1024).toFixed(2)
			if (gb > 0){
				return `${gb} GB`
			} else {
				return `${mb.toFixed(2)} MB`
			}
		}
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Instances" breadcrumbItem="INFO" />
						<Row>
							<Col xl="4">
								<Card>
									<CardBody>
										<CardTitle className="mb-4">System Inofrmation</CardTitle>
										{
											((this.state.sysInfo.status && this.state.sysInfo.status.toLowerCase() === "running") || (this.state.sysInfo.status && this.state.sysInfo.status.toLowerCase() === "stopped")) ? (
												<ul className="list-inline font-size-20 contact-links mb-2">
													<li className="list-inline-item px-2">
														<Link to="#" id={"console"} onClick={() => {this.getVncViewer()}}>
															<i className="mdi mdi-monitor"></i>
															<UncontrolledTooltip placement="top" target={"console"}>
																Console
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"start_shutdown"} onClick={() => {this.StartStopInstance()}}>
															<i className="mdi mdi-power"></i>
															<UncontrolledTooltip placement="top" target={"start_shutdown"}>
																VM {this.state.sysInfo.status.toLowerCase() === "stopped" ? "Start" : "Shutdown"}
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"start_stop"} onClick={() => {this.StopInstance()}}>
															<i className="mdi mdi-stop"></i>
															<UncontrolledTooltip placement="top" target={"start_stop"}>
																VM Stop
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"restart"} onClick={() => {this.rebootInstance()}}>
															<i className="mdi mdi-refresh-circle"></i>
															<UncontrolledTooltip placement="top" target={"restart"}>
																VM Restart
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"reinstall"} style={{pointerEvents: "none"}}>
															<i className="mdi mdi-backup-restore"></i>
															<UncontrolledTooltip placement="top" target={"reinstall"}>
																VM Reinstall
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"destroy"} onClick={() => {this.askDeleteInstance()}}>
															<i className="mdi mdi-delete-alert"></i>
															<UncontrolledTooltip placement="top" target={"destroy"}>
																Server Destroy
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"snap_shot"} onClick={() => this.setState({show_snap_des: true})}>
															<i className="mdi mdi-camera-metering-center"></i>
															<UncontrolledTooltip placement="top" target={"snap_shot"}>
																Take Snap-Shot
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"add_group"} onClick={() => this.get_user_groups()}>
															<i className="mdi mdi-book-account"></i>
															<UncontrolledTooltip placement="top" target={"add_group"}>
																Add to Group
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"template_vm"} onClick={() => this.setState({show_template_warning: true})}>
															<i className="mdi mdi-album"></i>
															<UncontrolledTooltip placement="top" target={"template_vm"}>
																Convert VM to Template 
															</UncontrolledTooltip>
														</Link>
													</li>
													<li className="list-inline-item px-2">
														<Link to="#" id={"new_disk"} onClick={() => this.setState({new_disk: true})}>
															<i className="bx bx-add-to-queue"></i>
															<UncontrolledTooltip placement="top" target={"new_disk"}>
																Add new Disk
															</UncontrolledTooltip>
														</Link>
													</li>													
												</ul>
	
											) : (this.state.sysInfo.status && this.state.sysInfo.status.toLowerCase() === "template") ?
												<ul className="list-inline font-size-20 contact-links mb-2">
													<li className="list-inline-item px-2">
														<Link to="#" id={"clone_vm"} onClick={() => this.setState({show_new_clone: true})}>
															<i className="mdi mdi-archive-arrow-up-outline"></i>
															<UncontrolledTooltip placement="top" target={"clone_vm"}>
																Create Clone VM
															</UncontrolledTooltip>
														</Link>
													</li>
												</ul>
												: null
										}
										<p className="text-muted mb-4">{this.state.sysInfo && this.state.sysInfo.os}</p>
										{this.state.bill_pend && <Alert color="danger">Please proceed with VM billing in order to continue the usage of the VM</Alert>}
										<div className="table-responsive">
											<Table className="table-nowrap mb-0">
												<tbody>
													<tr>
														<th scope="row">Status :</th>
														<td>{this.state.sysInfo.status}</td>
													</tr>
													<tr>
														<th scope="row">Instance Name :</th>
														<td>
															<Editable
																editText={<i class="bx bx-edit" style={{fontSize: 18}}></i>}
																label={null}
																mode="inline"
																ajax={this.change_vm_name}
																validate={(value) => {
																	if(value.length === 0)
																		return "Enter a valid VM Name"
																}}
																placement="top"
																showText
																type="textfield"
																initialValue={this.state.sysInfo.name}
															/>
														</td>
													</tr>
													<tr>
														<th scope="row">IP Address:</th>
														<td>{this.state.sysInfo.ipAddress}</td>
													</tr>
													<tr>
														<th scope="row" style={{cursor: "pointer"}} onClick={() => this.setState({show_port_forward: true})}>Show Port Forwards</th>
													</tr>
													<tr>
														<th scope="row">Network Usage:</th>
														<td>
															{this.state.sysInfo.netUsage && mb_gb_conv(this.state.sysInfo.netUsage)}
															<Link to="#" id={"reload_net_usage"} className="ml-4 font-size-20" onClick={() => this.reloadNetworkUsage()}>
																<i className="mdi mdi-cloud-refresh"></i>
																<UncontrolledTooltip placement="top" target={"reload_net_usage"}>
																	Reload Network Usage
																</UncontrolledTooltip>
															</Link>

														</td>
													</tr>
													{/* <tr>
														<th scope="row">Server Node :</th>
														<td>{this.props.match.params.node}</td>
													</tr> */}
													<tr>
														<th scope="row">Server Cluster :</th>
														<td>{this.state.sysInfo.location}</td>
													</tr>
													<tr>
														<th scope="row">CPU :</th>
														<td>{this.state.sysInfo.cores} Cores</td>
														<td>{this.state.isEditable && <i class="bx bx-message-square-edit" style={{fontSize: "18px"}} onClick={() => {
															if (this.state.sysInfo.status.toLowerCase() === "stopped")
																this.setState({modify_cpu: true})
															else
																showToast("Failed changing Configuration", "Cannot change configuration when VM is powered on. Please power off the machine and try mhanging.", "warning")
														}}></i>}</td>
													</tr>
													<tr>
														<th scope="row">RAM :</th>
														<td>{this.state.sysInfo.ram} GB</td>
														<td>{this.state.isEditable && <i class="bx bx-message-square-edit" style={{fontSize: "18px"}} onClick={() => {
															if (this.state.sysInfo.status.toLowerCase() === "stopped")
																this.setState({modify_ram: true})
															else
																showToast("Failed changing Configuration", "Cannot change configuration when VM is powered on. Please power off the machine and try mhanging.", "warning")
														}}></i>}</td>
													</tr>
													{
														this.state.sysInfo.disks && this.state.sysInfo.disks.map((disk, ind) => (
															<tr key={"Disk_"+ind}>
																<th scope="row">Storage {disk.diskName} - ({disk.diskType ? "SSD" : "HDD"}) :</th>
																<td>{disk.storage} GB</td>
																<td>{this.state.isEditable && <i class="bx bx-message-square-edit" style={{fontSize: "18px"}} onClick={() => {this.setState({selectedDisk: disk.diskName}, () => {this.setState({modify_disk: true})})} }></i>}</td>
															</tr>
	
														))
													}
													<tr>
														<th scope="row">Billing Start Date :</th>
														<td>{this.state.sysInfo.billingStartDate}</td>
													</tr>
													<tr>
														<th scope="row">Last Billing Date :</th>
														<td>{this.state.sysInfo.billingLastDate}</td>
													</tr>
													<tr>
														<th scope="row">End Billing Date :</th>
														<td>{this.state.sysInfo.billingEndDate}</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</CardBody>
								</Card>
								<Card>
									<CardBody>
										<CardTitle className="mb-5">Snap-Shots</CardTitle>
										<div className="">
											<ul className="verti-timeline list-unstyled">
												{
													this.state.snapShots.map((snap, i) =>
														<li className={snap.current ? "event-list active" : "event-list"} key={"_exp_" + i} >
															<div className="event-timeline-dot">
																<i className={snap.current ? "bx bx-right-arrow-circle bx-fade-right" : "bx bx-right-arrow-circle"}></i>
															</div>
															<Media>
																<div className="mr-3">
																	{!snap.current ?
																	(<Link to="#" id={"roll_bak_"+i} onClick={() => this.setState({alert_revert_snap: true, snap_name: snap.snapName})}>
																		<i className="mdi mdi-file-restore-outline h4 text-primary"></i>
																		<UncontrolledTooltip placement="top" target={"roll_bak_"+i}>
																			Rollback Snap-Shot
																		</UncontrolledTooltip>
																	</Link>) : null }
																</div>
																<Media body>
																	<div>
																		<h5 className="font-size-15"><Link className="text-dark">{snap.snapName}</Link></h5>
																		<p className="font-size-12"><Link className="text-dark">{snap.description}</Link></p>
																		<span className="text-primary"><Moment format="Do MMMM YYYY - HH:mm">{snap.createdTime}</Moment></span>
																	</div>
																</Media>
															</Media>
														</li>
													)
												}

											</ul>
										</div>

									</CardBody>
								</Card>
								<Card>
									<CardBody>
										<CardTitle className="mb-5">Assigned-Groups</CardTitle>
										<div className="">
											<ul className="list-unstyled">
												{
													this.state.vm_grps.map((grp, i) =>
														<li className={"event-list"} key={grp.groupId} >
															<Media>
																<div className="mr-3">
																	<Link to="#" id={"remove_grp"+i} onClick={() => this.removeVmGroup(grp.groupId)}>
																		<i className="mdi mdi-account-multiple-remove h4 text-primary"></i>
																		<UncontrolledTooltip placement="top" target={"remove_grp"+i}>
																			Remove VM from Group
																		</UncontrolledTooltip>
																	</Link>
																</div>
																<Media body>
																	<div>
																		<h5 className="font-size-15"><Link className="text-dark">{grp.name}</Link></h5>
																		<span className="text-primary">All Users of this group can view the VM</span>
																	</div>
																</Media>
															</Media>
														</li>
													)
												}
											</ul>
										</div>

									</CardBody>
								</Card>
								{
									this.state.sysInfo.typeId === 3 ?
										<Card>
											<CardBody>
												<CardTitle className="mb-5">App Publishing</CardTitle>
												<VmAppPublishings {...this.props} vm_id={this.state.vmid} node_id={this.state.nodeid}/>
											</CardBody>
										</Card> 
									: null
								}
							</Col>

							<Col xl="8">
								<Row>
									<Col lg="6">
										<Card>
											<CardBody>
												<CardTitle className="mb-4"> CPU Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.cpu_usage.map((obj => {return obj.time})),
													series: [
														{
															name: "Cpu",
															data: this.state.cpu_usage.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
									<Col>
										<Card>
											<CardBody>
												<CardTitle className="mb-4"> DISK IO Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.disk_read.map((obj => {return obj.time})),
													series: [
														{
															name: "Disk Read",
															data: this.state.disk_read.map((obj => {return obj.usage}))
														},
														{
															name: "Disk Write",
															data: this.state.disk_write.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row>
									<Col lg="6">
										<Card>
											<CardBody> ?
												<CardTitle className="mb-4"> Memory Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.mem_usage.map((obj => {return obj.time})),
													series: [
														{
															name: "RAM",
															data: this.state.mem_usage.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
									<Col>
										<Card>
											<CardBody>
												<CardTitle className="mb-4"> Network IO Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.netin.map((obj => {return obj.time})),
													series: [
														{
															name: "Network IN",
															data: this.state.netin.map((obj => {return obj.usage}))
														},
														{
															name: "Network OUT",
															data: this.state.netout.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
								</Row>

								<Card>
									<CardBody>
										<CardTitle className="mb-4">
											<div className="mr-3">
												User POOL
												<Link to="#" className="ml-4" id={"add_user_vm"} onClick={() => {this.setState({add_user_vm: true})}}>
													<i className="mdi mdi-account-multiple-plus-outline h4 text-primary"></i>
													<UncontrolledTooltip placement="top" target={"add_user_vm"}>
														Add User to the VM
													</UncontrolledTooltip>
												</Link>
											</div>
										</CardTitle>
										<div className="table-responsive">
											<Table className="table table-nowrap table-hover mb-0">
												<thead>
													<tr>
														<th scope="col">Name</th>
														<th scope="col">Email</th>
														<th scope="col">Role</th>
														<th scope="col">Logs</th>
														<th scope="col">Remove</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.sysInfo.pool && this.state.sysInfo.pool.filter((elem) => {return elem.email !== this.props.email}).map((user, indx) => (
															<tr key={"pool_"+indx}>
																<th scope="row">{user.name}</th>
																<td>{user.email}</td>
																<td>{user.role && user.role.join()}</td>
																<td><Link to="#">Logs</Link></td>
																<td><i class="mdi mdi-delete" style={{fontSize: "18px", cursor: "pointer"}} onClick={() => {this.removeUserVM(user.email)}}/></td>
															</tr>	
														))
													}
												</tbody>
											</Table>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Modal isOpen={this.state.alert_revert_snap} role="dialog" autoFocus={true} centered={true} className="modal-md" tabIndex="-1">
							<div className="modal-content">
								<ModalHeader toggle={() => this.setState({alert_revert_snap: !this.state.alert_revert_snap})}>
									Confirm Revert Snapshot 
								</ModalHeader >
								<ModalBody>
									Are you sure of reverting machine <b>{this.state.sysInfo.name}</b> to snapshot <b>{this.state.snap_name}</b>?
									Reverting to the snapshot would delete all the current data and this action is irreversible.
								</ModalBody>
								<ModalFooter>
									<Button className="btn btn-danger btn-block" onClick={() => this.RollbackInstance(this.state.snap_name)}>
										Revert
									</Button>
								</ModalFooter>
							</div>
						</Modal>
						
						<Modal isOpen={this.state.delet_inst} role="dialog" autoFocus={true} centered={true} className="modal-md" tabIndex="-1">
							<div className="modal-content">
								<ModalHeader toggle={() => this.setState({delet_inst: !this.state.delet_inst})}>
									Confirm Delete Instance 
								</ModalHeader >
								<ModalBody>
									Are you sure of deleting the current VM <b>{this.state.sysInfo.name}</b>? Deleting the VM will result in completly removing the VM but the details of the VM do persists for billing. <br /> <b>Note:</b>&nbsp;You will be charged for the VM till the current Usage.
								</ModalBody>
								<ModalFooter>
									<Button className="btn btn-danger btn-block" onClick={() => {this.deleteInstance()}}>
										Destroy
									</Button>
								</ModalFooter>
							</div>
						</Modal>
						{this.state.show_snap_des ? (
							<SweetAlert
								showCancel
								title="Create Snap Shot"
								showConfirm={false}
								cancelBtnBsStyle="danger"
								onCancel={() => this.setState({ show_snap_des: false })}
							>
								<AvForm className="needs-validation mt-4" onValidSubmit={this.snapshotInstance}>
									<Row>
										<Col lg="12" md="12" >
											<AvField
												name="snap_name"
												placeholder="Snap Shot Name"
												label="Snap-Shot Name"
												type="text"
												errorMessage="Name cannor be empty"
												className="form-control"
												validate={{ required: { value: true } }}
												id="name_snap"
												onChange={(event) => this.setState({snap_name: event.target.value})}
											/>
										</Col>
									</Row>
									<Row>
										<Col lg="12" md="12" >
											<AvField
												name="snap_desc"
												placeholder="Enter description for snap-shot"
												label="Description"
												type="text"
												className="form-control"
												id="desc_snap"
												onChange={(event) => this.setState({snap_description: event.target.value})}
											/>
										</Col>
									</Row>
									<Row>
										<Col lg="12" md="12">
											<Button
												type="submit"
												color="success"
												className="btn btn-success btn-blo	ck waves-effect waves-light"
											>
												Submit
											</Button>
										</Col>
									</Row>
								</AvForm>
							</SweetAlert>
						) : null}
						{this.state.show_template_warning ?
							<SweetAlert
								showCancel
								title="Convert to Template"
								cancelBtnBsStyle="danger"
								onCancel={() => this.setState({ show_template_warning: false })}
								onConfirm={this.convert_vm_to_template}
							>
								Are you Sure you would like to convert the VM to template?<br />
								You would only be able to clone from the template<br />
								<b>Note: </b> The VM would be inaccessible for normal usage.
							</SweetAlert>
						: null}
						<Modal
							size="md"
							isOpen={this.state.show_add_group}
							toggle={() =>
								this.setState({ show_add_group: !this.state.show_add_group })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ show_add_group: !this.state.show_add_group }) }
							>
								Add the VM to group
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.addVmtoGroup}>
									<Row>
										<Col lg={12}>
											<AvField
												name="choose_group"
												type="select"
												required
											>
												<option key={"grp_"} value={"null"}>
													Select
												</option>
												{
													this.state.my_grps.map((grp, indx) => (
														<option key={"grp_"+indx} value={grp.groupId}>
															{grp.name}
														</option>
													))
												}

											</AvField>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<b>Note: </b>Upon adding the VM to the group all users of the Group will have access to view the VM
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Add-Group
												</button>
											</div>
										</Col>
								</Row>

								</AvForm>

							</ModalBody>
						</Modal>
						<Modal
							size="md"
							isOpen={this.state.show_new_clone}
							toggle={() =>
								this.setState({ show_new_clone: !this.state.show_new_clone })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ show_new_clone: !this.state.show_new_clone }) }
							>
								Create New Clone
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.clone_vm_from_template}>
									<Row>
										<Col lg={12}>
											<AvField
												name="clone_name"
												label="Clone VM Name"
												placeholder="Name for the New VM"
												helpMessage="Plase give the name for the new VM"
												type="text"
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<b>Note: </b>A new VM will be created from the template VM with same hardware configuration
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Create
												</button>
											</div>
										</Col>
								</Row>
								</AvForm>
							</ModalBody>
						</Modal>						
						<Modal
							size="md"
							isOpen={this.state.add_user_vm}
							toggle={() =>
								this.setState({ add_user_vm: !this.state.add_user_vm })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ add_user_vm: !this.state.add_user_vm }) }
							>
								Add User to VM
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.addVmtoUser}>
									<Row>
										<Col lg={12}>
											<AvField
												name="user_email"
												required
												label="User Email"
												placeholder="Email of the User"
												helpMessage="Plase give the email of the user you would like to add"
												type="email"
											/>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<b>Note: </b>Upon adding the VM to the user, they will have access to view the VM
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Add-User
												</button>
											</div>
										</Col>
								</Row>

								</AvForm>

							</ModalBody>
						</Modal>
						<Modal
							size="md"
							isOpen={this.state.modify_ram}
							toggle={() =>
								this.setState({ modify_ram: !this.state.modify_ram })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ modify_ram: !this.state.modify_ram }) }
							>
								Edit RAM size
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.modifyRamAmount}>
									<Row>
										<Col lg={12}>
											<AvField
												name="ram_size"
												label="Memory"
												placeholder="Enter RAM size"
												helpMessage="Amount of Memory in GB"
												type="number"
												required
												validate = {{
													max: {
														value: 256
													},
													min: {
														value: 1
													}
												}}
											/>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Modify
												</button>
											</div>
										</Col>
								</Row>
								</AvForm>
							</ModalBody>
						</Modal>
						<Modal
							size="md"
							isOpen={this.state.modify_cpu}
							toggle={() =>
								this.setState({ modify_cpu: !this.state.modify_cpu })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ modify_cpu: !this.state.modify_cpu }) }
							>
								Edit CPU Cores
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.modifyCpuCores}>
									<Row>
										<Col lg={12}>
											<AvField
												name="cpu_cores"
												label="Cores"
												placeholder="Enter number of cores"
												helpMessage="Number of CPU cores"
												type="number"
												required
												validate = {{
													max: {
														value: 24
													},
													min: {
														value: 1
													}
												}}
											/>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Modify
												</button>
											</div>
										</Col>
								</Row>
								</AvForm>
							</ModalBody>
						</Modal>
						<Modal
							size="md"
							isOpen={this.state.modify_disk}
							toggle={() =>
								this.setState({ modify_disk: !this.state.modify_disk })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ modify_disk: !this.state.modify_disk }) }
							>
								Resize-Disk
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.ExtendDiskSize}>
									<Row>
										<Col lg={12}>
											<AvField
												name="extend_size"
												label="Re-Size"
												placeholder="Amount of extra space needed"
												helpMessage="Enter the extra space in GB"
												type="number"
												required
												validate = {{
													max: {
														value: 2048
													},
													min: {
														value: 1
													}
												}}
											/>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right mr-3">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Modify
												</button>
											</div>
										</Col>
								</Row>
								</AvForm>
								<ModalFooter>
									<div className="text-right">
										<Button
											className="btn btn-primary btn-danger"
											onClick={() => {this.removeDisk()}}
										>
										Delete
										</Button>
									</div>

								</ModalFooter>
							</ModalBody>
						</Modal>
						<Modal
							size="md"
							isOpen={this.state.new_disk}
							toggle={() =>
								this.setState({ new_disk: !this.state.new_disk })
							}
						>
							<ModalHeader
								toggle={ () => this.setState({ new_disk: !this.state.new_disk }) }
							>
								Create New Disk
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.addNewDisk}>
									<Row>
										<Col lg={12}>
											<AvField
												name="disk_size"
												label="Size of Disk"
												placeholder="Amount of space needed"
												helpMessage="Enter the space in GB"
												type="number"
												required
												validate = {{
													max: {
														value: 6144
													},
													min: {
														value: 1
													}
												}}
											/>
											<AvCheckboxGroup name="ssd_check">
												<AvCheckbox label="SSD" value="is_ssd" />
											</AvCheckboxGroup>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<div className="text-right mr-3">
												<button
													type="submit"
													className="btn btn-primary"
												>
												Create
												</button>
											</div>
										</Col>
								</Row>
								</AvForm>
							</ModalBody>
						</Modal>
						<Modal isOpen={this.state.show_port_forward} role="dialog" autoFocus={true} className="modal-lg" centered={true} tabIndex="-1" toggle={() => {this.setState({show_port_forward:false})}}>
							<ModalHeader toggle={() => {this.setState({show_port_forward:false})}}>
								Manage Port Forwards
							</ModalHeader >
							<ModalBody>
								<PortForwardGroup portLists={this.state.port_forwrds} vmid={this.state.vmid} nodeid={this.state.nodeid} email={this.props.email} token={this.props.token} realm={this.props.realm} domain={this.props.domain} reloader={this.getVmPortForwards}/>
							</ModalBody>
						</Modal>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default InstanceInfo;