import React, { Component } from "react"
import {
	Col,
	Container,
	Row
} from "reactstrap"

class DownloadsPage extends Component {
	constructor(props) {
		super(props)
		this.state = { 
			files: [],
		}
	}

	getDownloads() {
		fetch("/downloads/files.json").then(resp => {
			resp.json().then(data => {
				this.setState({
					files: data
				});
			})
		})
	}

	componentDidMount() {
		this.getDownloads();
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content pt-4">
					<Container>
						<Row className="justify-content-center">
							<h1>Downloads Page</h1>
						</Row>
						<Row className="justify-content-center mt-4 py-4"  style={{backgroundColor: "white"}}>
							<Col lg="8" md="12" sm="12">
								<Row>
									<Col lg="8" md="6" sm="6">
										<h3>Download Title</h3>
									</Col>
									<Col lg="4" md="6" sm="6">
										<h3>Link</h3>
									</Col>
								</Row>
								<hr />
								{this.state.files.map((elem) => 
									<Row className="mt-2">
										<Col lg="8" md="6" sm="6">
											<h4>{elem.name}</h4>
										</Col>
										<Col lg="4" md="6" sm="6">
											<a href={elem.link}>Download</a>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}
export default DownloadsPage;
