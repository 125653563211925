import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";

import logo from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//i18n
import { withNamespaces } from 'react-i18next';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			isSearch: false,
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleRightbar = this.toggleRightbar.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);
		this.toggleSearch = this.toggleSearch.bind(this);
	}

	toggleSearch = () => {
		this.setState({ isSearch: !this.state.isSearch });
	}

	toggleMenu() {
		this.props.openLeftMenuCallBack();
	}

	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			/* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<header id="page-topbar">
					<div className="navbar-header">
						<div className="d-flex">
							<div className="navbar-brand-box">
								<Link to="/" className="logo logo-dark">
									<span className="logo-sm">
										<img src={logo} alt="" height="22" />
									</span>
									<span className="logo-lg">
										<img src={logoDark} alt="" height="55" />
									</span>
								</Link>

								<Link to="/" className="logo logo-light">
									<span className="logo-sm">
										<img src={logoLightSvg} alt="" height="22" />
									</span>
									<span className="logo-lg">
										<img src={logoLight} alt="" height="55" />
									</span>
								</Link>
							</div>
							<button
								type="button"
								className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
								data-toggle="collapse"
								onClick={this.toggleMenu}
								data-target="#topnav-menu-content"
							>
								<i className="fa fa-fw fa-bars"/>
							</button>

						</div>

						<div className="d-flex">

							<div className="dropdown d-none d-lg-inline-block ml-1">
								<button
									type="button"
									className="btn header-item noti-icon waves-effect"
									onClick={this.toggleFullscreen}
									data-toggle="fullscreen"
								>
									<i className="bx bx-fullscreen"></i>
								</button>
							</div>

							<NotificationDropdown {...this.props} />
							<ProfileMenu {...this.props} />

						</div>
					</div>
				</header>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = state => {
	const { layoutType } = state.Layout;
	return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
