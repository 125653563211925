import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Button, InputGroup, Alert, } from "reactstrap";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import call_api from "../../components/api-caller";

class CreateInstance extends Component {
	constructor() {
		super();
		this.state = {
			vmname: "",
			no_cores: 2,
			storage_ssd: true,
			storage_hdd: true,
			storage_val_ssd: 20,
			storage_val_hdd: 20,
			ram: 4096,
			email: "",
			token: "",
			os: "",
			os_cost: 0,
			cores_cost: 0,
			ram_cost: 0,
			ssd_cost: 0,
			hdd_cost: 0,
			return_status: -3,
			name_valid: false,
			os_valid: false,
			availIsos: [],
			btn_disabled: false,
			monthly_costs: {
				ram:0,
				cpu:0,
				ssd:0,
				hdd:0,
			},
			hourly_costs: {
				ram:0,
				cpu:0,
				ssd:0,
				hdd:0,
			},
			monthly_billing: true,
			createInsTab: 1,
			catalogs: [],
		}
	}

	async get_comp_costs() {
		var query = `query get_comp_costs{
			getCompCosts(email: "${this.props.email}", token:"${this.props.token}", realm: "${this.props.realm}"){
				ram
				cpu
				hdd
				ssd
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompCosts && data.data.getCompCosts.length === 2){
			this.setState({
				monthly_costs: data.data.getCompCosts[0],
				hourly_costs: data.data.getCompCosts[1]
			}, () => {
				this.handle_cost();
			});
		}
	}

	async createInstance(){
		this.setState({
			os_valid: false,
			name_valid: false,
		})
		var query = `mutation create_instance{
			addVm(
				email: "${this.props.email}", 
				token:"${this.props.token}", 
				realm: "${this.props.realm}", 
				vmName:"${this.state.vmname}", 
				ssdStor: ${this.state.storage_ssd}, 
				ssdStorage: ${this.state.storage_val_ssd}, 
				hddStor: ${this.state.storage_hdd}, 
				hddStorage: ${this.state.storage_val_hdd}, 
				memory: ${this.state.ram}, 
				cores:${this.state.no_cores}, 
				os: "${this.state.os}", 
				monthlyBilling: ${this.state.monthly_billing}
			) {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.addVm){
			this.setState({
				return_status: data.data.addVm.result
			})
		} else{
			this.setState({
				return_status: -2
			});
		}
	}

	async get_isos() {
		var query =`query get_iso{
			getIsosAvail(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}") {
				isoName
				osId
				cost
				hourlyCost
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getIsosAvail && data.data.getIsosAvail.length > 0){
			this.setState({
				availIsos: data.data.getIsosAvail,
			});
		}
	}

	componentDidMount() {
		this.get_isos();
		this.get_comp_costs();
	}

	change_iso(value) {
		if (value !== "null"){
			var oses = this.state.availIsos.filter((elem) => {return elem.osId === value});
			if (oses.length > 0){
				this.setState({
					os: value,
					os_cost: (this.state.monthly_billing ? oses[0].cost : oses[0].hourlyCost),
					os_valid: true,
				});
			}	
		} else {
			this.setState({
				os: null,
				os_cost: 0,
				os_valid: false,
			});
		}
	}

	handle_cost(){
		var oses = this.state.availIsos.filter((elem) => {return elem.osId === this.state.os});
		if (oses.length > 0){
			this.setState({
				os_cost: (this.state.monthly_billing ? oses[0].cost : oses[0].hourlyCost),
				os_valid: true,
			});
		}	
		this.setState({
			cores_cost: this.state.no_cores * (this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).cpu,
			ram_cost: this.state.ram * (this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).ram,
			hdd_cost: this.state.storage_hdd ? this.state.storage_val_hdd * (this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).hdd : 0,
			ssd_cost: this.state.storage_ssd ? this.state.storage_val_ssd * (this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).ssd : 0,
		});
	}


	render() {
		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<CardTitle className="mb-4">Create New Instance</CardTitle>
						{
							this.state.return_status === 0 ? (
								<Row>
									<Col md={12} lg={12} >
										<Alert color="warning">Error Creating Instance. You may not have credits to create a VM.</Alert>
									</Col>
								</Row>	
							) : null
						}
						{
							this.state.return_status === -2 ? (
								<Row>
									<Col md={12} lg={12} >
										<Alert color="danger">Error Creating Instance. Please check your permissions with administrator.</Alert>
									</Col>
								</Row>	
							) : null
						}
						{
							this.state.return_status === 1 ? (
								<Row>
									<Col md={12} lg={12} >
										<Alert color="success">VM created Successfully. You have been assigned to VM</Alert>
									</Col>
								</Row>	
							) : null
						}
						{
							this.state.return_status === -1 ? (
								<Row>
									<Col md={12} lg={12} >
										<Alert color="danger">Error creating VM. Cannot apply current configuration.</Alert>
									</Col>
								</Row>	
							) : null
						}
						<Form>
							<FormGroup className="mb-4" row>
								<Label htmlFor="projectname" className="col-form-label col-lg-2 col-md-2">Instance Name</Label>
								<Col lg="8" md="8">
									<Input id="projectname" invalid={!this.state.name_valid} name="projectname" type="text" className="form-control" placeholder="Enter Instance Name..." onChange={(event) => {this.setState({vmname: event.target.value, name_valid: (event.target.value.indexOf("_") === -1)})}} />
								</Col>
								<Col lg="2" className="font-weight-bold text-right pr-4" style={{fontSize: "16px"}} md="2">
									{this.state.monthly_billing ? "Monthly" : "Hourly"} Cost: <br />
									{this.props.currencyFormat.format(this.state.cores_cost+this.state.os_cost+this.state.ssd_cost+this.state.hdd_cost+this.state.ram_cost)}
								</Col>
							</FormGroup>
							<FormGroup className="mb-4" row>
								<label htmlFor="projectbudget" className="col-form-label col-lg-2">Select OS</label>
								<Col lg="8" md="8">
										<select required className="form-control" onChange={(event) => this.change_iso(event.target.value) }>
											<option key="Select" value={"null"}>
												Select
											</option>
											{
												this.state.availIsos.map((iso, indx) => 
													(
													<option key={"iso_"+indx} value={iso.osId}>{iso.isoName}</option>
													)
												)
											}
										</select>
								</Col>
								<Col lg="2" className="font-weight-bold text-right pr-4" md="2">
									{this.props.currencyFormat.format(this.state.os_cost)}
								</Col>
							</FormGroup>
							<FormGroup className="mb-4" row>
								<label htmlFor="projectbudget" className="col-form-label col-lg-2">Number of cores</label>
								<Col lg="3">
									<InputGroup>
										<div
											className="input-group-append"
											onClick={() =>
												this.setState({
													no_cores: Math.max(1, this.state.no_cores - 1)
												}, () => this.handle_cost())
											}
										>
											<Button type="button" color="primary">
												<i className="mdi mdi-minus"></i>
											</Button>
										</div>
										<input
											type="number"
											className="form-control"
											value={this.state.no_cores}
											placeholder="number"
											readOnly
										/>
										<div
											className="input-group-append"
											onClick={() =>
												this.setState({
													no_cores: Math.min(128, this.state.no_cores + 1)
												}, () => this.handle_cost())
											}
										>
											<Button type="button" color="primary">
												<i className="mdi mdi-plus"></i>
											</Button>
										</div>
									</InputGroup>
								</Col>
								<Col lg="5"></Col>
								<Col lg="2"  className="font-weight-bold text-right pr-4" md="2">
									{this.state.no_cores} * {this.props.currencyFormat.format((this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).cpu)} = {this.props.currencyFormat.format(this.state.cores_cost)}
								</Col>
							</FormGroup>
							<FormGroup className="mb-4" row>
								<label htmlFor="projectbudget" className="col-form-label col-lg-2">Maximum RAM assigned</label>
								<Col lg="8" md="8" >
									<span className="float-left mt-4 pt-3">1 GB</span>{" "}
									<span className="float-right mt-4 pt-3">256 GB</span>
									<Slider
										value={this.state.ram}
										step={1024}
										format={val => (val/1024) + " GB"}
										min={1024}
										max={256 * 1024}
										orientation="horizontal"
										onChange={value => {
											this.setState({ ram: value }, () => this.handle_cost());
										}}
									/>
								</Col>
								<Col lg="2" className="font-weight-bold mt-3 text-right pr-4" md="2">
									{(this.state.ram/1024)} * {this.props.currencyFormat.format((this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).ram*1024)} = {this.props.currencyFormat.format(this.state.ram_cost)}
								</Col>
							</FormGroup>
							<FormGroup className="mb-4" row>
								<label htmlFor="projectbudget" className="col-form-label col-lg-2">Storage Assigned</label>
								<Col lg="8" md="8">
									<div className="custom-control custom-switch mb-2" dir="ltr">
										<input type="checkbox" className="custom-control-input" id="storageSwitchSSD" defaultChecked={this.state.storage_ssd} />
										<label className="custom-control-label" htmlFor="storageSwitchSSD" onClick={(e) => { this.setState({ storage_ssd: !this.state.storage_ssd }, () => this.handle_cost()) }}>SSD</label>
									</div>
									<span className="float-left mt-4 pt-3">0 GB</span>
									<span className="float-right mt-4 pt-3">1 TB</span>
									<Slider
										value={this.state.storage_val_ssd}
										step={10}
										format={val => val + " GB"}
										min={0}
										max={1024}
										orientation="horizontal"
										onChange={value => {
											this.setState({ storage_val_ssd: value }, () => this.handle_cost());
										}}
									/>
									<div className="custom-control custom-switch mb-2 mt-4 pt-4" dir="ltr">
										<input type="checkbox" className="custom-control-input" id="storageSwitchHDD" defaultChecked={this.state.storage_hdd} />
										<label className="custom-control-label" htmlFor="storageSwitchHDD" onClick={(e) => { this.setState({ storage_hdd: !this.state.storage_hdd }, () => this.handle_cost()) }}>HDD</label>
									</div>

									<span className="float-left mt-4 pt-3">0 GB</span>{" "}
									<span className="float-right mt-4 pt-3">3 TB</span>
									<Slider
										value={this.state.storage_val_hdd}
										step={10}
										format={val => val + " GB"}
										min={0}
										max={3 * 1024}
										orientation="horizontal"
										onChange={value => {
											this.setState({ storage_val_hdd: value }, () => this.handle_cost());
										}}
									/>
								</Col>
								<Col lg="2" className="font-weight-bold text-right pr-4" md="2">
										<div className="mt-4 pt-3 mb-4 pb-4">SSD: {this.state.storage_val_ssd} * {this.props.currencyFormat.format((this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).ssd)} = {this.props.currencyFormat.format(this.state.ssd_cost)}</div>
										<div className="mt-4 pt-4">HDD: {this.state.storage_val_hdd} * {this.props.currencyFormat.format((this.state.monthly_billing ? this.state.monthly_costs : this.state.hourly_costs).hdd)} = {this.props.currencyFormat.format(this.state.hdd_cost)}</div>
								</Col>
							</FormGroup>
							<FormGroup className="mb-4" row>
								<label htmlFor="billing_type" className="col-form-label col-lg-2">Billing Type</label>
								<Col lg="8" md="8">
									<div className="custom-control custom-switch" dir="ltr">
										<input type="checkbox" className="custom-control-input" id="billingType" defaultChecked={this.state.monthly_billing} />
										<label className="custom-control-label" htmlFor="billingType" onClick={(e) => { this.setState({ monthly_billing: !this.state.monthly_billing }, () => this.handle_cost()) }}>Monthly Billing </label>
									</div>
								</Col>
							</FormGroup>
						</Form>
						<Row className="justify-content-end mb-2">
							<Col lg="8" md="8">
								<b>Note:</b> IP address will be assigned automatically 
							</Col>
						</Row>
						<Row className="justify-content-end">
							<Col lg="8" md="8">
								<Button type="submit" disabled={!(this.state.name_valid && this.state.os_valid)} color="primary" onClick={() => {this.createInstance()}}>Create Instance</Button>
							</Col>
						</Row>

					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

export default CreateInstance;