
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

import user2 from '../../../assets/images/users/user.svg';
import call_api from "../../api-caller";

class ProfileMenu extends Component {

	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			name: "",
		};
		this.toggle = this.toggle.bind(this);
	}


	toggle() {
		this.setState(prevState => ({
			menu: !prevState.menu
		}));
	}

	async getName(){
		var query = `query get_user_details{
			getUserDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserDetails){
			this.setState({
				name: data.data.getUserDetails.name
			})
		}
	}

	componentDidMount() {
		this.getName();
	}
	
	render() {

		return (
			<React.Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
					<DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
						<img className="rounded-circle header-profile-user" src={user2} alt="Header Avatar" />
						<span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.name}</span>
						<i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
					</DropdownToggle>
					<DropdownMenu right>
						<Dropdown
							isOpen={this.state.info_dropup1}
							className="mx-2"
							direction="left"
							toggle={() =>
								this.setState({
									info_dropup1: !this.state.info_dropup1,
								})
							}
						>
							<DropdownToggle className="btn btn-info btn-block" caret>
							<i className="mdi mdi-chevron-left"></i> Company 
							</DropdownToggle>
							<DropdownMenu>
								{
									this.props.domainList && this.props.domainList.map((elem, indx) => (
										<DropdownItem 
											className={this.props.domain === elem ? "text-danger" : ""}
											onClick={() => this.props.domainChange(indx)}>
											{elem}
										</DropdownItem>
									))
								}
							</DropdownMenu>
						</Dropdown>

						<Link to="/profile" className="dropdown-item mb-1">
							<i className="bx bx-user font-size-16 align-middle mr-1 text-warning"></i>
							<span>{this.props.t('Profile')}</span>
						</Link>
						<DropdownItem tag="a" href="auth-lock-screen">
							<i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{this.props.t('Lock screen')}
						</DropdownItem>
						<div className="dropdown-divider"></div>
						<Link to="/logout" className="dropdown-item">
							<i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
							<span>{this.props.t('Logout')}</span>
						</Link>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		);
	}
}

export default withRouter(withNamespaces()(ProfileMenu));
