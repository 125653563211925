import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={6} sm={12}>
                            {new Date().getFullYear()} © DeepCloud.
                        </Col>
                        <Col md={6} sm={12}>
                            <div className="text-sm-right d-none d-sm-block">
                                <a href="/downloads_page" className="d-inline mr-2">Downloads</a>
                                <p className="d-inline ml-2">Design & Develop by DeepCloud</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
