import React, { Component } from 'react';

import { Container, Row, Col, Card, CardBody, CardTitle, Table,  } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Moment from 'react-moment';
import SweetAlert from "react-bootstrap-sweetalert";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import DashedLine from "../Instances/SplineArea";
import CardHeader from 'reactstrap/lib/CardHeader';

class VDIReport extends Component {
	constructor (props){
		super(props);
		this.state = {
			cpu_usage: [],
			mem_usage: [],
			disk_read: [],
			disk_write: [],
			netin: [],
			netout: [],
			vmName: "",
			usgTime: "",
			usersUsage: [],
		}
	}

	async get_vdi_report() {
		var query = `query {
			getVmReport(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", reportToken: "${this.props.match.params.token}"){
				vmName
				usageTime
				usage {
					cpu {
						usage
						time
					}
					diskRead {
						usage
						time
					}
					diskWrite {
						usage
						time
					}
					mem {
						usage
						time
					}
					netin {
						usage
						time
					}
					netout {
						usage
						time
					}
				}
				userUsage {
					usageTime
					app
					start
					end
					user {
						name
						email
					}
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getVmReport){
			this.setState({
				vmName: data.data.getVmReport.vmName,
				usgTime: data.data.getVmReport.usageTime,
				usersUsage: data.data.getVmReport.userUsage,
			});
			var usg_stat = data.data.getVmReport.usage;
			if (usg_stat.cpu && usg_stat.cpu.length > 0){
				this.setState({
					cpu_usage: usg_stat.cpu
				});
			}
			if (usg_stat.mem && usg_stat.mem.length > 0){
				this.setState({
					mem_usage: usg_stat.mem
				});
			}
			if (usg_stat.diskRead && usg_stat.diskRead.length > 0){
				this.setState({
					disk_read: usg_stat.diskRead
				});
			}
			if (usg_stat.diskWrite && usg_stat.diskWrite.length > 0){
				this.setState({
					disk_write: usg_stat.diskWrite
				});
			}
			if (usg_stat.netin && usg_stat.netin.length > 0){
				this.setState({
					netin: usg_stat.netin
				});
			}
			if (usg_stat.netout && usg_stat.netout.length > 0){
				this.setState({
					netout: usg_stat.netout
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.match.params && this.props.match.params.token && this.props.domain != "" && prevProps.domain == "") {
			this.get_vdi_report();
		}
	}
	secondsToHour(seconds) {
		var hours = Math.floor(seconds / (60 * 60) );
		var mns = Math.floor((seconds / 60)  - (hours * 60));
		var scs = Math.floor(seconds - ((mns * 60) + (hours * 60 * 60)));
		return (`${(hours > 0) ? hours+" hr " : ""}${(mns > 0) ? mns+" m " : ""}${(scs > 0) ? scs+" s" : ""}`);
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Reports" breadcrumbItem="VDI Report" />
						<Card>
							<CardBody>
								<CardTitle className="mb-4">VM Usage</CardTitle>
								<h6><b>{this.state.vmName}</b> usage report for {this.state.usgTime}</h6>
								<Row>
									<Col lg={6} md={6} sm={12}>
										<Card>
											<CardBody>
												<CardTitle className="mb-4">CPU Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.cpu_usage.map((obj => {return obj.time})),
													series: [
														{
															name: "CPU",
															data: this.state.cpu_usage.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
									<Col lg={6} md={6} sm={12}>
										<Card>
											<CardBody>
												<CardTitle className="mb-4">Memory Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.mem_usage.map((obj => {return obj.time})),
													series: [
														{
															name: "RAM",
															data: this.state.mem_usage.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row>
									<Col lg={6} md={6} sm={12}>
										<Card>
											<CardBody>
												<CardTitle className="mb-4"> Disk IO Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.disk_read.map((obj => {return obj.time})),
													series: [
														{
															name: "Network IN",
															data: this.state.disk_read.map((obj => {return obj.usage}))
														},
														{
															name: "Network OUT",
															data: this.state.disk_write.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
									<Col lg={6} md={6} sm={12}>
										<Card>
											<CardBody>
												<CardTitle className="mb-4"> Netwrok IO Usage Stats </CardTitle>
												<DashedLine data={{
													xData: this.state.netin.map((obj => {return obj.time})),
													series: [
														{
															name: "Network IN",
															data: this.state.netin.map((obj => {return obj.usage}))
														},
														{
															name: "Network OUT",
															data: this.state.netout.map((obj => {return obj.usage}))
														}
													]
												}}/>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row>
									<Card className="w-100 m-2">
										<CardHeader>Users Usage Details</CardHeader>
										<CardBody>
											<Table>
												<thead>
													<td>User Name</td>
													<td>User Email</td>
													<td>Start Time</td>
													<td>End Time</td>
													<td>Usage Time</td>
													<td>Application</td>
												</thead>
												<tbody>
													{this.state.usersUsage.map((elem) => 
														<tr>
															<td>{elem.user.name}</td>
															<td>{elem.user.email}</td>
															<td><Moment format='h:mm:ss a, Do MMMM' date={elem.start} /></td>
															<td><Moment format='h:mm:ss a, Do MMMM' date={elem.end} /></td>
															<td>{this.secondsToHour(elem.usageTime)}</td>
															<td>{elem.app}</td>
														</tr>
													)}
												</tbody>
											</Table>

										</CardBody>
									</Card>
								</Row>
							</CardBody>
						</Card>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}
export default VDIReport;