import React, { Component } from 'react';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, CardTitle, CardSubtitle, Alert, Button } from "reactstrap";
import call_api from "../../components/api-caller";

class ResetPassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			message: "",
			type: null,
		}
		this.resetPassword = this.resetPassword.bind(this);
	}

	async resetPassword(event, values){
		var query = `mutation {
			adminResetUserPass(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", userEmail:"${values.user_email}", password: "${values.user_password}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.adminResetUserPass){
			if (data.data.adminResetUserPass.result === 1) {
				this.setState({
					message: "Password reset Successfully",
					type: "success"
				});	
			} else if (data.data.adminResetUserPass.result === -1) {
				this.setState({
					message: "NO User exists with the provided email",
					type: "warning"
				});
			} else {
				this.setState({
					message: "There is an error processing the request. Please try again after some time.",
					type: "danger"
				});	
			}
		}
		else
		this.setState({
			message: "You may not have enough privileges to reset user password. Please check your permissions with administrator",
			type: "danger"
		});	
	}

	render() {
		return (
			<React.Fragment>
				<CardTitle>Reset Password</CardTitle>
				<CardSubtitle className="mb-4">Reset User's Account Password here</CardSubtitle>
				{
					this.state.type ? (
						<Row>
							<Col md={8} lg={8} >
								<Alert color={this.state.type}>{this.state.message}</Alert>
							</Col>
						</Row>	
					) : null
				}
				<AvForm className="needs-validation" onValidSubmit={this.resetPassword}>
					<Row>
						<Col md={8} lg={8}>
							<AvField
								name="user_email"
								placeholder="Email Address of User"
								type="email"
								errorMessage="Enter a valid Email"
								label="Email"
								className="form-control"
								required
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8} lg={8}>
							<AvField
								name="user_password"
								placeholder="New Password for the User"
								type="password"
								label="Password"
								errorMessage="Password cananot be empty"
								className="form-control"
								required
								validate= {{
									minLength: {
										value: 6,
										errorMessage: "Password has to be atleast 6 characters long"
									}
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8} lg={8}>
							<AvField
								name="cnfrm_pass"
								placeholder="Enter Password again"
								type="password"
								label="Confirm Password"
								errorMessage="Password cananot be empty"
								className="form-control"
								required
								validate= {{
									match: {
										value: "user_password",
										errorMessage: "Password's do not match"
									}
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col md="6">
							<Button
								color="success"
								className="btn btn-success waves-effect waves-light"
								type="submit"
							>
								Reset
							</Button>
						</Col>
					</Row>
				</AvForm>
			</React.Fragment>
		)
	}
}

export default ResetPassword;