
import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody,Container, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import SweetAlert from "react-bootstrap-sweetalert";
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import call_api from "../../components/api-caller";

class ForgetPasswordPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			show_otp: false,
			otp: "",
			otp_verify: false,
			show_new_pass: false,
			pass_change_success: false,
			pass_change_failed: false,
			new_pass: "",
			error: false,
			error_msg: "",
		};

		// handleValidSubmit
		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.send_otp_auth = this.send_otp_auth.bind(this);
		this.new_pass_change = this.new_pass_change.bind(this);
	}

	// handleValidSubmit
	async handleValidSubmit(event, values) {
		var query = `mutation request_reset_pass{
			forgotPassSendOtp(email: "${values.email}", realm: "${this.props.realm}") {
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.forgotPassSendOtp && data.data.forgotPassSendOtp.result === 1){
			this.setState({
				email: values.email,
				show_otp: true,
			})
		} else {
			this.setState({
				error_msg: "Looks like no user exists with the email. Please try with another email",
				error: true,
			})
		}
	}

	async send_otp_auth(event, values) {
		event.preventDefault();
		var query = `mutation opt_verify{
			forgotPassVerifyOtp(email: "${this.state.email}", realm: "${this.props.realm}", otp: "${values.otp}"){
				result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.forgotPassVerifyOtp && data.data.forgotPassVerifyOtp.result){
			this.setState({
				otp_verify: false,
				show_otp: false,
				show_new_pass: true,
			})
		}
		else{
			this.setState({
				otp_verify: true,
			})
		}
	}

	async new_pass_change(event){
		event.preventDefault();
		var query = `mutation newPass{
			changeNewPassword(email: "${this.state.email}", realm: "${this.props.realm}", password: "${this.state.new_pass}"){
			  result
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.changeNewPassword && data.data.changeNewPassword.result===1){
			this.setState({
				otp_verify: false,
				show_otp: false,
				show_new_pass: false,
				pass_change_success: true,
			});
		}
		else{
			this.setState({
				otp_verify: false,
				show_otp: false,
				show_new_pass: false,
				pass_change_failed: true,
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-soft-primary">
										<Row>
											<Col className="col-7">
												<div className="text-primary p-4">
													<h5 className="text-primary">Reset Password !</h5>
													<p>Reset your account password by providing your identity.</p>
												</div>
											</Col>
											<Col className="col-5 align-self-end">
												<img src={profile} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<div>
											<Link to="/">
												<div className="avatar-md profile-user-wid mb-4">
													<span className="avatar-title rounded-circle bg-light">
														<img src={logo} alt="" height="34" />
													</span>
												</div>
											</Link>
										</div>
										<div className="p-2">
											{this.props.forgetError && this.props.forgetError ? (
												<Alert color="danger" style={{ marginTop: "13px" }}>
													{this.props.forgetError}
												</Alert>
											) : null}
											{this.props.forgetSuccessMsg ? (
												<Alert color="success" style={{ marginTop: "13px" }}>
													{JSON.parse(this.props.forgetSuccessMsg)["msg"]} <br />
													The OTP has been sent to <b>{JSON.parse(this.props.forgetSuccessMsg)["email"]}</b>
												</Alert>
											) : null} 
											{ this.state.error ? <Alert color="danger">{this.state.error_msg}</Alert> : null}
											<AvForm
												className="form-horizontal"
												onValidSubmit={this.handleValidSubmit}
											>

												<div className="form-group">
													<AvField
														name="email"
														label="Email"
														className="form-control"
														placeholder="Enter email of your account"
														type="text"
														required
													/>
												</div>
												<Row className="form-group">
													<Col className="text-right">
														<button
															className="btn btn-primary w-md waves-effect waves-light"
															type="submit"
														>
															Reset
															</button>
													</Col>
												</Row>
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Go back to{" "}
										<Link
											to="login"
											className="font-weight-medium text-primary"
										>
											Login
											</Link>{" "}
									</p>
									Crafted with <i className="mdi mdi-heart text-danger"></i> by <p>© {new Date().getFullYear()} DeepCloud</p>
								</div>
							</Col>
						</Row>
						{this.state.show_otp ? (
							<SweetAlert
								title="Enter OTP"
								confirmBtnBsStyle="danger"
								confirmBtnText="Close"
								onConfirm={() => {this.setState({show_otp: false})}}
							>
								Enter the OTP recieved in your Email or to your Phone Number
								<AvForm
									className="form-horizontal mt-4"
									onValidSubmit={this.send_otp_auth}
								>
									<Row>
										<Col md="12">
											{ this.state.otp_verify ? (<Alert color="danger" style={{ marginTop: "13px" }}>
												Incorrect OTP entered
											</Alert>) : null}

											<AvField
												name="otp"
												className="form-control"
												errorMessage="Invalid OTP"
												placeholder="Enter 6-digit OTP"
												type="text"
												required
											/>

										</Col>
									</Row>
									<Row>
										<Col md="12">
											<Button
												type="submit"
											>
												Submit
											</Button>
										</Col>
									</Row>
								</AvForm>
							</SweetAlert>
						) : null}
						{this.state.show_new_pass ? (
							<SweetAlert
							title="New Password"
							confirmBtnBsStyle="danger"
							confirmBtnText="Close"
							onConfirm={() => {this.setState({show_pass: false})}}
						>
							Your Identity has been verified. Please enter your password
							<AvForm
								className="form-horizontal mt-4"
								onValidSubmit={this.new_pass_change}
							>
								<Row>
									<Col md="12">
										<AvField
											name="new_pass"
											className="form-control"
											errorMessage="Password cannot be empty"
											placeholder="New Password"
											type="password"
											onChange = {(event) => {this.setState({new_pass: event.target.value})}}
											required
										/>

									</Col>
								</Row>
								<Row>
									<Col md="12">
										<AvField
											name="new_pass_cnfrm"
											className="form-control"
											placeholder="Confirm Password"
											type="password"
											errorMessage="Password cannot be empty"
											required
											validate = {{
												required: {value: true},
												match: {value: "new_pass", errorMessage: "Password's do not match"}
											}}
										/>

									</Col>
								</Row>
								<Row>
									<Col md="12">
										<Button
											type="submit"
										>
											Submit
										</Button>
									</Col>
								</Row>
							</AvForm>
						</SweetAlert>
					) : null}
						{this.state.pass_change_success ? (
							<SweetAlert success 
								confirmBtnText="Continue"
								confirmBtnBsStyle="success"
								title="Password changed successfully"
								onConfirm={() => this.props.history.push("/")}
							>
								Your password has been reset. You can now login to your account.
							</SweetAlert>
						) : null}
						{this.state.pass_change_failed ? (
							<SweetAlert danger
								confirmBtnText="Continue"
								confirmBtnBsStyle="success"
								title="Password changed Failed"
								onConfirm={() => this.props.history.push("/")}
							>
								Your password haven't been reset. Please try resetting your password again after some time
							</SweetAlert>
						) : null}

					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = state => {
	const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
	return { forgetError, forgetSuccessMsg };
};

export default withRouter(
	connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
