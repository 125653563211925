import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import call_api from "../../components/api-caller";

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isHigherUser: false
		};
	}

	componentDidMount() {
		var matchingMenuItem = null;
		var ul = document.getElementById("navigation");
		var items = ul.getElementsByTagName("a");
		for (var i = 0; i < items.length; ++i) {
			if (this.props.location.pathname === items[i].pathname) {
				matchingMenuItem = items[i];
				break;
			}
		}
		if (matchingMenuItem) {
			this.activateParentDropdown(matchingMenuItem);
		}
	}

	componentDidMount(){
		if (this.props.domain !== ""){
			this.get_login_info();
		}
	}

	componentDidUpdate(prevProps, prevState){
		if (this.props.domain && this.props.domain !== "" && prevProps.domain === ""){
			this.get_login_info();
		}
	}

	async get_login_info() {
		var query = `query is_higher_user{
			isHigherUser(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				result
			}
		}`;
		const data = await call_api(query);
		if ( data && data.data && data.data.isHigherUser && data.data.isHigherUser.result === "1"){
			this.setState({isHigherUser: true});
		}
	}

	activateParentDropdown = item => {
		item.classList.add("active");
		const parent = item.parentElement;
		if (parent) {
			parent.classList.add("active"); // li
			const parent2 = parent.parentElement;
			parent2.classList.add("active"); // li
			const parent3 = parent2.parentElement;
			if (parent3) {
				parent3.classList.add("active"); // li
				const parent4 = parent3.parentElement;
				if (parent4) {
					parent4.classList.add("active"); // li
					const parent5 = parent4.parentElement;
					if (parent5) {
						parent5.classList.add("active"); // li
						const parent6 = parent5.parentElement;
						if (parent6) {
							parent6.classList.add("active"); // li
						}
					}
				}
			}
		}
		return false;
		
	};

	render() {
		return (
			<React.Fragment>
				<div className="topnav">
					<div className="container-fluid">
						<nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
							<Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link dropdown-toggle arrow-none" to="/dashboard">
											<i className="bx bx-home-circle mr-2"></i>{this.props.t('Dashboard')} {this.props.menuOpen}
										</Link>
									</li>
								</ul>
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link dropdown-toggle arrow-none" to="/groups">
											<i className="bx bxs-user-badge mr-2"></i>{this.props.t('Groups')} {this.props.menuOpen}
										</Link>
									</li>
								</ul>
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link dropdown-toggle arrow-none" to="/create_instance">
											<i className="bx bx-desktop mr-2"></i>{this.props.t('Create Instance')} {this.props.menuOpen}
										</Link>
									</li>
								</ul>
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link dropdown-toggle arrow-none" to="/billing">
											<i className="bx bx-rupee mr-2"></i>{this.props.t('Billing')} {this.props.menuOpen}
										</Link>
									</li>
								</ul>
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link dropdown-toggle arrow-none" to="/help">
											<i className="bx bx-help-circle mr-2"></i>{this.props.t('Help')} {this.props.menuOpen}
										</Link>
									</li>
								</ul>
								{this.state.isHigherUser ? 
									<ul className="navbar-nav">
										<li className="nav-item">
											<Link className="nav-link dropdown-toggle arrow-none" to="/admin">
												<i className="bx bx-user-voice mr-2"></i>{this.props.t('Admin')} {this.props.menuOpen}
											</Link>
										</li>
									</ul> : null
								}
							</Collapse>
						</nav>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(withNamespaces()(Navbar));
