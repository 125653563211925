import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Media } from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { withNamespaces } from 'react-i18next';
import call_api from "../../components/api-caller";
import WelcomeComp from './WelcomeComp';
import StackedColumnChart from "./StackedColumnChart";
import TopCities from "./TopCities";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

const Dashboard = (props) => {
	const [reports, setReports] = useState([
		{ title: "Cores", iconClass: "bx-chip", description: "0" },
		{ title: "RAM", iconClass: "bx-microchip", description: "0 GB" },
		{ title: "Storage", iconClass: "bx-memory-card", description: "0 TB" },
		{ title: "Network", iconClass: "bx-vector", description: "0 MB"},
	]);
	const [modal, setModal] = useState(false);
	const [userDetails, setUserDetails] = useState({});
	const [vmsInfo, setVmsInfo] = useState([]);
	const [userVmStatus, setUserVmStatus] = useState([0, 0, 0, 0]);
	const {
		visitorIsLoading,
		visitorError,
		visitorData,
	  } = useVisitorData();

	const get_details = async () => {
		var query = `query get_user_details{
			getUserDetails(email: "${props.email}", token: "${props.token}", realm: "${props.realm}", domain: "${props.domain}") {
				name
				phoneNo
				companyName
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserDetails){
			const dt = data.data.getUserDetails;
			setUserDetails(dt)
		}
	}

	const getVmInfo = async () => {
		var query = `query get_user_vms{
			getUserVms(email: "${props.email}", token: "${props.token}", realm: "${props.realm}", domain: "${props.domain}") {
				name
				vmId
				nodeName
				location
				ipAddress
				cores
				ram
				stor
				template
				netUsage
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserVms && data.data.getUserVms.length > 0){
			var cores = 0;
			var ram = 0;
			var stor = 0;
			var net_usage = 0;
			data.data.getUserVms.forEach((vm) => {
				cores += vm.cores;
				ram += vm.ram;
				stor += vm.stor;
				net_usage += vm.netUsage;
			})
			function mb_gb_conv(mb) {
				const gb = (mb / 1024).toFixed(2)
				if (gb > 0){
					return `${gb} GB`
				} else {
					return `${mb.toFixed(2)} MB`
				}
			}
			
			setVmsInfo(data.data.getUserVms);
			setUserVmStatus([cores, `${ram} GB`, `${stor} GB`, mb_gb_conv(net_usage)]);
		}
	}

	useEffect(() => {
		get_details();
		getVmInfo();
	}, []);

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title={'My Cloud'} breadcrumbItem={'Dashboard'} />
					<Row>
						<Col xl="4">
							{
								userDetails !== {} &&
								<WelcomeComp userDetails={userDetails} vms={vmsInfo.length} />
							}
						</Col>
						<Col xl="8">
							<Row>
								{
									reports.map((report, key) =>
										<Col md="3" key={"_col_" + key}>
											<Card className="mini-stats-wid">
												<CardBody>
													<Media>
														<div className="mini-stat-icon avatar-sm rounded-circle bg-primary mr-4 mt-1">
															<span className="avatar-title">
																<i className={"bx " + report.iconClass + " font-size-24"}></i>
															</span>
														</div>
														<Media body>
															<p className="text-muted font-weight-medium">{report.title}</p>
															<h4 className="mb-0">{userVmStatus[key]}</h4>
														</Media>
													</Media>
												</CardBody>
											</Card>
										</Col>
									)
								}
							</Row>
							<TopCities vmInfo={vmsInfo}/>
						</Col>
					</Row>

					<Row>
						<Col xl="12" lg="12" md="12" sm="12">
							<Card>
								<CardHeader className="m-2">
									Usage Overview
								</CardHeader>
								<CardBody>
									<CardBody>
										{
											(props.email !== "") && (props.token !== "") && (props.realm !== "") && 
											<StackedColumnChart {...props} />
										}
									</CardBody>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
}

export default withNamespaces()(Dashboard);
