import React, { Component } from 'react';
import { Container, Row, Col, Table, Input, Card, Form, FormGroup, Label, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import call_api from "../../components/api-caller";
import showToast from "../../components/showToast";
import Moment from 'react-moment';
import RazorPayPaymentIndex from "./razorpay_payment";

class BillingPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			user_name: "",
			vmInfo: [],
			phoneNo: "",
			address: "",
			gstin: "",
			sub_total: 0,
			auth_token: "",
			countries: [],
			states: [],
			cities: [],
			country: "",
			state: "",
			city: "",
			prepaid: true,
			wallet_balance: 0,
			amount_pay: 0,
			payment_success: false, 
			payment_failure: false,
			pmnt_msg: "",
			jwt_data: null,
		}
		this.pay_usage = this.pay_usage.bind(this);
	}

	async get_states(country){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Accept': 'application/json', 
					"Authorization": 'Bearer ' + this.state.auth_token
				},
			};
			const resp = await fetch('https://www.universal-tutorial.com/api/states/'+country, requestOptions);
		if (resp.status === 200){
			const data = await resp.json();

			this.setState({
				country: country,
				states: data.map((elem) => {return elem.state_name})
			});
		}
	}

	async get_cities(state){
		const requestOptions = {
			method: 'GET',
			headers: {
				'Accept': 'application/json', 
				"Authorization": 'Bearer ' + this.state.auth_token
			},
		};
		const resp = await fetch('https://www.universal-tutorial.com/api/cities/'+state, requestOptions);
		if (resp.status === 200){
			const data = await resp.json();
			this.setState({
				state: state,
				cities: data.map((elem) => {return elem.city_name})
			});
		}
	}

	async get_countries(){
		const requestOptions = {
			method: 'GET',
			headers: {
				'Accept': 'application/json', 
				"Authorization": 'Bearer ' + this.state.auth_token
			},
		};
		const cointryResp = await fetch('https://www.universal-tutorial.com/api/countries/', requestOptions);
		if (cointryResp.status === 200){
			const countries = await cointryResp.json();
			this.setState({
				countries: countries.map((elem) => {return elem.country_name})
			});
		}
	}


	async get_states_data() {
		const requestOptions = {
			method: 'GET',
			headers: { 'Accept': 'application/json', "api-token": 'VX5rWYh0jEjDxSA4wSHAO7YHd6_k9C7zB1jlZPVCcV0rAOuQ2oa-14zldy5-4Fp-S-U', "user-email": "hopena3260@pnrep.com" },
		};
		const resp = await fetch('https://www.universal-tutorial.com/api/getaccesstoken/', requestOptions);
		if (resp.status === 200){
			const data = await resp.json();
			this.setState({
				auth_token: data.auth_token
			}, () => {
				this.get_countries();
			});
		}
	}

	async getUserWalletDetails() {
		var query = `query get_wallet_details{
			getWalletDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				prepaid
				amount
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getWalletDetails){
			this.setState({
				prepaid: data.data.getWalletDetails.prepaid,
				wallet_balance: data.data.getWalletDetails.amount
			});
			
		}
	}

	async getUserDetails() {
		var query = `query get_user_details{
			getUserDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				phoneNo
				address{
					country
					state
					city
					address
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getUserDetails){
			this.setState({
				user_name: data.data.getUserDetails.name,
				phoneNo: data.data.getUserDetails.phoneNo,
			}, () => {
				if (data.data.getUserDetails.address.city && data.data.getUserDetails.address.country && data.data.getUserDetails.address.state && data.data.getUserDetails.address.address) {
					this.setState({
						country: data.data.getUserDetails.address.country
					}, () => {
						this.get_states(data.data.getUserDetails.address.country).then(() => {
							this.setState({
								state: data.data.getUserDetails.address.state
							}, () => {
								this.get_cities(data.data.getUserDetails.address.state).then(() => {
									this.setState({
										city: data.data.getUserDetails.address.city,
										address: data.data.getUserDetails.address.address
									});
								})
							})
						})
					});
				}
			});
		}
	}

	async getVmInfo() {
		var query = `query user_billing{
			userBilling(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				name
				vmId
				createDate
				lastBillDate
				billEndDate
				cost
				costpm
				cluster
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.userBilling){
			this.setState({
				vmInfo: data.data.userBilling,
				sub_total: data.data.userBilling.map(vm => {return vm.cost}).reduce((a, b) => a+b, 0)
			});
		}
		else {
			showToast("Cannot Access Billing Page", "You may not have neough privileges to access billing page", "error");
			// this.props.history.push("/");
		}
	}

	async pay_usage(){
		var query = `mutation pay_usage{
			payVmUsage(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", transaction:{
				amount: ${this.state.amount_pay},
				transactionRef:"",
				taxId:"${this.state.gstin}",
				address:{
					address:"${this.state.address.replace(/\n/g, " ")}",
					state:"${this.state.state}",
					city:"${this.state.city}",
					country:"${this.state.country}",
				}
			}) {
				result
				jwtData
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.payVmUsage){
			if (data.data.payVmUsage.result){
				showToast("Payment Initiated", "Please proceed with payment with payment gateway", "success");
				this.setState({
					jwt_data: data.data.payVmUsage.jwtData
				})
			} else {
				showToast("Payment Failed", "Make sure you have entered all fields correct and amount is more than ₹ 1.", "error");
			}
		}
		else {
			showToast("Payment Failed", "The current transaction has failed. Please try again after some time", "error");
		}
	}

	getUserCreds() {
		var userCreds = JSON.parse(localStorage.getItem("authUser"));
		if (userCreds && userCreds.hasOwnProperty("email") && userCreds.hasOwnProperty("token")){
			var email = userCreds["email"];
			var token = userCreds["token"];
			this.setState({
				email: email,
				token: token,
			}, () => {
				this.getUserDetails();
				this.getVmInfo();
				this.getUserWalletDetails();
			});
		}
	}

	componentDidMount() {
		this.getUserCreds();
		this.get_states_data();
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>

						{/* Render Breadcrumbs */}
						<Breadcrumbs title="Billing" breadcrumbItem="Payments" />
						<Row>
							<Col lg="8">
								<Card className="shadow-none border mb-0">
									<CardBody>
										<CardTitle className="mb-4">VMs' Summary</CardTitle>

										<div className="table-responsive">
											<Table className="table-centered mb-0 table-nowrap">
												<thead className="thead-light">
													<tr>
														<th scope="col">VM Name</th>
														<th scope="col">VM Details</th>
														<th scope="col">Billing Status</th>
														<th scope="col" className="text-right">Cost</th>
														<th scope="col" className="text-right">Usage Cost</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.vmInfo.map((vm, key) =>
															<tr key={"_orderSummary_" + key}>
																<th scope="row">{vm.name}</th>
																<td>
																	<p className="text-muted mb-0">Cluster: {vm.cluster}</p>
																	<p className="text-muted mb-0">Created: <Moment format="DD-MM-YYYY HH:mm">{vm.createDate}</Moment></p>
																</td>
																<td>
																	<p className="mb-0">Last: {vm.lastBillDate && <Moment format="DD-MM-YYYY HH:mm">{vm.lastBillDate}</Moment>}</p>
																	<p className="mb-0">End: {vm.billEndDate && <Moment format="DD-MM-YYYY HH:mm">{vm.billEndDate}</Moment>}</p>
																</td>
																<td className="text-right">₹ {vm.costpm.toFixed(2)}</td>
																<td className="text-right">₹ {vm.cost.toFixed(2)}</td>
															</tr>
														)
													}
													{/* <tr>
														<td></td>
														<td></td>
														<td></td>
														<td>
															<h6 className="m-0 text-right">Sub Total:</h6>
														</td>
														<td className="text-right">
															₹ {this.state.sub_total.toFixed(2)}
														</td>
													</tr> */}
													{/* <tr className="bg-soft-primary p-3 rounded">
														<td colSpan="3">
															<h5 className="font-size-14 text-primary mb-0">{/*<i className="fas fa-shipping-fast mr-2"></i> }{this.state.country.toLowerCase() === "india" ? "GSTIN" : "TAX"} <span className="float-right"></span></h5>
														</td>
														<td>
															{(this.state.country.toLowerCase() === "india") && (this.state.state.toLowerCase() === "karnataka") ? 	(
																	<span>
																		<p className="mb-0">CGST @ 9%</p>
																		<p className="mb-0">SGST @ 9%</p>
																	</span>) : (
																		(this.state.country.toLowerCase() === "india") ? "IGST @ 18%" : "NO TAX")}														
														</td>
														<td className="text-right">
															<div>
																	{(this.state.country.toLowerCase() === "india") && (this.state.state.toLowerCase() === "karnataka") ? 	(
																	<span>
																		<p className="mb-0">₹ {(this.state.sub_total*0.09).toFixed(2)}</p>
																		<p className="mb-0">₹ {(this.state.sub_total*0.09).toFixed(2)}</p>
																	</span>) : (
																		(this.state.country.toLowerCase() === "india") ? <p className="mb-0">₹ {(this.state.sub_total*0.18).toFixed(2)}</p> : <p className="mb-0">₹ 0</p>)}
															</div>
														</td>
													</tr> */}
													<tr className="bg-soft-primary p-3 rounded">
														<td colSpan="4">
															<h5 className="font-size-14 text-primary mb-0">Total<span className="float-right"></span></h5>
														</td>
														<td className="text-right">
															<div>
																₹ {this.state.sub_total.toFixed(2)}

															</div>
														</td>
													</tr>
													{/* <tr>
														<td></td>
														<td></td>
														<td colSpan="2">
															<h6 className="m-0 text-right">Total:</h6>
														</td>
														<td className="text-right">
															₹ {(this.state.sub_total * (1 + (this.state.country.toLowerCase() === "india" ? 0.18 : 0))).toFixed(2)}
														</td>
													</tr> */}
												</tbody>
											</Table>
											<p className="mt-2">
												<b>Note:</b> The costs are subject to change as per the Seller's resource costs.
											</p>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg="4">
								<Card>
									<CardBody>
										<CardTitle>Wallet Details</CardTitle>
										<Table>
											<tr>
												<th>
													Account Type :
												</th>
												<td>
													{this.state.prepaid ? "Prepaid" : "Postpaid"}
												</td>
											</tr>
											<tr>
												<th>
													Wallet Balance :
												</th>
												<td>
													₹ {this.state.wallet_balance.toFixed(2)}
												</td>
											</tr>
										</Table>
									</CardBody>
								</Card>
								<Card>
									<CardBody>
										<CardTitle>Billing Information</CardTitle>
										<CardSubtitle className="mb-3">Billing for your VMs' Usage</CardSubtitle>
										<Form>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-name" md="2" className="col-form-label">Name</Label>
												<Col md="10">
													<Input type="text" value={this.state.user_name} className="form-control" id="billing-name" required readOnly placeholder="Enter your name" />
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-email-address" md="2" className="col-form-label">Email Address</Label>
												<Col md="10">
													<Input type="email" value={this.props.email} className="form-control" readOnly required id="billing-email-address" placeholder="Enter your email" />
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-phone" md="2" className="col-form-label">Phone</Label>
												<Col md={10}>
													<input type="text" value={this.state.phoneNo} className="form-control" readOnly required id="billing-phone" placeholder="Enter your Phone no." />
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-phone" md="2" className="col-form-label">Country</Label>
												<Col md={10}>
													<select className="form-control" required value={this.state.country} onChange={(event) => {this.get_states(event.target.value)}}>
														<option key="Country_" value="null">Select</option>
														{
															this.state.countries.map((cntry, ind) => (
																<option key={"Country_"+ind} value={cntry}>{cntry}</option>
															))
														}
													</select>
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-phone" md="2" className="col-form-label">State</Label>
												<Col md={10}>
													<select className="form-control" required value={this.state.state} onChange={(event) => {this.get_cities(event.target.value)}}>
														<option key="State_" value="null">Select</option>
														{
															this.state.states.map((states, ind) => (
																<option key={"State_"+ind} value={states}>{states}</option>
															))
														}
													</select>
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-phone" md="2" className="col-form-label">City</Label>
												<Col md={10}>
													<select className="form-control" required value={this.state.city} onChange={(event) => {this.setState({city: event.target.value})}}>
														<option key="City_" value="null">Select</option>
														{
															this.state.cities.map((city, ind) => (
																<option key={"City_"+ind} value={city}>{city}</option>
															))
														}
													</select>
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-address" md="2" className="col-form-label">Address</Label>
												<Col md="10">
													<textarea className="form-control" required value={this.state.address} id="billing-address" rows="3" placeholder="Enter full address" onChange={(event) => {this.setState({address: event.target.value})}} ></textarea>
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="billing-phone" md="2" className="col-form-label">{this.state.country.toLowerCase() === "india" ? "GSTIN" : "TAX-ID"}</Label>
												<Col md={10}>
													<input type="text" className="form-control" id="country" placeholder="Enter your GSTIN" onChange={(event) => {this.setState({gstin: event.target.value})}}  />
												</Col>
											</FormGroup>
											<FormGroup className="mb-4" row>
												<Label htmlFor="amount_pay" md="2" className="col-form-label">Amount Payable</Label>
												<Col md={10}>
													<input type="number" className="form-control" id="amount_pay" placeholder="Enter amount you would like to pay" onChange={(event) => {this.setState({amount_pay: event.target.value})}}  />
												</Col>
											</FormGroup>
											<Row className="mx-2">
												<Button
													color="success"
													className="btn btn-success waves-effect waves-light btn-block"
													onClick={this.pay_usage}
												>
													Pay
												</Button>
											</Row>
										</Form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				{
					this.state.jwt_data ? 
					<RazorPayPaymentIndex jwt_token={this.state.jwt_data} />
					: null 
				}
			</React.Fragment>
		);
	}
}

export default BillingPage;