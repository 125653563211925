import React, { Component } from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, } from "reactstrap";
import showToast from "../../components/showToast";
import call_api from "../../components/api-caller";
import { AvForm, AvField } from 'availity-reactstrap-validation';

class CompanyPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			compDetails: {},
			compConfig: {},
		}
		this.modify_company_details = this.modify_company_details.bind(this);
	}

	async get_company_details() {
		var query =`query {
			getMyCompanyDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}"){ 
				companyName
				gstin
				address
			}
		}`;
		const data = await call_api(query);
		if ( data && data.data && data.data.getMyCompanyDetails){
			this.setState({
				compDetails: data.data.getMyCompanyDetails
			});
		}
	}

	async get_company_config() {
		var query =`query {
			getCompanyConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}"){ 
				rdpDomainLogin
			}
		}`;
		const data = await call_api(query);
		if ( data && data.data && data.data.getCompanyConfig){
			this.setState({
				compConfig: data.data.getCompanyConfig
			});
		}
	}

	async modify_company_config() {
		const query = `mutation {
			changeCompanyConfig(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", rdpDomLogin: ${this.state.compConfig.rdpDomainLogin ? "true": "false"}) {
				result
			}
		}`;
		const data = await call_api(query);
		if ( data && data.data && data.data.changeCompanyConfig){
			if (data.data.changeCompanyConfig.result) {
				showToast("Configuration modified successfully", "The company configuration has been modified successfully", "success");
				this.get_company_config();
			} else {
				showToast("Configuration modification failed", "There is an error modifying company configuration. Please try again after some time.", "error");
			}
		} else {
			showToast("Configuration modification failed", "You may not have enough privileges to modify company configuration.", "error");
		}
	}

	async modify_company_details(event, values) {
		const query = `mutation {
			modifyCompanyDetails(email: "${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}", companyName: ${JSON.stringify(values.comp_name)}, address: ${JSON.stringify(values.address)}, gstin: "${values.gstin}") {
				result
			}
		}`;
		const data = await call_api(query);
		if ( data && data.data && data.data.modifyCompanyDetails){
			if (data.data.modifyCompanyDetails.result) {
				showToast("Details modified successfully", "The company details have been modified successfully", "success");
				this.get_company_details();
			} else {
				showToast("Details modification failed", "There is an error modifying company details. Please try again after some time.", "error");
			}
		} else {
			showToast("Details modification failed", "You may not have enough privileges to modify company Details.", "error");
		}
	}

	componentDidMount() {
		this.get_company_details();
		this.get_company_config();
	}

	render() {
		return (<React.Fragment>
			<Card>
				<CardHeader>Company Details</CardHeader>
				<CardBody>
					<Row>
						<Col lg="8">
							<AvForm onValidSubmit={this.modify_company_details}>
								<AvField 
									type="text"
									name="comp_name"
									label="Company Name"
									placeholder="Name of your Company"
									required
									value={this.state.compDetails.companyName}
								/>
								<AvField 
									type="text"
									name="address"
									label="Company Address"
									placeholder="Address of your Company"
									required
									value={this.state.compDetails.address}
								/>
								<AvField 
									type="text"
									name="gstin"
									label="GSTIN Regn Number"
									placeholder="GSTIN of your Company"
									required
									validate={{
										pattern: {
											value: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
											errorMessage: "Invalid GSTIN number"
										}
									}}
									value={this.state.compDetails.gstin}
								/>
								<Button className="btn btn-success btn-block">
									Submit
								</Button>
							</AvForm>
						</Col>
					</Row>
				</CardBody>
			</Card>
			<Card>
				<CardHeader>Company Configuratin</CardHeader>
				<CardBody>
					<Row>
						<Col md={8} lg={8}>
							<div className="custom-control custom-checkbox mb-3">
								<input
									type="checkbox"
									className="custom-control-input"
									id="CustomCheck1"
									onChange={() => false}
									checked={this.state.compConfig.rdpDomainLogin}
								/>
								<label
								className="custom-control-label"
								onClick={() => {
									var compConfig = this.state.compConfig;
									compConfig.rdpDomainLogin = !compConfig.rdpDomainLogin;
									this.setState({
										compConfig: compConfig,
									})
								}}
								>
								RDP Domain Login
								</label>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={8} lg={8}>
							<Button className="btn btn-info btn-block" onClick={() => this.modify_company_config()}>
								Modify
							</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</React.Fragment>)
	}
}

export default CompanyPage;