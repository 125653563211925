import React, { Component } from 'react';
import {Card, CardBody, Table, Badge, Row, Col} from "reactstrap";
import call_api from "../../components/api-caller";
import Moment from 'react-moment';


class PaymentsPage extends Component {

	constructor (props){
		super(props);
		this.state = {
			all_pmnts: [],
			company_pmnt_balance: {},
		};
	}

	componentDidMount(){
		this.get_all_payments();
		this.get_balance_details();

	}

	async get_balance_details() {
		var query = `query get_company_balance{
			getCompanyBalanceData(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				billingDate
				monthlyBalance
				totalBalance
				monthlyExpenditure
				totalExpenditure
				monthlyWithdrawl
				totalWithdrawl
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyBalanceData){
			this.setState({
				company_pmnt_balance: data.data.getCompanyBalanceData
			})
		}
	}

	async get_all_payments() {
		var query =`query get_payments{
			getCompanyPayments(email:"${this.props.email}", token: "${this.props.token}", realm: "${this.props.realm}", domain: "${this.props.domain}") {
				amount
				paidTime
				success
				transDetails{
					address
					state
					city
					country
					taxId
					token
				}
				user {
					name
					phoneNo
					email
				}
			}
		}`;
		const data = await call_api(query);
		if (data && data.data && data.data.getCompanyPayments && data.data.getCompanyPayments.length > 0){
			this.setState({
				all_pmnts: data.data.getCompanyPayments,
				recieve_amnt: data.data.getCompanyPayments.map((elem) => {if (elem.success) {return elem.amount} else {return 0}}).reduce((a, b) => a+b)
			});
		}
	}
	
	render() {
		return (
			<React.Fragment>
				<Card>
					<CardBody className="border-top">
						<Row>
							<Col sm="6">
								<div>
									<p className="text-muted mb-2">Available Balance</p>
									<h5>{this.props.currencyFormat.format(this.state.company_pmnt_balance.totalBalance)}</h5>
								</div>
							</Col>
							<Col sm="6">
								<div className="text-sm-right mt-4 mt-sm-0">
									<p className="text-muted mb-2">Expenditure</p>
									<h5>{this.props.currencyFormat.format(this.state.company_pmnt_balance.totalExpenditure)}   <span className="badge badge-success ml-1 align-bottom">{((1-(this.state.company_pmnt_balance.totalBalance / Math.max(this.state.company_pmnt_balance.totalExpenditure, 1)))*100).toFixed(2)} %</span></h5>
									
								</div>
							</Col>
						</Row>
					</CardBody>

					<CardBody className="border-top">
						<p className="text-muted mb-4">In this month</p>
						<div className="text-center">
							<Row>
								<Col sm="4">
									<div>
										<div className="font-size-24 text-primary mb-2">
											<i className="bx bx-send"></i>
										</div>
					
										<p className="text-muted mb-2">Expenditure</p>
										<h5>{this.props.currencyFormat.format(this.state.company_pmnt_balance.monthlyExpenditure)}</h5>

										{/* <div className="mt-3">
											<Link to="#" className="btn btn-primary btn-sm w-md">Send</Link>
										</div> */}
									</div>
								</Col>
								<Col sm="4">
									<div className="mt-4 mt-sm-0">
										<div className="font-size-24 text-primary mb-2">
											<i className="bx bx-import"></i>
										</div>					
										<p className="text-muted mb-2">Receive</p>
										<h5>{this.props.currencyFormat.format(this.state.company_pmnt_balance.monthlyBalance)}</h5>

										{/* <div className="mt-3">
											<Link to="#" className="btn btn-primary btn-sm w-md">Receive</Link>
										</div> */}
									</div>
								</Col>
								<Col sm="4">
									<div className="mt-4 mt-sm-0">
										<div className="font-size-24 text-primary mb-2">
											<i className="bx bx-wallet"></i>
										</div>
					
										<p className="text-muted mb-2">Withdraw</p>
										<h5>{this.props.currencyFormat.format(this.state.company_pmnt_balance.monthlyWithdrawl)}</h5>

										{/* <div className="mt-3">
											<Link to="#" className="btn btn-primary btn-sm w-md">Withdraw</Link>
										</div> */}
									</div>
								</Col>
							</Row>
						</div>

					</CardBody>
				</Card>

				<div className="table-responsive">
					<Table className="table-centered mb-0 table-nowrap">
						<thead className="thead-light">
							<tr>
								<th scope="col">User Details</th>
								<th scope="col">Billing Time</th>
								<th scope="col">Transaction Status</th>
								<th scope="col" className="text-right">Amount</th>
							</tr>
						</thead>
						<tbody>
							{
								this.state.all_pmnts.map((pmnt, key) =>
									<tr key={"_orderSummary_" + key}>
										<td>
											<p className="text-muted mb-0">Name: {pmnt.user.name}</p>
											<p className="text-muted mb-0">Email: {pmnt.user.email}</p>
											<p className="text-muted mb-0">Phone No: {pmnt.user.phoneNo}</p>
										</td>
										<td>
											<p className="mb-0">{pmnt.paidTime && <Moment format="DD-MM-YYYY HH:mm">{pmnt.paidTime}</Moment>}</p>
										</td>
										<td>
											<p className="mb-0">
												<Badge className={"font-size-12 badge-soft-" + (pmnt.success ? "success" : "danger")} color={pmnt.success ? "success" : "danger"} pill>{pmnt.success ? "Success" : "Failed"}</Badge>
											</p>
										</td>
										<td className="text-right">{this.props.currencyFormat.format(pmnt.amount)}</td>
									</tr>
								)
							}
						</tbody>
					</Table>
				</div>
			</React.Fragment>
		);
	}
}

export default PaymentsPage;